import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from '../templates/TableIcons';
import AddIcon from '@material-ui/icons/Add';
import { Button, Box } from '@material-ui/core';
import { deleteIntern_Fly_Ash, getIntern_Fly_Ash } from '../../repo/intern_fly_ashRepo';
import { dateTimeToStringTime, dateToStringID } from '../../repo/utils';
import { getCachedToken } from '../../auth/auth';

export default function MonitoringFlyAshTable(props) {
  const tableRef = React.createRef();
  const flyAshColumns = [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Tanggal", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCDate()}/${temp_date.getUTCMonth() + 1}/${temp_date.getUTCFullYear()}`}</p>
      }
    },
    {
      title: "Jam", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCHours()}:${temp_date.getUTCMinutes() + 1}`}</p>
      }
    },
    {
      title: "Unit-1", field: "unit_1"
    },
    {
      title: "Unit-2", field: "unit_2"
    },
    {
      title: "Penginput", field: "updated_by"
    },
  ];
  let columns = flyAshColumns;

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [props.tipe, props.filterStartDate, props.filterEndDate]);

  const handleRowDelete = (oldData, resolve) => {
    deleteIntern_Fly_Ash(oldData.id)
      .then(res => {
        resolve()
      })
      .catch(error => {
        let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        alert(errorMessage);
        resolve()
      })
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      title={props.title}
      icons={tableIcons}
      columns={columns}
      options={{
        sorting: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        toolbar: true,
        paging: true,
        search: false,
      }}
      // actions={[
      //   {
      //     icon: () => (
      //       <Button variant="outlined" disableElevation startIcon={<AddIcon />}>
      //         Tambah Data Baru
      //       </Button>),
      //     tooltip: 'Tambah Data',
      //     isFreeAction: true,
      //     onClick: (event) => alert("You want to add a new row")
      //   }
      // ]}
      icons={tableIcons}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve)
          }),
      }}
      data={async (query) => {
        const res = await getIntern_Fly_Ash(props.filterStartDate, props.filterEndDate, query.page, query.pageSize, '');
        return ({
          data: res.records ? res.records : [],
          page: query.page,
          totalCount: parseInt(res.TotalRecord)
        })
      }
      }
    />
  );
}
