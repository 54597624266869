import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from './history';
import { getRoleFromJWTToken } from "./repo/notificationRepo";

// Pages for unauthenticated user
import LoginPage from "./components/login/LoginForm";
import NotFound from "./components/login/NotFound";

// Pages for admin
import DashboardPage from "./components/dashboard/DashboardPage"
import AdministrasiPage from "./components/administrasi/AdministrasiPage"
import MonitoringPage from "./components/monitoring/MonitoringPage"
import Intern_Air_LindiPage from "./components/intern_air_lindi/Intern_Air_LindiPage"
import Intern_Air_LindiAddUpdatePage from "./components/intern_air_lindi/Intern_Air_LindiAddUpdatePage"
import Intern_CondensorPage from "./components/intern_condensor/Intern_CondensorPage"
import Intern_CondensorAddUpdatePage from "./components/intern_condensor/Intern_CondensorAddUpdatePage"
import Intern_Fly_AshPage from "./components/intern_fly_ash/Intern_Fly_AshPage"
import Intern_Fly_AshAddUpdatePage from "./components/intern_fly_ash/Intern_Fly_AshAddUpdatePage"
import Intern_Leak_DetectorPage from "./components/intern_leak_detector/Intern_Leak_DetectorPage"
import Intern_Leak_DetectorAddUpdatePage from "./components/intern_leak_detector/Intern_Leak_DetectorAddUpdatePage"
import Intern_PengelolaanPage from "./components/intern_pengelolaan/Intern_PengelolaanPage"
import Intern_PengelolaanAddUpdatePage from "./components/intern_pengelolaan/Intern_PengelolaanAddUpdatePage"
import KendaraanPage from "./components/kendaraan/KendaraanPage"
import KendaraanAddUpdatePage from "./components/kendaraan/KendaraanAddUpdatePage"
import KerjasamaPage from "./components/administrasi/kerjasama/KerjasamaPage"
import KerjasamaAddUpdatePage from "./components/administrasi/kerjasama/KerjasamaAddUpdatePage"
import MaterialPage from "./components/material/MaterialPage"
import MaterialAddUpdatePage from "./components/material/MaterialAddUpdatePage"
import PengambilanPage from "./components/pengambilan/PengambilanPage"
import PengambilanAddUpdatePage from "./components/pengambilan/PengambilanAddUpdatePage"
import Pengambilan_LogPage from "./components/pengambilan_log/Pengambilan_LogPage"
import Pengambilan_LogAddUpdatePage from "./components/pengambilan_log/Pengambilan_LogAddUpdatePage"
import Pengambilan_MaterialPage from "./components/pengambilan_material/Pengambilan_MaterialPage"
import Pengambilan_MaterialAddUpdatePage from "./components/pengambilan_material/Pengambilan_MaterialAddUpdatePage"
import PengangkutPage from "./components/pengangkut/PengangkutPage"
import PengangkutAddUpdatePage from "./components/pengangkut/PengangkutAddUpdatePage"
import PengelolaPage from "./components/pengelola/PengelolaPage"
import PengelolaAddUpdatePage from "./components/pengelola/PengelolaAddUpdatePage"
import Pengelola_MaterialPage from "./components/pengelola_material/Pengelola_MaterialPage"
import Pengelola_MaterialAddUpdatePage from "./components/pengelola_material/Pengelola_MaterialAddUpdatePage"
import PerizinanPage from "./components/administrasi/perizinan/PerizinanPage"
import PerizinanAddUpdatePage from "./components/administrasi/perizinan/PerizinanAddUpdatePage"
import Perizinan_PemegangPage from "./components/administrasi/perizinan_pemegang/Perizinan_PemegangPage"
import Perizinan_PemegangAddUpdatePage from "./components/administrasi/perizinan_pemegang/Perizinan_PemegangAddUpdatePage"
import UserPage from "./components/user/UserPage"
import UserAddUpdatePage from "./components/user/UserAddUpdatePage"
import NotificationPage from "./components/notification/NotificationPage";

// Pages for sekretariat
import { DashboardPage as SekretariatDashboardPage } from "./components/pages_sekretariat/dashboard/DashboardPage";
import { PengambilanPage as SekretariatPengambilanPage } from "./components/pages_sekretariat/pengambilan/PengambilanPage";
import { TagihanPage } from "./components/pages_sekretariat/tagihan/TagihanPage";

// Pages for finance
import { DashboardPage as FinanceDashboardPage } from "./components/pages_finance/dashboard/DashboardPage";

// Pages for pos1
import { DashboardPage as PomiDashboardPage } from "./components/pages_pomi/dashboard/DashboardPage";

// Pages for pos2
import { DashboardPage as SikamDashboardPage } from "./components/pages_sikam/dashboard/DashboardPage";

export default function Routes(props) {
  return (
    <Router history={history}>
      <Switch>
        {/* Unauthenticated routes */}
        <Route path="/dashboard/" exact>
          <LoginPage role={props.role} setRole={props.setRole} />
        </Route>

        {/* Routes for admin user */}
        {props.role == 'admin' && <Switch>
          <Route path="/dashboard/main" exact><DashboardPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/administrasi" exact><AdministrasiPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/monitoring" exact><MonitoringPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_air_lindi" exact><Intern_Air_LindiPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_air_lindi/add" exact><Intern_Air_LindiAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_air_lindi/update/:id" exact><Intern_Air_LindiAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_condensor" exact><Intern_CondensorPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_condensor/add" exact><Intern_CondensorAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_condensor/update/:id" exact><Intern_CondensorAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_fly_ash" exact><Intern_Fly_AshPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_fly_ash/add" exact><Intern_Fly_AshAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_fly_ash/update/:id" exact><Intern_Fly_AshAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_leak_detector" exact><Intern_Leak_DetectorPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_leak_detector/add" exact><Intern_Leak_DetectorAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_leak_detector/update/:id" exact><Intern_Leak_DetectorAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_pengelolaan" exact><Intern_PengelolaanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_pengelolaan/add" exact><Intern_PengelolaanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/intern_pengelolaan/update/:id" exact><Intern_PengelolaanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kendaraan" exact><KendaraanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kendaraan/add" exact><KendaraanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kendaraan/update/:id" exact><KendaraanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kerjasama" exact><KerjasamaPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kerjasama/add" exact><KerjasamaAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/kerjasama/update/:id" exact><KerjasamaAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/material" exact><MaterialPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/material/add" exact><MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/material/update/:id" exact><MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan" exact><PengambilanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan/add" exact><PengambilanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan/update/:id" exact><PengambilanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_log" exact><Pengambilan_LogPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_log/add" exact><Pengambilan_LogAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_log/update/:id" exact><Pengambilan_LogAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_material" exact><Pengambilan_MaterialPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_material/add" exact><Pengambilan_MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengambilan_material/update/:id" exact><Pengambilan_MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengangkut" exact><PengangkutPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengangkut/add" exact><PengangkutAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengangkut/update/:id" exact><PengangkutAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola" exact><PengelolaPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola/add" exact><PengelolaAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola/update/:id" exact><PengelolaAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola_material" exact><Pengelola_MaterialPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola_material/add" exact><Pengelola_MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/pengelola_material/update/:id" exact><Pengelola_MaterialAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan" exact><PerizinanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan/add" exact><PerizinanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan/update/:id" exact><PerizinanAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan_pemegang" exact><Perizinan_PemegangPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan_pemegang/add" exact><Perizinan_PemegangAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/perizinan_pemegang/update/:id" exact><Perizinan_PemegangAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/user" exact><UserPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/user/add" exact><UserAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/user/update/:id" exact><UserAddUpdatePage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
          <Route path="/dashboard/notifikasi/" exact><NotificationPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /></Route>
        </Switch>}

        {/* Routes for sekretariat user */}
        {props.role == 'sekretariat' && <Switch>
          <Route path="/dashboard/s/main" exact>
            <SekretariatDashboardPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} />
          </Route>
          <Route path="/dashboard/s/pengambilan" exact>
            <SekretariatPengambilanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} />
          </Route>
          <Route path="/dashboard/s/tagihan" exact>
            <TagihanPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}/>
          </Route>
          <Route path="/dashboard/notifikasi/" exact>
            <NotificationPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} />
          </Route>
        </Switch>}

        {/* Routes for finance user */}
        {props.role == 'keuangan' && <Switch>
          <Route path="/dashboard/f/main" exact>
            <FinanceDashboardPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}/>
          </Route>
        </Switch>}

        {/* Routes for pos1 user */}
        {props.role == 'pos1' && <Switch>
          <Route path="/dashboard/p1/main" exact>
            <PomiDashboardPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}/>
          </Route>
        </Switch>}

        {/* Routes for pos2 user */}
        {props.role == 'pos2' && <Switch>
          <Route path="/dashboard/p2/main" exact>
            <SikamDashboardPage history={history} location={props.location} match={props.match} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}/>
          </Route>
        </Switch>}

        {/* Handle not found route */}
        <Route component={NotFound} />

      </Switch>
    </Router>
  )
}