import React, { useState } from "react";
import { Grid, Button, Snackbar, Modal } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import MuiAlert from '@material-ui/lab/Alert';
import SumberMaterialTable from "./SumberMaterialTable";
import { makeStyles } from '@material-ui/core/styles';
import SumberMaterialAddUpdateModal from "./SumberMaterialAddUpdateModal";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SumberMaterialDataSubpage = (props) => {
    const history = props.history;
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalMode, setModalMode] = React.useState('add');
    const [modalData, setModalData] = React.useState({ id: 0, data: '' });
    const [tableRefresh, setTableRefresh] = React.useState(false);

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    const openAddDataForm = () => {
        setModalMode('add');
        setModalData({
            id: 0,
            value: '',
        });
        setModalOpen(true);
    }

    const openEditDataForm = (id, data) => {
        setModalMode('edit');
        setModalData({
            id: id,
            value: data,
        });
        setModalOpen(true);
    }

    return (
        <div>
            <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} justify='flex-end'>
                <Grid container item md={3} xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        style={{ height: 45, width: '100%' }}
                        onClick={openAddDataForm}
                    >
                        Tambah Data Baru
                    </Button>
                </Grid>
            </Grid>
            <SumberMaterialTable
                setAlertState={setAlertstate}
                alertState={alertState}
                openEditDataForm={openEditDataForm}
                refresh={tableRefresh}
            />
            <SumberMaterialAddUpdateModal
                open={modalOpen}
                setOpen={setModalOpen}
                mode={modalMode}
                data={modalData}
                alertState={alertState}
                setAlertState={setAlertstate}
                tableRefresh={tableRefresh}
                setTableRefresh={setTableRefresh}
            />
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div >);
}
export default SumberMaterialDataSubpage;
