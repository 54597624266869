import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPerizinan = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPerizinan(pageNo+1,pageSize);
    }

    else{
        try {
            res = await searchPerizinan(search,pageNo+1,pageSize);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addPerizinan = (data) => {
return api.post(`/perizinan`,data)
}
const updatePerizinan = (id,data) => {
return api.put(`/perizinan/${id}`,data)
}
const getAllPerizinan = (pageNo,pageSize) => {
return api.get(`/perizinan/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePerizinan = (id) => {
return api.get(`/perizinan/${id}`)
}
const searchPerizinan = (pageNo,pageSize,searchKey) => {
return api.get(`/perizinan/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePerizinan = (id) => {
return api.delete(`/perizinan/${id}`)
}
export {getPerizinan,addPerizinan,updatePerizinan,getAllPerizinan,getOnePerizinan,searchPerizinan,deletePerizinan}


