import api from "../api/axios";

const getMaterialByPengangkutID = (id_pengangkut) => {
    return api.get(`/pengangkut/${id_pengangkut}/material`);
}

const addPengangkutMaterial = (id_pengangkut, data) => {
    return api.post(`/pengangkut/${id_pengangkut}/material`, data)
}

const deletePengangkutMaterial = (id_pengangkut, id_material) => {
    return api.delete(`/pengangkut/${id_pengangkut}/material/${id_material}`);
}

export { getMaterialByPengangkutID, addPengangkutMaterial, deletePengangkutMaterial }


