import { Box, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { getNotifications, setNotificationAsRead } from "../../repo/notificationRepo";
import tableIcons from '../templates/TableIcons';
import PageTemplate from "../templates/Template";

const NotificationPage = (props) => {

    return (
        <PageTemplate title="Notifikasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Box style={{ marginTop: 120 }}></Box>
            <Grid container justify="center">
                <Grid md={8} sm={12} item>
                    <MaterialTable
                        title="Notifikasi"
                        icons={tableIcons}
                        columns={[
                            { title: "ID", field: "id", hidden: true },
                            {
                                title: "Konten", field: "content", render: rowData => {
                                    if (rowData.is_read) {
                                        return <p>{rowData.content}</p>
                                    }
                                    setNotificationAsRead(rowData.id);
                                    return <b><p>{rowData.content}</p></b>
                                }
                            }
                        ]}
                        options={{
                            sorting: false,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            toolbar: true,
                            paging: true,
                            search: false,
                        }}
                        data={async (query) => {
                            const res = await getNotifications(query.page + 1, query.pageSize);
                            const payload = res && res.data && res.data.document ? res.data.document : {};
                            return ({
                                data: payload.records ? payload.records : [],
                                page: query.page,
                                totalCount: payload.TotalRecord ? parseInt(payload.TotalRecord) : 0
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </PageTemplate>
    )
}

export default NotificationPage;