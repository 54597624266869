import api from "../api/axios";
import { dateToISOString } from "./utils";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getIntern_Leak_Detector = async (filterStartDate, filterEndDate, pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllIntern_Leak_Detector(filterStartDate, filterEndDate, pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchIntern_Leak_Detector(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addIntern_Leak_Detector = (data) => {
    return api.post(`/intern_leak_detector`, data)
}
const updateIntern_Leak_Detector = (id, data) => {
    return api.put(`/intern_leak_detector/${id}`, data)
}
const getAllIntern_Leak_Detector = (filterStartDate, filterEndDate, pageNo, pageSize) => {
    return api.get(`/intern_leak_detector/?pageNo=${pageNo}&pageSize=${pageSize}${filterStartDate ? '&start_date='+dateToISOString(filterStartDate) : ''}${filterEndDate ? '&end_date='+dateToISOString(filterEndDate) : ''}`);
}
const getOneIntern_Leak_Detector = (id) => {
    return api.get(`/intern_leak_detector/${id}`)
}
const searchIntern_Leak_Detector = (pageNo, pageSize, searchKey) => {
    return api.get(`/intern_leak_detector/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deleteIntern_Leak_Detector = (id) => {
    return api.delete(`/intern_leak_detector/${id}`)
}
export { getIntern_Leak_Detector, addIntern_Leak_Detector, updateIntern_Leak_Detector, getAllIntern_Leak_Detector, getOneIntern_Leak_Detector, searchIntern_Leak_Detector, deleteIntern_Leak_Detector }


