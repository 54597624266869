import api from "../api/axios";
import { dateToISOString } from "./utils";

//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengambilan = async (startDate, endDate, status, noPengambilanQuery, filterMaterial, filterPengelola, filterPengangkut, pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPengambilan(startDate, endDate, status, noPengambilanQuery, filterMaterial, filterPengelola, filterPengangkut, pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPengambilan(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addPengambilan = (data) => {
    return api.post(`/pengambilan`, data)
}

const updatePengambilan = (id, data) => {
    return api.put(`/pengambilan/${id}`, data)
}

const getAllPengambilan = (filterStartDate, filterEndDate, status, noPengambilanQuery, filterMaterial, filterPengelola, filterPengangkut, pageNo, pageSize) => {
    let statusQuery = '';
    if (status != undefined && status != 'all') statusQuery = `&status_akhir=${status}`;
    if (noPengambilanQuery) noPengambilanQuery = `&no_pengambilan_query=${noPengambilanQuery}`;
    let dateQuery = `${filterStartDate ? '&start_date='+dateToISOString(filterStartDate) : ''}${filterEndDate ? '&end_date='+dateToISOString(filterEndDate) : ''}`;
    filterMaterial = filterMaterial != undefined ? `&containMaterial=${filterMaterial}` : '';
    filterPengelola = filterPengelola != undefined ? `&id_pengelola=${filterPengelola}` : '';
    filterPengangkut = filterPengangkut != undefined ? `&id_pengangkut=${filterPengangkut}` : '';
    return api.get(`/pengambilan/?pageNo=${pageNo}&pageSize=${pageSize}${statusQuery}${dateQuery}${noPengambilanQuery ? noPengambilanQuery : ''}${filterMaterial}${filterPengelola}${filterPengangkut}`);
}

const getTodayPengambilanByVehicleNo = (vehicleNo) => {
  let crtDate = new Date();
  let crtDateStr = dateToISOString(crtDate);
  return api.get(`/pengambilan?plat_nomor=${vehicleNo}&start_date=${crtDateStr}&end_date=${crtDateStr}`);
}

const durationToStartDate = (duration) => {
    let startDate = null;
    let startDateStr = null;
    if (duration && duration != 'all') {
        let durationDay = parseInt(duration);
        let dateOffset = (24 * 60 * 60 * 1000) * durationDay;
        startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        let monthStr = startDate.getMonth() + 1 > 9 ? startDate.getMonth() + 1 : `0${startDate.getMonth() + 1}`;
        let dayStr = startDate.getDate() > 9 ? startDate.getDate() : `0${startDate.getDate()}`;
        startDateStr = `${startDate.getFullYear()}-${monthStr}-${dayStr}`;
    }
    return startDateStr;
}

const getAllPengambilanByPengelolaIDAndDuration = (pengelolaID, statusAkhir, duration, pageNo, pageSize) => {
    let startDateStr = durationToStartDate(duration);
    return api.get(
        `/pengambilan/?&pageNo=${pageNo + 1}&pageSize=${pageSize}` +
        (pengelolaID ? `&id_pengelola=${pengelolaID}` : '') +
        (statusAkhir ? `&status_akhir=${statusAkhir}` : '') +
        (startDateStr ? `&start_date=${startDateStr}` : ''));
};

const getAllPengambilanByPengelolaIDAndDate = (pengelolaID, statusAkhir, startDateStr, endDateStr, pageNo, pageSize) => {
    return api.get(
        `/pengambilan/?&pageNo=${pageNo + 1}&pageSize=${pageSize}` +
        (pengelolaID ? `&id_pengelola=${pengelolaID}` : '') +
        (statusAkhir ? `&status_akhir=${statusAkhir}` : '') +
        (startDateStr ? `&start_date=${startDateStr}` : '') +
        (endDateStr ? `&end_date=${endDateStr}` : '')
    );
};

const getMaterialListByPengelolaIDAndDuration = (pengelolaID, duration, statusAkhir, pageNo, pageSize) => {
    let startDateStr = durationToStartDate(duration);
    return api.get(
        `/pengambilan-material/?&pageNo=${pageNo + 1}&pageSize=${pageSize}` +
        (pengelolaID ? `&id_pengelola=${pengelolaID}` : '') +
        (statusAkhir ? `&status_akhir=${statusAkhir}` : '') +
        (startDateStr ? `&start_date=${startDateStr}` : ''));
}

const getMaterialListByPengelolaIDAndDate = (pengelolaID, statusAkhir, startDateStr, endDateStr, pageNo, pageSize) => {
    return api.get(
        `/pengambilan-material/?&pageNo=${pageNo + 1}&pageSize=${pageSize}` +
        (pengelolaID ? `&id_pengelola=${pengelolaID}` : '') +
        (statusAkhir ? `&status_akhir=${statusAkhir}` : '') +
        (startDateStr ? `&start_date=${startDateStr}` : '') +
        (endDateStr ? `&end_date=${endDateStr}` : '')
    );
}

const getOnePengambilan = (id) => {
    return api.get(`/pengambilan?id=${id}`)
}

const searchPengambilan = (pageNo, pageSize, searchKey) => {
    return api.get(`/pengambilan/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

const deletePengambilan = (id) => {
    return api.delete(`/pengambilan/${id}`)
}

const getLast10PengambilanByPengelolaID = (id_pengelola) => {
    return api.get(`/pengambilan?id_pengelola=${id_pengelola}&pageNo=1&pageSize=10`);
}

const getLast10PengambilanByPengangkutID = (id_pengangkut) => {
    return api.get(`/pengambilan?id_pengangkut=${id_pengangkut}&pageNo=1&pageSize=10`);
}

export { getPengambilan, getTodayPengambilanByVehicleNo, getMaterialListByPengelolaIDAndDate, getAllPengambilanByPengelolaIDAndDate, getLast10PengambilanByPengangkutID, getLast10PengambilanByPengelolaID, addPengambilan, updatePengambilan, getAllPengambilan, getOnePengambilan, searchPengambilan, deletePengambilan, getAllPengambilanByPengelolaIDAndDuration, getMaterialListByPengelolaIDAndDuration }


