import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const DashboardTableColumns = (totalCount) => [
  {title: "Id", field: "id", hidden:true},
  {title: "Tanggal", field: "tanggal_pengambilan", type:"datetime"},
  {title: "Nama", field: "kodenama"},
  {title: "Tonase", field: "tonase", render: rowData => {
    var formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    });
    var value = formatter.formatToParts(rowData.tonase).map(
      p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
    ).join('');
    return <p>{value}</p>
  }},
  {title: "Nilai (Rp)", field: "nilai", render: rowData => {
    var formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    var value = formatter.formatToParts(rowData.nilai).map(
      p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
    ).join('');
    return <p>{value}</p>
  }},
  {title: "Keterangan", field: "jenis"},
  {title: "InsertedAt", field: "inserted_at",type:"datetime", hidden:true},
  {title: "UpdatedAt", field: "updated_at",type:"datetime", hidden:true},

]
export default DashboardTableColumns;
