import PageTemplate from "../templates/Template";
import React, { useEffect, useState } from "react";
import { Grid, Container, Box, Button, Modal, Slide } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import '@fontsource/roboto';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import MonitoringBasicTable from "./MonitoringBasicTable";
import MonitoringFlyAshTable from "./MonitoringFlyAshTable";
import MonitoringLeakDetectorTable from "./MonitoringLeakDetectorTable";
import { makeStyles } from '@material-ui/core/styles';
import { apiUrl } from "../../api/axios";

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { dateToISOString } from "../../repo/utils";
import idLocale from 'date-fns/locale/id';

const useStyles = makeStyles((theme) => ({
    selectedMenu: {
        color: '#3d5bcc',
    },
    unselectedMenu: {
        color: 'black',
    }
}));

const MonitoringPage = (props) => {
    const [monitoringID, setMonitoringID] = useState(0);
    const menuMapping = {
        0: { title: 'Level Silo Ash', tipe: 'silo_ash' },
        1: { title: 'Leak Detector', tipe: 'leak_detector' },
        2: { title: 'Air Lindi', tipe: 'air_lindi' },
        3: { title: 'Outlet Kondensor-1', tipe: 'condensor1' },
        4: { title: 'Outlet Kondensor-2', tipe: 'condensor2' },
        5: { title: 'MCWWTP', tipe: 'mcwwtp' },
        6: { title: 'WTP', tipe: 'wtp' },
        7: { title: 'Drainase', tipe: 'drainase' },
        8: { title: 'STP', tipe: 'stp' },
        9: { title: 'Coal Pond', tipe: 'coal_pond' }
    }

    const [filterDateValue, setFilterDateValue] = useState('all');
    const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
    const [dateRangeState, setDateRangeState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const token = localStorage.getItem('token');
    const classes = useStyles();
    const handleMenuChange = (menuID) => {
        setMonitoringID(menuID);
    }
    const handleFilterDateChange = (e) => {
        setFilterDateValue(e.target.value);
    }

    useEffect(() => {
        console.log(">>>>", JSON.stringify(dateRangeState), filterDateValue, (filterDateValue == 'specific' && dateRangeState[0].startDate));
    }, [dateRangeState]);

    return (
        <PageTemplate title="Monitoring" menu="monitoring" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Container>
                <Typography variant="h5" style={{ margin: 50 }}>📝 Monitoring Kondisi Pembangkit</Typography>

                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid container item xs={12} md={12} direction='row' style={{ marginBottom: 5 }} spacing={2}>
                        <Grid item xs={12} md={3} justify='flex-start'>
                            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-outlined-label">Rentang Tanggal</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Perusahaan Pengelola"
                                    value={filterDateValue}
                                    onChange={handleFilterDateChange}
                                    style={{ textAlign: 'left', backgroundColor: 'white' }}
                                >
                                    <MenuItem value={'all'}>Semua</MenuItem>
                                    <MenuItem value={'specific'}>Tertentu</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {filterDateValue === 'specific' && <Grid item xs={12} md={3} justify='flex-start'>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <p style={{ marginBottom: 0, marginTop: 3 }}>
                                    Tanggal: {dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : '∞'} - {dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : '∞'}
                                </p>
                                <Button
                                    onClick={() => setIsDateRangeModalOpen(true)}
                                    variant="outlined"
                                    size="small"
                                >
                                    Ganti
                                </Button>
                            </FormControl>
                        </Grid>}

                    </Grid>
                    <Grid item xs={12} md={12} style={{ backgroundColor: '#3f51b5', height: 2 }}>
                    </Grid>
                    <Grid item xs={9} md={10} style={{ height: '50%', textAlign: 'left' }}>
                        <Paper>
                            {
                                monitoringID == 0 &&
                                <MonitoringFlyAshTable
                                    tipe={menuMapping[monitoringID].tipe}
                                    title={menuMapping[monitoringID].title}
                                    filterStartDate={
                                        filterDateValue == 'specific' && dateRangeState[0].startDate ?
                                            dateRangeState[0].startDate
                                            :
                                            null
                                    }
                                    filterEndDate={
                                        filterDateValue == 'specific' && dateRangeState[0].endDate ?
                                            dateRangeState[0].endDate
                                            :
                                            null
                                    } />
                            }
                            {
                                monitoringID == 1 &&
                                <MonitoringLeakDetectorTable
                                    tipe={menuMapping[monitoringID].tipe}
                                    title={menuMapping[monitoringID].title}
                                    filterStartDate={
                                        filterDateValue == 'specific' && dateRangeState[0].startDate ?
                                            dateRangeState[0].startDate
                                            :
                                            null
                                    }
                                    filterEndDate={
                                        filterDateValue == 'specific' && dateRangeState[0].endDate ?
                                            dateRangeState[0].endDate
                                            :
                                            null
                                    } />
                            }
                            {
                                monitoringID >= 2 &&
                                <MonitoringBasicTable
                                    tipe={menuMapping[monitoringID].tipe}
                                    title={menuMapping[monitoringID].title}
                                    filterStartDate={
                                        filterDateValue == 'specific' && dateRangeState[0].startDate ?
                                            dateRangeState[0].startDate
                                            :
                                            null
                                    }
                                    filterEndDate={
                                        filterDateValue == 'specific' && dateRangeState[0].endDate ?
                                            dateRangeState[0].endDate
                                            :
                                            null
                                    } />
                            }
                        </Paper>
                    </Grid>
                    <Grid container item xs={3} md={2} alignItems="flex-start" direction="column">
                        <Paper style={{ width: '100%' }}>
                            <List>
                                <ListItem button className={monitoringID == 0 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 0} onClick={() => handleMenuChange(0)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 0 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Silo Fly Ash</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 1 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 1} onClick={() => handleMenuChange(1)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 1 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Leak Detector</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 2 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 2} onClick={() => handleMenuChange(2)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 2 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Air Lindi</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 3 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 3} onClick={() => handleMenuChange(3)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 3 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Outlet Kondensor 1</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 4 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 4} onClick={() => handleMenuChange(4)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 4 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Outlet Kondensor 2</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 5 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 5} onClick={() => handleMenuChange(5)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 5 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>MCWWTP</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 6 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 6} onClick={() => handleMenuChange(6)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 6 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>WTP</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 7 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 7} onClick={() => handleMenuChange(7)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 7 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Drainase</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 8 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 8} onClick={() => handleMenuChange(8)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 8 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>STP</Box></Typography>   </ListItem>
                                <ListItem button className={monitoringID == 9 ? classes.selectedMenu : classes.unselectedMenu} selected={monitoringID == 9} onClick={() => handleMenuChange(9)}><Typography variant="subtitle2"><Box fontWeight={monitoringID == 9 ? "fontWeightBold" : "fontWeightMedium"} display="inline" m={1}>Coal Pond</Box></Typography>   </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Modal
                open={isDateRangeModalOpen}
                onClose={() => {
                    setIsDateRangeModalOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Slide direction="down" in={isDateRangeModalOpen} mountOnEnter unmountOnExit>
                    <div style={{ width: 50, height: 50, marginLeft: '40%', marginTop: '10%' }}>
                        <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            locale={idLocale}
                            onChange={item => setDateRangeState([item.selection])}
                            ranges={dateRangeState}
                            dateDisplayFormat={'d MMM yyyy'}
                        />
                    </div>
                </Slide>
            </Modal>

            <Button variant={"contained"} color="primary" href={`${apiUrl}/report/monitoring?token=${token}`} style={{ marginTop: 20 }}>Unduh sebagai File Excel</Button>
        </PageTemplate>)
}
export default MonitoringPage;
