// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import idLocale from 'date-fns/locale/id';
import { dateToISOString } from "../../../repo/utils";

import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Button,
  Modal,
  Slide,
  Card,
  CardContent,
  Snackbar,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import PageTemplate from "../../templates/Template";
import PengambilanTable from "./PengambilanTable";
import { getAllPengelola } from '../../../repo/pengelolaRepo';
import { generateTagihanDocument } from '../../../repo/tagihanRepo';
import { getAllPengangkut } from '../../../repo/pengangkutRepo';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// PengambilanPage for sekretariat
export const PengambilanPage = (props) => {
  const classes = useStyles();
  const [companyList, setCompanyList] = useState([]);
  const [isCompanyLoaded, setIsCompanyLoaded] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('all');
  const [selectedCompanyType, setSelectedCompanyType] = useState('all');
  const [selectedCompanyName, setSelectedCompanyName] = useState('Semua Perusahaan');
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [isGenerateDocumentModalOpen, setIsGenerateDocumentModalOpen] = useState(false);
  const [duration, setDuration] = useState('all');
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [alertState, setAlertstate] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    severity: "success",
    message: "",
  });
  const { vertical, horizontal, open, severity, message } = alertState;

  const hideAlert = () => {
    setAlertstate({ ...alertState, open: false });
  };

  useEffect(() => {
    getAllPengelola(1, 9999).then(result => {
      if (result &&
        result.data &&
        result.data.document &&
        result.data.document.records &&
        result.data.document.records.length > 0) {
        let companies = result.data.document.records;

        companies.forEach(c => {
          c.is_pengelola = true;
        });

        getAllPengangkut(1, 9999).then(resultP => {
          if (resultP &&
            resultP.data &&
            resultP.data.document &&
            resultP.data.document.records &&
            resultP.data.document.records.length > 0) {

            companies = companies.concat(resultP.data.document.records);
            setCompanyList(companies);

            setIsCompanyLoaded(true);
          }
        }).catch(e => {
          console.log("error: ", e);
        });
      }
    }).catch(e => {
      console.log("error: ", e);
    });
  }, []);

  const handleCompanyChange = (e) => {
    let companyName = '';
    let selectedID = e.target.value.split('.')[0];
    let selectedType = e.target.value.split('.')[1];

    for (let i = 0; i < companyList.length; i++) {
      if (companyList[i].id == selectedID && companyList[i].is_pengelola == (selectedType == 'pengelola' ? true : undefined)) {
        companyName = companyList[i].nama_perusahaan;
        break;
      }
    }

    setSelectedCompanyName(companyName);
    setSelectedCompany(selectedID);
    setSelectedCompanyType(selectedType);
  };

  useEffect(() => {
    if (selectedCompany == 'all' || duration == 'all') {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selectedCompany, duration]);

  const handleGenerateDocument = () => {
    generateTagihanDocument(
      selectedCompanyType == 'pengelola' ? selectedCompany : undefined,
      selectedCompanyType == 'pengangkut' ? selectedCompany : undefined,
      dateRangeState[0].startDate,
      dateRangeState[0].endDate
    ).then((resp) => {
      console.log(resp);
      setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Dokumen tagihan berhasil dibuat, harap periksa menu 'Dokumen Tagihan'", severity: "success" });
    }).catch(e => {
      console.log(e);
      setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Terjadi kesalahan sistem", severity: "error" });
    }).then(() => {
      setIsGenerateDocumentModalOpen(false);
    });
  }

  return (
    <PageTemplate
      title="Pengambilan"
      menu="pengambilan"
      isDrawerOpen={props.isDrawerOpen}
      setIsDrawerOpen={props.setIsDrawerOpen}
    >
      <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} alignItems="center">
        <Grid container item md={8} xs={12} spacing={2} >
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Perusahaan</InputLabel>
              <Select
                label="Perusahaan"
                disabled={!isCompanyLoaded}
                value={`${selectedCompany}.${selectedCompanyType}`}
                style={{ textAlign: 'left', backgroundColor: 'white' }}
                onChange={handleCompanyChange}
              >
                <MenuItem value={'all.all'}>Semua</MenuItem>
                {
                  companyList.map((c, i) => (
                    <MenuItem key={i} value={`${c.id}.${c.is_pengelola ? 'pengelola' : 'pengangkut'}`}>{c.nama_perusahaan}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} justify='flex-start'>
            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Tanggal</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(e) => setDuration(e.target.value)}
                label="Status"
                value={duration ? duration : 'all'}
                style={{ textAlign: 'left', backgroundColor: 'white' }}
              >
                <MenuItem value={'all'}>Semua</MenuItem>
                <MenuItem value={'range'}>Rentang Tanggal</MenuItem>Î
              </Select>
            </FormControl>
          </Grid>
          {duration === 'range' &&
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <p style={{ marginBottom: 0, marginTop: 3 }}>
                Tanggal: {dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : '∞'} - {dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : '∞'}
              </p>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsDateRangeModalOpen(true)}
              >
                Ganti
              </Button>
            </FormControl>
          </Grid>}
        </Grid>
        <Grid container item md={4} xs={12} justify="flex-end" alignContent="center">
          <Grid item>
            <Button
              disabled={!isDataAvailable || isSelectAll}
              variant="contained"
              color="primary"
              onClick={() => setIsGenerateDocumentModalOpen(true)}
            >
              Buat Dokumen Tagihan
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={isDateRangeModalOpen}
        onClose={() => {
          setIsDateRangeModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Slide direction="down" in={isDateRangeModalOpen} mountOnEnter unmountOnExit>
          <div style={{ width: 50, height: 50, marginLeft: '40%', marginTop: '10%' }}>
            <DateRange
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              locale={idLocale}
              onChange={item => setDateRangeState([item.selection])}
              ranges={dateRangeState}
              dateDisplayFormat={'d MMM yyyy'}
            />
          </div>
        </Slide>
      </Modal>

      <Modal
        open={isGenerateDocumentModalOpen}
        onClose={() => {
          setIsGenerateDocumentModalOpen(false);
        }}
      >
        <Slide direction="down" in={isGenerateDocumentModalOpen} mountOnEnter unmountOnExit>
          <div style={{ width: '40%', height: 200, marginLeft: '30%', marginTop: '10%' }}>
            <Card>
              <CardContent>
                <p><b>Buat Dokumen Penagihan</b></p>
                <p>{`Perusahaan: ${selectedCompanyName}`}</p>
                <p>{`Periode : ${duration === 'range' ? `${dateToISOString(dateRangeState[0].startDate)} s.d ${dateToISOString(dateRangeState[0].endDate)}` : "semua"}`}</p>
                <p>Dokumen tagihan untuk setiap pengambilan hanya dapat dibuat satu kali, harap periksa menu "Dokumen Tagihan" untuk mengecek dokumen yang sudah dibuat sebelumnya.</p>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleGenerateDocument()}
                >
                  Buat Dokumen
                </Button>
              </CardContent>
            </Card>
          </div>
        </Slide>
      </Modal>

      <PengambilanTable
        companyID={selectedCompanyType == 'pengelola' ? selectedCompany : undefined}
        transporterID={selectedCompanyType == 'pengangkut' ? selectedCompany : undefined}
        dateStart={
          duration == 'range' && dateRangeState[0].startDate ?
            dateRangeState[0].startDate
            :
            null
        }
        dateEnd={
          duration == 'range' && dateRangeState[0].endDate ?
            dateRangeState[0].endDate
            :
            null
        }
        setIsDataAvailable={setIsDataAvailable}
      />

      <Snackbar autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={hideAlert}
        key={vertical + horizontal}>
        <Alert onClose={hideAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

    </PageTemplate>
  )
};