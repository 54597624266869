import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import FileSelector from "../../templates/FileSelector";
import history from '../../../history';
import { addKerjasama, getOneKerjasama, updateKerjasama } from "../../../repo/kerjasamaRepo";
import { getPengelola } from "../../../repo/pengelolaRepo";
import { getPengangkut } from "../../../repo/pengangkutRepo";
import Checkbox from '@material-ui/core/Checkbox';
import { uploadFile } from "../../../repo/fileRepo";
import { apiUrl } from "../../../api/axios";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const KerjasamaAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [kerjasama, setKerjasama] = useState(undefined)
    const [title, setTitle] = useState("Add/Update Data Kerjasama");
    const [pengelola, setPengelola] = useState([]);
    const [pengangkut, setPengangkut] = useState([]);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [pihak, setPihak] = useState({ pihak1: 'pengelola', pihak2: 'pengangkut' });
    const [isLoading, setIsLoading] = useState(true);

    const checkErrors = () => {
        let errorList = {}
        if (kerjasama) {
            if (kerjasama.is_with_pjb === "" || kerjasama.is_with_pjb === undefined) {
                errorList = { ...errorList, is_with_pjb: "Required field!" }
            }
            if (kerjasama.tanggal_selesai === "" || kerjasama.tanggal_selesai === undefined) {
                errorList = { ...errorList, tanggal_selesai: "Required field!" }
            }
            if ((props.match.params.id === undefined && selectedFile === undefined) || (props.match.params.id && (kerjasama.url === undefined || kerjasama.url == ""))) {
                errorList = { ...errorList, url: "Required field!" }
            }
        }

        setErrorMessages(errorList)
        return errorList
    }

    // #1: load data pengangkut dan pengelola, set title
    useEffect(() => {
        getPengelola(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                p.nama = p.nama_perusahaan;
            })
            setPengelola(res.records);
        });
        getPengangkut(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                p.nama = p.nama_perusahaan;
            })
            setPengangkut(res.records);
        });

        if (props.match.params.id) {
            setTitle("Edit Data Kerjasama");
        } else {
            setKerjasama({
                is_with_pjb: 0,
            });
            setTitle("Tambah Data Kerjasama");
        }
    }, [props.match.params.id]);

    // #2: load data kerjasama, after pengelola and pengangkut is loaded
    useEffect(() => {
        // for edit data, get the data from backend
        if (pengelola.length > 0 && pengangkut.length > 0 && props.match.params.id) {
            getOneKerjasama(props.match.params.id).then((res) => {
                if (res.data.document.tanggal_selesai && res.data.document.tanggal_selesai.length > 0) {
                    let tanggalSelesai = new Date(res.data.document.tanggal_selesai);
                    res.data.document.tanggal_selesai = tanggalSelesai.toISOString().substring(0, 10);
                }
                let tempKerjasama = { ...res.data.document };
                for (const key in tempKerjasama) {
                    if (tempKerjasama[key] == null) delete tempKerjasama[key];
                }
                setKerjasama(tempKerjasama);
                setIsLoading(false);
            });
        }

        // for add data, set default value
        if (pengelola.length > 0 && pengangkut.length > 0 && props.match.params.id === undefined) {
            setKerjasama({
                is_with_pjb: 0,
                id_pengelola: pengelola[0].id,
                id_pengangkut: pengangkut[0].id,
            });
            setIsLoading(false);
        }

    }, [pengelola, pengangkut]);

    // #3: for add data only, handle if pihak is changed.
    useEffect(() => {
        if (props.match.params.id === undefined) {
            if (pihak.pihak1 != 'pengelola' && pihak.pihak2 != 'pengelola') {
                setKerjasama((data) => { return { ...data, id_pengelola: null } });
            };
            if (pihak.pihak1 != 'pengangkut' && pihak.pihak2 != 'pengangkut') {
                setKerjasama((data) => { return { ...data, id_pengangkut: null } });
            };
            if (pihak.pihak1 != 'pjb' && pihak.pihak2 != 'pjb') {
                setKerjasama((data) => { return { ...data, is_with_pjb: 0 } });
            };
            if (pihak.pihak1 == 'pjb' || pihak.pihak2 == 'pjb') {
                setKerjasama((data) => { return { ...data, is_with_pjb: 1 } });
            };

            // setting default value
            if ((pihak.pihak1 == 'pengelola' || pihak.pihak2 == 'pengelola') && (pengelola.length > 0)) {
                setKerjasama((data) => { return { ...data, id_pengelola: pengelola[0].id } });
            }
            if ((pihak.pihak1 == 'pengangkut' || pihak.pihak2 == 'pengangkut') && (pengangkut.length > 0)) {
                setKerjasama((data) => { return { ...data, id_pengangkut: pengangkut[0].id } });
            }
        }
    }, [pihak]);

    useEffect(() => {
        checkErrors();
    }, [selectedFile]);

    const handleSelectedFile = (file) => {
        setSelectedFile(file);
    }

    const handleAddOrCreateKerjasama = async (data) => {
        console.log(data);
        if (data.id) {
            var updateResponse = await updateKerjasama(data.id, data);
            if (updateResponse && updateResponse.data) {
                if (updateResponse.data.code === 1) {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
            }
        } else {
            var addResponse = await addKerjasama(data)
            if (addResponse && addResponse.data) {
                if (addResponse.data.code === 1) {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
            }
        }
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault()
        try {
            //if no errors then send data
            const errors = checkErrors();

            if (Object.keys(errors).length < 1) {
                // Upload file
                if (selectedFile) {
                    uploadFile(selectedFile).then((res) => {
                        if (res.data && res.data.document && res.data.document.file_id) {
                            let uploadedFileURL = apiUrl + '/file/' + res.data.document.file_id;
                            handleAddOrCreateKerjasama({ ...kerjasama, url: uploadedFileURL });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'Gagal mengunggah dokumen', severity: "error" });
                        }
                    }).catch(err => {
                        let errMessage = JSON.stringify(err);
                        if (err.response && err.response.data && err.response.data.message) {
                            errMessage = err.response.data.message;
                        }
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                    });
                } else {
                    handleAddOrCreateKerjasama(kerjasama);
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
        }
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={8} xs={12}>
                    <Card style={{ marginTop: 20, width: '100%' }}>
                        <CardHeader title={title} />
                        <CardContent>

                            {props.match.params.id == undefined &&
                                <Grid spacing={3} container>
                                    <Grid item md={4}>
                                        <Typography variant="body1" align="left">
                                            Pihak Kerjasama
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Select style={{ width: '100%', textAlign: 'left' }}
                                            value={pihak.pihak1}
                                            id="pihak1"
                                            onChange={(e) => {
                                                setPihak({ ...pihak, pihak1: e.target.value });
                                            }}>
                                            {pihak.pihak2 != 'pengelola' && <MenuItem key={1} value={'pengelola'}>Pengelola</MenuItem>}
                                            {pihak.pihak2 != 'pengangkut' && <MenuItem key={2} value={'pengangkut'}>Pengangkut</MenuItem>}
                                            {pihak.pihak2 != 'pjb' && <MenuItem key={3} value={'pjb'}>PT. PJB UP Paiton</MenuItem>}
                                        </Select>
                                    </Grid>
                                    <Grid item md={1}><p>dan</p></Grid>
                                    <Grid item md={3}>
                                        <Select style={{ width: '100%', textAlign: 'left' }}
                                            value={pihak.pihak2}
                                            id="pihak2"
                                            onChange={(e) => {
                                                setPihak({ ...pihak, pihak2: e.target.value });
                                            }}>
                                            {pihak.pihak1 != 'pengelola' && <MenuItem key={1} value={'pengelola'}>Pengelola</MenuItem>}
                                            {pihak.pihak1 != 'pengangkut' && <MenuItem key={2} value={'pengangkut'}>Pengangkut</MenuItem>}
                                            {pihak.pihak1 != 'pjb' && <MenuItem key={3} value={'pjb'}>PT. PJB UP Paiton</MenuItem>}
                                        </Select>
                                    </Grid>
                                </Grid>
                            }

                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {(!isLoading) ?
                                    <Grid spacing={3} container>
                                        {((props.match.params.id == undefined && (pihak.pihak1 == 'pengelola' || pihak.pihak2 == 'pengelola')) || (props.match.params.id != undefined && kerjasama.id_pengelola != null)) &&
                                            <Grid md={12} key={"0"} item container direction="row">
                                                <Grid item md={4}>
                                                    <Typography variant="body1" align="left">
                                                        Perusahaan Pengelola *
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Select style={{ width: '100%', textAlign: 'left' }}
                                                        id="id_pengelola"
                                                        disabled={props.match.params.id != undefined}
                                                        value={kerjasama.id_pengelola ? kerjasama.id_pengelola : ''}
                                                        onChange={(e) => {
                                                            setKerjasama({ ...kerjasama, id_pengelola: e.target.value });
                                                            checkErrors();
                                                        }}>
                                                        {pengelola.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        }
                                        {((props.match.params.id == undefined && (pihak.pihak1 == 'pengangkut' || pihak.pihak2 == 'pengangkut')) || (props.match.params.id != undefined && kerjasama.id_pengangkut != null)) &&
                                            <Grid md={12} key={"1"} item container direction="row">
                                                <Grid item md={4}>
                                                    <Typography variant="body1" align="left">
                                                        Perusahaan Pengangkut *
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Select style={{ width: '100%', textAlign: 'left' }}
                                                        disabled={props.match.params.id != undefined}
                                                        id="id_pengangkut"
                                                        value={kerjasama.id_pengangkut ? kerjasama.id_pengangkut : ''}
                                                        onChange={(e) => {
                                                            setKerjasama({ ...kerjasama, id_pengangkut: e.target.value });
                                                            checkErrors();
                                                        }}>
                                                        {pengangkut.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        }
                                        {((props.match.params.id == undefined && (pihak.pihak1 == 'pjb' || pihak.pihak2 == 'pjb')) || (props.match.params.id != undefined && kerjasama.is_with_pjb)) ?
                                            <Grid md={12} key={"2"} item container direction="row">
                                                <Grid item md={4}>
                                                    <Typography variant="body1" align="left">
                                                        Kerjasama dengan UP Paiton? *
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} align="left">
                                                    <Checkbox
                                                        checked={kerjasama.is_with_pjb == 1}
                                                        color="primary"
                                                        disabled
                                                        onChange={(e) => { setKerjasama({ ...kerjasama, is_with_pjb: e.target.checked ? 1 : 0 }); checkErrors() }}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            : null}
                                        <Grid md={12} key={"3"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Tanggal Selesai *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5}>
                                                <TextField required autoFocus fullWidth
                                                    helperText={errorMessages.tanggal_selesai}
                                                    type={"date"}
                                                    InputLabelProps={{ shrink: true, }}
                                                    onChange={(e) => { setKerjasama({ ...kerjasama, tanggal_selesai: e.target.value }); checkErrors() }}
                                                    value={kerjasama.tanggal_selesai ? kerjasama.tanggal_selesai : ''}
                                                    error={(errorMessages.tanggal_selesai) ? true : false} />
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"4"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Dokumen Perjanjian *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <FileSelector handleSelectedFile={handleSelectedFile} thumbnailURL={kerjasama && kerjasama.url ? kerjasama.url : null} />
                                                {(errorMessages.url && errorMessages.url.length > 0) && <p style={{ textAlign: 'left', color: '#f44336', fontSize: '0.75rem', fontWeight: 400 }}>{errorMessages.url}</p>}
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} item key={"5"} >
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => history.push('/dashboard/administrasi?menu=2')} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Sumbit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)}
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(KerjasamaAddUpdatePage)
