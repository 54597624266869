import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengelola_Material = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPengelola_Material(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPengelola_Material(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}


const addPengelola_Material = (data) => {
    return api.post(`/pengelola_material`, data)
}
const addPengelolaMaterial = (id_pengelola, data) => {
    return api.post(`/pengelola/${id_pengelola}/material`, data)
}
const updatePengelolaMaterial = (id_pengelola, id_material, data) => {
    return api.put(`/pengelola/${id_pengelola}/material/${id_material}`, data)
}
const getAllPengelola_Material = (pageNo, pageSize) => {
    return api.get(`/pengelola_material/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePengelola_Material = (id_pengelola) => {
    return api.get(`/pengelola_material/${id_pengelola}`)
}
const searchPengelola_Material = (pageNo, pageSize, searchKey) => {
    return api.get(`/pengelola_material/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePengelola_Material = (id_pengelola) => {
    return api.delete(`/pengelola_material/${id_pengelola}`)
}
const getMaterialByPengelolaID = (id_pengelola) => {
    return api.get(`/pengelola/${id_pengelola}/material`);
}
const deletePengelolaMaterial = (id_pengelola, id_material) => {
    return api.delete(`/pengelola/${id_pengelola}/material/${id_material}`);
}
export { getMaterialByPengelolaID, addPengelolaMaterial, deletePengelolaMaterial, getPengelola_Material, addPengelola_Material, updatePengelolaMaterial, getAllPengelola_Material, getOnePengelola_Material, searchPengelola_Material, deletePengelola_Material }


