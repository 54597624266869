import api from "../api/axios";

//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getSumberMaterial = async (pageNo, pageSize) => {
    let res;
    res = await getAllSumberMaterial(pageNo + 1, pageSize);
    return res.data.document;
}

const addSumberMaterial = (data) => {
    return api.post(`/sumber-material`, data)
}

const updateSumberMaterial = (id, data) => {
    return api.put(`/sumber-material/${id}`, data)
}

const getAllSumberMaterial = (pageNo, pageSize) => {
    return api.get(`/sumber-material/?pageNo=${pageNo}&pageSize=${pageSize}`);
}

const deleteSumberMaterial = (id) => {
    return api.delete(`/sumber-material/${id}`)
}

export { getSumberMaterial, addSumberMaterial, updateSumberMaterial, getAllSumberMaterial, deleteSumberMaterial }


