import PageTemplate from "../../templates/Template";
import { Grid, TextField, Button, Card } from "@material-ui/core";

// DashboardPage for POS SIKAM (pos2)
export const DashboardPage = (props) => {
  return (
    <PageTemplate title="Pos POMI" menu="dashboard" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <p style={{ marginTop: 50, marginBottom: 30 }}>Masukan plat nomor kendaraan untuk mencari data pengambilan material</p>

      <Grid
        container
        direction="row"
        justify="center"
        style={{ padding: 0 }}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Plat Nomor Kendaraan"
                variant="outlined"
                focused
                style={{ width: '100%' }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="error"
                style={{ width: '100%' }}>
                Batal
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}>
                Cari
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 40 }}>
            <Grid item xs={12}>
              <Card style={{ padding: 5 }}>
                <table style={{ textAlign: 'left', verticalAlign: 'top' }}>
                  <tr><th>Pengelola</th>                    <td>:</td><td>PT Pengelola 1</td></tr>
                  <tr><th>Pengambilan</th>                  <td>:</td><td>11 April 2021</td></tr>
                  <tr><th>Status</th>                       <td>:</td><td>Menuju Pos POMI, Menunggu Approval</td></tr>
                  <tr><th>Waktu Tiba</th>                   <td>:</td><td>12 April 2021, 13:30</td></tr>
                  <tr><th>Jenis Material yang Diambil</th>  <td>:</td><td>Glass Wool Terkontaminasi</td></tr>
                  <tr><th>Driver</th>                       <td>:</td><td>Andi Sudrajat</td></tr>
                  <tr><th>Nomor Polisi Kendaraan</th>       <td>:</td><td>D 1124 ZE</td></tr>
                </table>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="error"
                style={{ width: '100%', marginTop: 10 }}>
                Tolak
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 10 }}>
                Terima
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

    </PageTemplate >
  )
};