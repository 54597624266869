import MaterialTable from "material-table";
import { createRef } from "react";
import tableIcons from '../../templates/TableIcons';
import { getTagihan } from "../../../repo/tagihanRepo";

function getURLExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

const TagihanTable = (props) => {
  let tableRef = createRef();

  return (
    <MaterialTable
      tableRef={tableRef}
      minRows={10}
      title="Dokumen Tagihan"
      options={{
        sorting: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        toolbar: true,
        paging: true,
        search: false,
      }}
      icons={tableIcons}
      columns={
        [
          { title: "Id", field: "id", hidden: true },
          {
            title: "Tanggal Mulai", field: "tanggal_mulai", render: rowData => {
              let date = new Date(rowData.tanggal_mulai);
              return <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
            },
          },
          {
            title: "Tanggal Selesai", field: "tanggal_selesai", render: rowData => {
              let date = new Date(rowData.tanggal_selesai);
              return <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
            },
          },
          {
            title: "Perusahaan", field: "pengelola", render: rowData => {
              let companyName = '';
              if (rowData.id_pengelola) {
                companyName = rowData.pengelola;
              }
              if (rowData.id_pengangkut) {
                companyName = rowData.pengangkut;
              }
              return <p>{companyName}</p>
            }
          },
          {
            title: "Dokumen", field: "url", render: rowData => {
              const token = localStorage.getItem('token');
              let url = '';
              let fileURL = '';
              let maxWidth = 30;
              if (rowData.url) {
                const extension = getURLExtension(rowData.url);
                fileURL = `${rowData.url}?token=${token}`;
                if (['pdf', 'doc', 'docx', 'xls', 'xlsx'].includes(extension)) {
                  if (extension === 'pdf') url = process.env.PUBLIC_URL + '/icon-pdf.png';
                  if (extension === 'doc' || extension === 'docx') url = process.env.PUBLIC_URL + '/icon-doc.png';
                  if (extension === 'xls' || extension === 'xlsx') url = process.env.PUBLIC_URL + '/icon-xls.png';
                  fileURL += '&dl';
                }
                if (['png', 'jpg', 'jpeg'].includes(extension)) {
                  url = rowData.url + `?token=${token}`;
                  maxWidth = 60;
                }
              }
              return (
                <img
                  src={url ? url : "https://static.thenounproject.com/png/583402-200.png"}
                  style={{ maxWidth: maxWidth }}
                  onClick={() => {
                    if (url) window.open(fileURL, "_blank")
                  }}
                />)
            }
          },
        ]
      }
      data={async (query) => {
        const res = await getTagihan(query.page, query.pageSize);
        return ({
          data: res.records,
          page: query.page,
          totalCount: parseInt(res.TotalRecord)
        })
      }}
    >

    </MaterialTable>
  )
}

export default TagihanTable;