import { Chip } from "@material-ui/core";

const PengambilanStateChip = (props) => {
    let state = props.status;
    let label = state.replace(/_/g, ' ');
    let color = "primary";
    let variant = "outlined";
    if (state === 'menunggu_pengangkut') color = undefined;
    if (state === 'dibatalkan' || state === 'ditolak') color = "secondary";
    if (state === 'selesai') variant = 'default';
    return <Chip
        variant={variant}
        size="small"
        label={label.replace(/\b\w/g, l => l.toUpperCase())}
        color={color}
    />
}

export default PengambilanStateChip;