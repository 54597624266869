import PageTemplate from "../templates/Template";
import React, { useState } from "react";
import { Container, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AdministrasiMenuTabs from "./AdministrasiMenuTabs";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AdministrasiPage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title="Administrasi" menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Container>
                <AdministrasiMenuTabs setAlertstate={setAlertstate} />
            </Container>
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>)
}
export default AdministrasiPage;
