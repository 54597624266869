import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import history from './../../history';
import { getRoleFromJWTToken } from '../../repo/notificationRepo';

export const MainListItems = function (props) {

  let role = getRoleFromJWTToken();

  return (
    <div>
      {/* Menu for admin */}
      {role == 'admin' && <>
        <ListItem button onClick={() => history.push({ pathname: "/dashboard/main", isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'dashboard'}>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: "/dashboard/user", isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'user'}>
          <ListItemText primary="User" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/material', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'material'}>
          <ListItemText primary="Material" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/pengelola', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'pengelola'}>
          <ListItemText primary="Pengelola" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/pengangkut', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'pengangkut'}>
          <ListItemText primary="Pengangkut" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/kendaraan', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'kendaraan'}>
          <ListItemText primary="Kendaraan" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/pengambilan', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'pengambilan'}>
          <ListItemText primary="Pengambilan Limbah" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/administrasi', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'administrasi'}>
          <ListItemText primary="Administrasi" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/monitoring', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'monitoring'}>
          <ListItemText primary="Monitoring" />
        </ListItem>
      </>}

      {/* Menu for sekretariat */}
      {role == 'sekretariat' && <>
        {/* <ListItem button onClick={() => history.push({ pathname: "/dashboard/s/main", isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'dashboard'}>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/s/pengambilan', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'pengambilan'}>
          <ListItemText primary="Tagihan Pengambilan" />
        </ListItem>
        <ListItem button onClick={() => history.push({ pathname: '/dashboard/s/tagihan', isDrawerOpen: props.isMobile ? false : true })} selected={props.menu === 'tagihan'}>
          <ListItemText primary="Dokumen Tagihan" />
        </ListItem>
      </>}

    </div>
  );
}
