import MaterialTable from 'material-table';
import React, { createRef, useEffect, useState } from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './KendaraanColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import { deleteKendaraan, getKendaraanByQuery } from "../../repo/kendaraanRepo";
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const KendaraanTable = (props) => {
  const history = props.history;
  const [columns, setColumns] = useState(getColumns({}));
  const tableRef = createRef();

  //Here we call delete
  const handleRowDelete = (oldData, resolve) => {
    deleteKendaraan(oldData.id)
      .then(res => {
        resolve()
      })
      .catch(error => {
        resolve()
      })
  }

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [props.pengangkutID, props.platNomorQuery]);


  return (
    <div>
      <MaterialTable
        tableRef={tableRef}
        minRows={10}
        title="Data Kendaraan"
        columns={columns}
        data={async (query) => {
          const res = await getKendaraanByQuery(props.pengangkutID == 'all' ? null : props.pengangkutID, props.platNomorQuery, query.page, query.pageSize);
          return ({
            data: res.records,
            page: query.page,
            totalCount: parseInt(res.TotalRecord)
          })
        }
        }
        options={{
          sorting: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          toolbar: true,
          paging: true,
          search: false,
        }}

        actions={[
          {
            icon: () => <Edit />,
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              history.push({
                pathname: `/dashboard/kendaraan/update/${rowData.id}`,
                user: rowData
              })
            }
          }
        ]}

        icons={tableIcons}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve)
            }),
        }}

      />
    </div>);
}
export default withRouter(KendaraanTable);
