import PageTemplate from "../../templates/Template";
import PerizinanTable from "./PerizinanTable";
import React from "react";

const PerizinanPage = (props) => {
    return(
    <PageTemplate title="Perizinan List" menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
        <PerizinanTable/>
    </PageTemplate>)
}
export default PerizinanPage;
