import api from "../api/axios";
import { dateToISOString } from "./utils";

const getTagihan = async (pageNo, pageSize) => {
  let res;
  try {
    res = await getAllTagihan(pageNo + 1, pageSize);
  } catch (err) {
    return {
      records: [],
      TotalRecord: 0
    }
  }

  return res.data.document;
}

const getAllTagihan = (pageNo, pageSize) => {
  return api.get(`/tagihan?pageNo=${pageNo}&pageSize=${pageSize}`);
}

const generateTagihanDocument = (pengelolaID, pengangkutID, startDate, endDate) => {
  return api.post(`/tagihan`, {
    id_pengelola: pengelolaID,
    id_pengangkut: pengangkutID,
    tanggal_mulai: startDate ? dateToISOString(startDate) : null,
    tanggal_selesai: endDate ? dateToISOString(endDate) : null,
  });
}

export { getTagihan, getAllTagihan, generateTagihanDocument }