import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengambilan_Log = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPengambilan_Log(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPengambilan_Log(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}


const addPengambilan_Log = (data) => {
    return api.post(`/pengambilan_log`, data)
}
const updatePengambilan_Log = (id_pengambilan, data) => {
    return api.put(`/pengambilan_log/${id_pengambilan}`, data)
}
const getAllPengambilan_Log = (pageNo, pageSize) => {
    return api.get(`/pengambilan_log/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePengambilan_Log = (id_pengambilan) => {
    return api.get(`/pengambilan_log/${id_pengambilan}`)
}
const searchPengambilan_Log = (pageNo, pageSize, searchKey) => {
    return api.get(`/pengambilan_log/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePengambilan_Log = (id_pengambilan) => {
    return api.delete(`/pengambilan_log/${id_pengambilan}`)
}
export { getPengambilan_Log, addPengambilan_Log, updatePengambilan_Log, getAllPengambilan_Log, getOnePengambilan_Log, searchPengambilan_Log, deletePengambilan_Log }


