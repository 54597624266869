import PageTemplate from "../templates/Template";
import KendaraanTable from "./KendaraanTable";
import React, { useEffect, useState } from "react";
import { Grid, Button, Hidden, TextField, InputAdornment } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { getPengangkut } from "../../repo/pengangkutRepo";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const KendaraanPage = (props) => {
  const classes = useStyles();
  const history = props.history;
  const [pengangkutID, setPengangkutID] = useState('all');
  const [pengangkutList, setPengangkutList] = useState([]);
  const [platNomorQuery, setPlatNomorQuery] = useState("");

  const handlePengangkutChange = (event) => {
    setPengangkutID(event.target.value);
  };

  useEffect(() => {
    let tempPengangkutList = [];
    getPengangkut(0, 1000, '').then(res => {
      if (res && res.records && res.records.length > 0) {
        res.records.forEach(pengangkut => {
          tempPengangkutList.push(pengangkut);
        });
        setPengangkutList(tempPengangkutList);
      }
    }).catch(err => {
      console.log('failed to load transporter data from backend', err);
    })
  }, []);

  return (
    <PageTemplate title="Daftar Kendaraan" menu="kendaraan" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} spacing={2}>
        <Grid item md={3} xs={12} justify='flex-start'>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label">Perusahaan Pengangkut</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handlePengangkutChange}
              value={pengangkutID}
              label="Perusahaan Pengangkut"
              style={{ textAlign: 'left', backgroundColor: 'white' }}
            >
              <MenuItem value={'all'}>Semua</MenuItem>
              {
                pengangkutList.map((pengangkut, i) => (
                  <MenuItem key={i} value={pengangkut.id}>{pengangkut.nama_perusahaan}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12} justify='flex-start'>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
            <TextField
              id="outlined-basic"
              label="Cari Plat Nomor"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /> </InputAdornment>,
              }}
              value={platNomorQuery ? platNomorQuery.replace(/\s/g, "") : ''}
              onChange={(e) => {
                setPlatNomorQuery(e.target.value.replace(/\s/g, ""));
              }}
              style={{ textAlign: 'left', backgroundColor: 'white' }} />
          </FormControl>
        </Grid>
        <Grid container item md={6} xs={12} justify='flex-end' alignItems='center' justifyContent='flex-end'>
          <Grid item md={4} xs={12}>
            <Button
              variant="outlined"
              color="primary"
              style={{ height: 50, width: '100%' }}
              onClick={() => { history.push("/dashboard/kendaraan/add") }}
            >
              <AddIcon />
              <Hidden mdDown>
                <p style={{ marginLeft: 10 }}>Tambah Data Baru</p>
              </Hidden>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <KendaraanTable pengangkutID={pengangkutID} platNomorQuery={platNomorQuery}/>
    </PageTemplate>)
}
export default KendaraanPage;
