import { Grid } from "@material-ui/core";
import PageTemplate from "../../templates/Template";
import TagihanTable from "./TagihanTable";

// TagihanPage for sekretariat
export const TagihanPage = (props) => {
  return (
    <PageTemplate
      title="Tagihan"
      menu="tagihan"
      isDrawerOpen={props.isDrawerOpen}
      setIsDrawerOpen={props.setIsDrawerOpen}
    >
      <Grid
        container
        style={{ marginTop: 15, marginBottom: 15 }}>
      </Grid>
      <TagihanTable />
    </PageTemplate>
  )
};