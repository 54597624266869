import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const GetIntern_Fly_AshColumns = (totalCount) => [
  {title: "Id", field: "id",hidden:true},

  {title: "Waktu", field: "waktu",type:"date"},
{title: "Unit1", field: "unit_1"},
{title: "Unit2", field: "unit_2"},
{title: "InsertedAt", field: "inserted_at",type:"datetime"},
{title: "UpdatedAt", field: "updated_at",type:"datetime"},

]
export default GetIntern_Fly_AshColumns;
