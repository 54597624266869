import PageTemplate from "../../templates/Template";
import { Grid, TextField, Button, Card, Snackbar, CircularProgress } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from "react";
import { getTodayPengambilanByVehicleNo } from "../../../repo/pengambilanRepo";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// DashboardPage for POS POMI (pos1)
export const DashboardPage = (props) => {
  const [isResultShown, setIsResultShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleNo, setVehicleNo] = useState('');
  const [results, setResults] = useState([]);

  const [alertState, setAlertstate] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    severity: "success",
    message: "",
  });
  const { vertical, horizontal, open, severity, message } = alertState;

  function clearResult() {
    setIsResultShown(false);
    setVehicleNo('');
    setResults([]);
  }

  function findPengambilan() {
    setIsLoading(true);
    let cleanVehicleNo = vehicleNo.replace(/\s/g, '');
    // handle empty query
    if (cleanVehicleNo == '') {
      setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Mohon masukan plat nomor kendaraan.", severity: "warning" });
      setIsLoading(false);
      return;
    }

    // get data from backend
    getTodayPengambilanByVehicleNo(cleanVehicleNo).then((res) => {
      console.log(res);
      if (res && res.data && res.data.document && res.data.document.TotalRecord != 0
        && res.data.document.records && res.data.document.records.length > 0) {
        setResults(res.data.document.records);
        setIsLoading(false);
        setIsResultShown(true);
      } else {
        setIsLoading(false);
        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Data tidak ditemukan.", severity: "warning" });
      }
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
      setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Terjadi kesalahan sistem.", severity: "error" });
    });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      findPengambilan();
    }
  }

  const hideAlert = () => {
    setAlertstate({ ...alertState, open: false });
  };

  return (
    <PageTemplate title="Pos POMI" menu="dashboard" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <p style={{ marginTop: 50, marginBottom: 30 }}>Masukan plat nomor kendaraan untuk mencari data pengambilan material</p>

      <Grid
        container
        direction="row"
        justify="center"
        style={{ padding: 0 }}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Plat Nomor Kendaraan"
                variant="outlined"
                focused
                value={vehicleNo}
                onChange={(event) => setVehicleNo(event.target.value)}
                onKeyDown={handleKeyDown}
                style={{ width: '100%' }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="error"
                style={{ width: '100%' }}
                onClick={clearResult}>
                Batal
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                onClick={findPengambilan}
                style={{ width: '100%' }}>
                Cari
              </Button>
            </Grid>
          </Grid>

          {isLoading &&
            <Grid item xs={12}>
              <CircularProgress color="primary" style={{ marginTop: 40 }} />
            </Grid>}

          {!isResultShown && <Grid container spacing={2} style={{ marginTop: 40 }}>
            {results.map((p) => <span>
              <Grid item xs={12}>
                <Card style={{ padding: 5 }}>
                  <table style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    <tr><th>Pengelola</th>                    <td>:</td><td>PT Pengelola 1</td></tr>
                    <tr><th>Pengambilan</th>                  <td>:</td><td>11 April 2021</td></tr>
                    <tr><th>Status</th>                       <td>:</td><td>Menuju Pos POMI, Menunggu Approval</td></tr>
                    <tr><th>Waktu Tiba</th>                   <td>:</td><td>12 April 2021, 13:30</td></tr>
                    <tr><th>Jenis Material yang Diambil</th>  <td>:</td><td>Glass Wool Terkontaminasi</td></tr>
                    <tr><th>Driver</th>                       <td>:</td><td>Andi Sudrajat</td></tr>
                    <tr><th>Nomor Polisi Kendaraan</th>       <td>:</td><td>D 1124 ZE</td></tr>
                  </table>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: '100%', marginTop: 3, marginBottom: 20 }}>
                  Tolak
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%', marginTop: 3, marginBottom: 20 }}>
                  Terima
                </Button>
              </Grid>
            </span>)}

            <Grid item xs={12}>
              <Card style={{ padding: 5 }}>
                <table style={{ textAlign: 'left', verticalAlign: 'top' }}>
                  <tr><th>Pengelola</th>                    <td>:</td><td>PT Pengelola 1</td></tr>
                  <tr><th>Pengambilan</th>                  <td>:</td><td>11 April 2021</td></tr>
                  <tr><th>Status</th>                       <td>:</td><td>Menuju Pos POMI, Menunggu Approval</td></tr>
                  <tr><th>Waktu Tiba</th>                   <td>:</td><td>12 April 2021, 13:30</td></tr>
                  <tr><th>Jenis Material yang Diambil</th>  <td>:</td><td>Glass Wool Terkontaminasi</td></tr>
                  <tr><th>Driver</th>                       <td>:</td><td>Andi Sudrajat</td></tr>
                  <tr><th>Nomor Polisi Kendaraan</th>       <td>:</td><td>D 1124 ZE</td></tr>
                </table>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="error"
                style={{ width: '100%', marginTop: 3, marginBottom: 20 }}>
                Tolak
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 3, marginBottom: 20 }}>
                Terima
              </Button>
            </Grid>


          </Grid>}

        </Grid>
      </Grid>

      <Snackbar autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={hideAlert}
        key={vertical + horizontal}>
        <Alert onClose={hideAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

    </PageTemplate >
  )
};