import api from "../api/axios";
import { dateToISOString } from "./utils";

const getPengambilanTagihan = async (idPengelola, idPengangkut, tanggalMulai, tanggalSelesai, pageNo, pageSize) => {
  let res;
  try {
    res = await getAllPengambilanTagihan(idPengelola, idPengangkut, tanggalMulai, tanggalSelesai, pageNo + 1, pageSize);
  } catch (err) {
    return {
      records: [],
      TotalRecord: 0
    }
  }

  return res.data.document;
}

const getAllPengambilanTagihan = (idPengelola, idPengangkut, tanggalMulai, tanggalSelesai, pageNo, pageSize) => {
  // convert date to string
  let tanggalMulaiStr = dateToISOString(tanggalMulai);
  let tanggalSelesaiStr = dateToISOString(tanggalSelesai);
  return api.get(`/pengambilan-tagihan?pageNo=${pageNo}&pageSize=${pageSize}${idPengelola != undefined ? `&id_pengelola=${idPengelola}` : ''}${idPengangkut != undefined ? `&id_pengangkut=${idPengangkut}` : ``}${tanggalMulai ? `&tanggal_mulai=${tanggalMulaiStr}` : ``}${tanggalSelesai ? `&tanggal_selesai=${tanggalSelesaiStr}` : ``}`);
}

export { getPengambilanTagihan, getAllPengambilanTagihan }