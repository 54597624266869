import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getMaterial = async (isb3, pageNo, pageSize, search) => {
    let res;
    if(search.length===0) {
        res = await getAllMaterial(isb3, pageNo+1, pageSize);
    }

    else{
        try {
            res = await searchMaterial(isb3, pageNo+1, pageSize, search);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addMaterial = (data) => {
    return api.post(`/material`,data)
}

const updateMaterial = (id,data) => {
    return api.put(`/material/${id}`,data)
}

const getAllMaterial = (isb3, pageNo, pageSize) => {
    return api.get(`/material/?pageNo=${pageNo}&pageSize=${pageSize}` + (isb3 ? `&is_b3=${isb3}` : ''));
}

const getOneMaterial = (id) => {
    return api.get(`/material/${id}`)
}

const searchMaterial = (isb3, pageNo, pageSize, searchKey) => {
    return api.get(`/material/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}` + (isb3 ? `&is_b3=${isb3}` : ''));
}

const deleteMaterial = (id) => {
    return api.delete(`/material/${id}`)
}

export {getMaterial,addMaterial,updateMaterial,getAllMaterial,getOneMaterial,searchMaterial,deleteMaterial}


