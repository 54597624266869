import api from "../api/axios";
import { dateToISOString } from "./utils";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getBasicMonitoring = async (tipe, filterStartDate, filterEndDate, pageNo, pageSize) => {
    let res;
    try {
        res = await getAllBasicMonitoring(tipe, filterStartDate, filterEndDate, pageNo+1, pageSize);
    } catch(err) {
        return {
            records:[],
            totalCount:0
        }
    }
    return res.data.document;
}

const addBasicMonitoring = (tipe, data) => {
    return api.post(`/monitoring/` + tipe, data);
}

const updateBasicMonitoring = (id, data) => {
    return api.put(`/monitoring/${id}`,data);
}

const getAllBasicMonitoring = (tipe, filterStartDate, filterEndDate, pageNo, pageSize) => {
    return api.get(`/monitoring/${tipe}/?pageNo=${pageNo}&pageSize=${pageSize}${filterStartDate ? '&start_date='+dateToISOString(filterStartDate) : ''}${filterEndDate ? '&end_date='+dateToISOString(filterEndDate) : ''}`);
}

const getOneBasicMonitoring = (id) => {
    return api.get(`/monitoring/${id}`);
}
const deleteBasicMonitoring = (tipe, id) => {
    return api.delete(`/monitoring/${tipe}/${id}`);
}

export {getBasicMonitoring,addBasicMonitoring,updateBasicMonitoring,getAllBasicMonitoring,getOneBasicMonitoring,deleteBasicMonitoring};


