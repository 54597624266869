import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const GetKendaraanColumns = (totalCount) => [
  { title: "Id", field: "id", hidden: true },
  { title: "Perusahaan", field: "id_pengangkut_Value" },
  { title: "Plat Nomor", field: "plat_nomor" },
  { title: "IdPengangkut", field: "id_pengangkut", hidden: true },
  {
    title: "Foto Kendaraan", field: "url_foto_kendaraan", render: rowData => {
      const token = localStorage.getItem('token');
      const url = rowData.url_foto_kendaraan && rowData.url_foto_kendaraan != "null" ? rowData.url_foto_kendaraan + "?token=" + token : null;
      return <img src={url ? url : "https://static.thenounproject.com/png/583402-200.png"} style={{ maxWidth: (url ? 60 : 20) }} onClick={() => url && window.open(url, "_blank")} />
    }
  },
  {
    title: "STNK", field: "url_foto_stnk", render: rowData => {
      const token = localStorage.getItem('token');
      const url = rowData.url_foto_stnk && rowData.url_foto_stnk != "null" ? rowData.url_foto_stnk + "?token=" + token : null;
      return <img src={url ? url : "https://static.thenounproject.com/png/583402-200.png"} style={{ maxWidth: (url ? 60 : 20) }} onClick={() => url && window.open(url, "_blank")} />
    }
  },
  { title: "Nomor Rangka", field: "nomor_rangka", hidden: true },
  { title: "Nomor Mesin", field: "nomor_mesin", hidden: true },
  { title: "Status Aktif", field: "is_aktif", type: "boolean" },
  { title: "InsertedAt", field: "inserted_at", type: "datetime", hidden: true },
  { title: "UpdatedAt", field: "updated_at", type: "datetime", hidden: true },
]
export default GetKendaraanColumns;
