import PageTemplate from "../../templates/Template";
import KerjasamaTable from "./KerjasamaTable";
import React from "react";

const KerjasamaPage = (props) => {
    return(
    <PageTemplate title="Kerjasama List" menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
        <KerjasamaTable/>
    </PageTemplate>)
}
export default KerjasamaPage;
