import {withRouter} from "react-router";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Switch} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../../history';
import {addIntern_Leak_Detector, getIntern_Leak_Detector,getOneIntern_Leak_Detector, updateIntern_Leak_Detector} from "../../repo/intern_leak_detectorRepo";



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function validateEmail(email){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const Intern_Leak_DetectorAddUpdatePage = (props)=>{
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message:"",
      });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [intern_leak_detector,setIntern_Leak_Detector] = useState(undefined)
    

    const checkErrors = () => {
        let errorList = {}
        if(intern_leak_detector.waktu === "" || intern_leak_detector.waktu === undefined)
{
   errorList = { ...errorList,waktu: "Required field!"}
}


        setErrorMessages(errorList)
        return errorList
    }

    useEffect(()=>{
    
      
        if(props.match.params.id) {
            getOneIntern_Leak_Detector(props.match.params.id).then((res) => {
                setIntern_Leak_Detector(res.data.document)
            })
        }else{
            setIntern_Leak_Detector({})
        }
    },[props.match.params.id])
   

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
        const errors = checkErrors()
        //if no errors then send data
        if(Object.keys(errors).length<1) {
            if (intern_leak_detector.id) {
               var updateResponse =  await updateIntern_Leak_Detector(intern_leak_detector);
               if(updateResponse && updateResponse.data){
                   if(updateResponse.data.code===1){
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Record Updated Successfully.",severity:"success"});
                     }else{
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Updated failed. Please try again.", severity:"error"});
                }
               }else{
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Updated failed. Please try again.", severity:"error"});
            }
                //props.history.push("/");
            } else {
                var addResponse = await addIntern_Leak_Detector(intern_leak_detector)
                if(addResponse && addResponse.data){
                    if(addResponse.data.code===1){
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Record Added Successfully.",severity:"success"});
                          }else{
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Add Record Failed. Please try again.",severity:"error"});
                    }
                }else{
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Add Record Failed. Please try again.",severity:"error"});
                    
                }
                //props.history.push("/");
            }
        }else{
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Invalid Data. Please try again.",severity:"error"});
                   
        } 
    }catch (e) {
        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message:"Invalid Data. Please try again.",severity:"error"});
            
    }

    }
   
    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
      };
    return(
        <PageTemplate title="Add/Update Intern_Leak_Detector">
            <Card>
            <CardContent>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
               
                    {(intern_leak_detector!==undefined )?
                        <Grid spacing={3} container>
                           <Grid xs={12} md={6} key={"0"} item>
<TextField required autoFocus fullWidth
helperText ={errorMessages.waktu}
type ={"date"}
InputLabelProps ={{ shrink: true, }}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,waktu:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.waktu}
error ={(errorMessages.waktu)?true:false}
label ={"waktu"}/>
</ Grid >
<Grid xs={12} md={6} key={"1"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_1}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_1:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_1}
error ={(errorMessages.status_1)?true:false}
label ={"status_1"}/>
</ Grid >
<Grid xs={12} md={6} key={"2"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_2}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_2:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_2}
error ={(errorMessages.status_2)?true:false}
label ={"status_2"}/>
</ Grid >
<Grid xs={12} md={6} key={"3"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_3}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_3:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_3}
error ={(errorMessages.status_3)?true:false}
label ={"status_3"}/>
</ Grid >
<Grid xs={12} md={6} key={"4"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_4}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_4:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_4}
error ={(errorMessages.status_4)?true:false}
label ={"status_4"}/>
</ Grid >
<Grid xs={12} md={6} key={"5"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_5}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_5:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_5}
error ={(errorMessages.status_5)?true:false}
label ={"status_5"}/>
</ Grid >
<Grid xs={12} md={6} key={"6"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_6}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_6:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_6}
error ={(errorMessages.status_6)?true:false}
label ={"status_6"}/>
</ Grid >
<Grid xs={12} md={6} key={"7"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.status_7}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,status_7:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.status_7}
error ={(errorMessages.status_7)?true:false}
label ={"status_7"}/>
</ Grid >
<Grid xs={12} md={6} key={"8"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_1}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_1:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_1}
error ={(errorMessages.url_foto_1)?true:false}
label ={"url_foto_1"}/>
</ Grid >
<Grid xs={12} md={6} key={"9"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_2}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_2:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_2}
error ={(errorMessages.url_foto_2)?true:false}
label ={"url_foto_2"}/>
</ Grid >
<Grid xs={12} md={6} key={"10"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_3}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_3:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_3}
error ={(errorMessages.url_foto_3)?true:false}
label ={"url_foto_3"}/>
</ Grid >
<Grid xs={12} md={6} key={"11"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_4}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_4:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_4}
error ={(errorMessages.url_foto_4)?true:false}
label ={"url_foto_4"}/>
</ Grid >
<Grid xs={12} md={6} key={"12"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_5}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_5:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_5}
error ={(errorMessages.url_foto_5)?true:false}
label ={"url_foto_5"}/>
</ Grid >
<Grid xs={12} md={6} key={"13"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_6}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_6:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_6}
error ={(errorMessages.url_foto_6)?true:false}
label ={"url_foto_6"}/>
</ Grid >
<Grid xs={12} md={6} key={"14"} item>
<TextField  autoFocus fullWidth
helperText ={errorMessages.url_foto_7}
type ={"text"}
onChange={(e)=>{setIntern_Leak_Detector({...intern_leak_detector,url_foto_7:e.target.value});checkErrors()}}
defaultValue ={intern_leak_detector.url_foto_7}
error ={(errorMessages.url_foto_7)?true:false}
label ={"url_foto_7"}/>
</ Grid >
<Grid xs={12}  md={6} item key={"17"}>
<Grid container justify={"flex-end"} alignContent={"flex-end"}>
<Button onClick={() => history.push('/intern_leak_detector')} variant={"contained"} type={"Button"} color="secondary">Cancel</Button>
</Grid>
</Grid>
<Grid xs={12}  md={6} item key={"18"}>
<Grid container justify={"flex-end"} alignContent={"flex-end"}>
<Button variant={"contained"} color="primary"  type={"Sumbit"}>Save</Button>
</Grid>
</Grid>

                        </Grid>
                        :null}
                </form>
                
               
                </CardContent>
                </Card>
                <Snackbar autoHideDuration={6000}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={hideAlert}
                    key={vertical + horizontal}>
                       <Alert onClose={hideAlert}  severity={severity}>
                       {message}
                    </Alert>
                </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(Intern_Leak_DetectorAddUpdatePage)
