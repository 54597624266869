import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getUser = async (role, pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllUser(role, pageNo + 1, pageSize);
  }

  else {
    try {
      res = await searchUser(role, pageNo + 1, pageSize, search);
    } catch (err) {
      return {
        records: [],
        totalCount: 0
      }
    }
  }
  return res.data.document;
}

const addUser = (data) => {
  return api.post(`/user`, data)
}

const updateUser = (id, data) => {
  return api.put(`/user/${id}`, data)
}

const getAllUser = (role, pageNo, pageSize) => {
  return api.get(`/user/?pageNo=${pageNo}&pageSize=${pageSize}` + (role ? `&role=${role}` : ''));
}

const getOneUser = (id) => {
  return api.get(`/user/${id}`)
}

const searchUser = (role, pageNo, pageSize, searchKey) => {
  return api.get(`/user/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}` + (role ? `&role=${role}` : ''));
}

const deleteUser = (id) => {
  return api.delete(`/user/${id}`)
}

export { getUser, addUser, updateUser, getAllUser, getOneUser, searchUser, deleteUser }


