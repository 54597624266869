import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getIntern_Condensor = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllIntern_Condensor(pageNo+1,pageSize);
    }

    else{
        try {
            res = await searchIntern_Condensor(search,pageNo+1,pageSize);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addIntern_Condensor = (data) => {
return api.post(`/intern_condensor`,data)
}
const updateIntern_Condensor = (id,data) => {
return api.put(`/intern_condensor/${id}`,data)
}
const getAllIntern_Condensor = (pageNo,pageSize) => {
return api.get(`/intern_condensor/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOneIntern_Condensor = (id) => {
return api.get(`/intern_condensor/${id}`)
}
const searchIntern_Condensor = (pageNo,pageSize,searchKey) => {
return api.get(`/intern_condensor/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deleteIntern_Condensor = (id) => {
return api.delete(`/intern_condensor/${id}`)
}
export {getIntern_Condensor,addIntern_Condensor,updateIntern_Condensor,getAllIntern_Condensor,getOneIntern_Condensor,searchIntern_Condensor,deleteIntern_Condensor}


