import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";
import { Box } from '@material-ui/core';
import PerizinanTable from './perizinan/PerizinanTable';
import KerjasamaTable from './kerjasama/KerjasamaTable';
import PerizinanPemegangTable from './perizinan_pemegang/Perizinan_PemegangTable';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3d5bcc',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#3d5bca',
      opacity: 1,
    },
    '&$selected': {
      color: '#3d5bcc',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#3d5bca',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const parseQuery = (queryString) => {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

const AdministrasiMenuTabs = (props) => {
  const classes = useStyles();
  const titleData = ['Data Perizinan', 'Jenis Perizinan', 'Kerjasama'];
  const [selectedMenuID, setSelectedMenuID] = useState(0);
  const columns = [
    { title: "ID", field: "id", hidden: true },
    { title: "Nama Perizinan", field: "nama_perizinan" },
    { title: "Peruntukan", field: "peruntukan" },
    { title: "B3", field: "is_b3" },
    { title: "Wajib", field: "is_required" },
  ];

  const handleChange = (event, newValue) => {
    setSelectedMenuID(newValue);
  };

  useEffect(() => {
    let menuID = 0;
    let queries = parseQuery(props.location.search);
    let menuIDQuery = parseInt(queries.menu);
    if (menuIDQuery != NaN && menuIDQuery != undefined && menuIDQuery && menuIDQuery >= 0 && menuIDQuery <= 2) {
      setSelectedMenuID(menuIDQuery);
    }
  }, [props.location.search]);

  return (
    <div className={classes.root}>
      <Typography className={classes.padding} />
      <div className={classes.demo1}>
        <AntTabs value={selectedMenuID} onChange={handleChange} aria-label="ant example">
          <AntTab label="Data Perizinan" />
          <AntTab label="Jenis Perizinan" />
          <AntTab label="Kerjasama" />
        </AntTabs>
        <Box style={{ height: 8, backgroundColor: '#3d5bcc' }} />
        <Grid container>
          {selectedMenuID == 0 && <PerizinanPemegangTable setAlertstate={props.setAlertstate} title={titleData[selectedMenuID]} />}
          {selectedMenuID == 1 && <PerizinanTable setAlertstate={props.setAlertstate} title={titleData[selectedMenuID]} />}
          {selectedMenuID == 2 && <KerjasamaTable setAlertstate={props.setAlertstate} title={titleData[selectedMenuID]} />}
        </Grid>
      </div>
    </div>
  );
}

export default withRouter(AdministrasiMenuTabs);