import PageTemplate from "../templates/Template";
import UserTable from "./UserTable";
import React, { useEffect, useState } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserPage = (props) => {
    const classes = useStyles();
    const history = props.history;
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [userType, setUserType] = useState('all')

    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
    };

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    useEffect(() => {
        if (props.history && props.history.location && props.history.location.state && props.history.location.state.alert) {
            setAlertstate(props.history.location.state.alert);
            props.history.location.state.alert = undefined;
        }
    }, []);

    return (
        <PageTemplate title="User (Pengguna)" menu="user" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} spacing={2}>
                <Grid container item md={3} xs={9} justify='flex-start'>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Jenis User</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleUserTypeChange}
                            value={userType}
                            label="Jenis User"
                            style={{ textAlign: 'left', backgroundColor: 'white' }}
                        >
                            <MenuItem value={'all'}>Semua User</MenuItem>
                            <MenuItem value={'admin'}>User Admin</MenuItem>
                            <MenuItem value={'pengelola'}>User Pengelola</MenuItem>
                            <MenuItem value={'pengangkut'}>User Pengangkut</MenuItem>
                            <MenuItem value={'pos1'}>User Pos POMI</MenuItem>
                            <MenuItem value={'pos2'}>User Pos Sikam</MenuItem>
                            <MenuItem value={'operasi'}>User Operasi</MenuItem>
                            <MenuItem value={'operasi-internal'}>User Operasi Internal</MenuItem>
                            <MenuItem value={'lingkungan'}>User Lingkungan</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item md={9} xs={2} justify='flex-end' alignItems='center' justifyContent='flex-end'>
                    <Grid item md={4} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ height: 50, width: '100%' }}
                            onClick={() => { history.push("/dashboard/user/add") }}
                        >
                            <AddIcon />
                            <Hidden mdDown>
                                <p style={{marginLeft: 10}}>Tambah Data Baru</p>
                            </Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <UserTable role={userType} />
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>)
}
export default UserPage;
