import PageTemplate from "../templates/Template";
import PengelolaTable from "./PengelolaTable";
import React from "react";
import { Grid, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const PengelolaPage = (props) => {
    const history = props.history;
    return (
        <PageTemplate title="Pengelola" menu="pengelola" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} justify='flex-end'>
                <Grid container item md={3} xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        style={{ height: 45, width: '100%' }}
                        onClick={() => { history.push("/dashboard/pengelola/add") }}
                    >
                        Tambah Data Baru
                    </Button>
                </Grid>
            </Grid>
            <PengelolaTable />
        </PageTemplate>
    )
}
export default PengelolaPage;
