import PageTemplate from "../templates/Template";
import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialMenuTabs from "./MaterialMenuTabs";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const MaterialPage = (props) => {
    const classes = useStyles();
    const history = props.history;
    const [isb3, setIsb3] = useState('all');

    const handleMaterialTypeChange = (event) => {
        setIsb3(event.target.value);
    };

    return (
        <PageTemplate title="Material" menu="material" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <MaterialMenuTabs />
        </PageTemplate>)
}
export default MaterialPage;
