import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from '../templates/TableIcons';
import AddIcon from '@material-ui/icons/Add';
import { Button, Box } from '@material-ui/core';
import { deleteBasicMonitoring, getBasicMonitoring } from "../../repo/basicMonitoringRepo";
import { dateTimeToStringTime, dateToStringID } from '../../repo/utils';
import { getCachedToken } from '../../auth/auth';

export default function MonitoringBasicTable(props) {
  const tableRef = React.createRef();
  const basicMonitoringColumns = [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Tanggal", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCDate()}/${temp_date.getUTCMonth()+1}/${temp_date.getUTCFullYear()}`}</p>
      }
    },
    {
      title: "Jam", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCHours()}:${temp_date.getUTCMinutes()+1}`}</p>
      }
    },
    { title: "Debit (m³)", field: "debit" },
    { title: "pH", field: "ph" },
    { title: "Suhu (°C)", field: "suhu" },
    {
      title: "Foto", field: "url_foto", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto ? rowData.url_foto + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    {
      title: "Penginput", field: "updated_by"
    },
  ];
  let columns = basicMonitoringColumns;

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [props.tipe, props.filterStartDate, props.filterEndDate]);

  //Here we call delete
  const handleRowDelete = (oldData, resolve) => {
    deleteBasicMonitoring(props.tipe, oldData.id)
      .then(res => {
        resolve()
      })
      .catch(error => {
        let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        // setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errorMessage, severity: "error" });
        alert(errorMessage);
        resolve()
      })
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      title={props.title}
      icons={tableIcons}
      columns={columns}
      options={{
        sorting: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        toolbar: true,
        paging: true,
        search: false,
      }}
      // actions={[
      //   {
      //     icon: () => (
      //       <Button variant="outlined" disableElevation startIcon={<AddIcon />}>
      //         Tambah Data Baru
      //       </Button>),
      //     tooltip: 'Tambah Data',
      //     isFreeAction: true,
      //     onClick: (event) => alert("You want to add a new row")
      //   }
      // ]}
      data={async (query) => {
        const res = await getBasicMonitoring(props.tipe, props.filterStartDate, props.filterEndDate, query.page, query.pageSize);
        return ({
          data: res.records ? res.records : [],
          page: query.page,
          totalCount: parseInt(res.TotalRecord)
        })
      }}
      icons={tableIcons}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve)
          }),
      }}
    />
  );
}
