import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getIntern_Pengelolaan = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllIntern_Pengelolaan(pageNo+1,pageSize);
    }

    else{
        try {
            res = await searchIntern_Pengelolaan(search,pageNo+1,pageSize);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addIntern_Pengelolaan = (data) => {
return api.post(`/intern_pengelolaan`,data)
}
const updateIntern_Pengelolaan = (id_pengambilan,data) => {
return api.put(`/intern_pengelolaan/${id_pengambilan}`,data)
}
const getAllIntern_Pengelolaan = (pageNo,pageSize) => {
return api.get(`/intern_pengelolaan/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOneIntern_Pengelolaan = (id_pengambilan) => {
return api.get(`/intern_pengelolaan/${id_pengambilan}`)
}
const searchIntern_Pengelolaan = (pageNo,pageSize,searchKey) => {
return api.get(`/intern_pengelolaan/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deleteIntern_Pengelolaan = (id_pengambilan) => {
return api.delete(`/intern_pengelolaan/${id_pengambilan}`)
}
export {getIntern_Pengelolaan,addIntern_Pengelolaan,updateIntern_Pengelolaan,getAllIntern_Pengelolaan,getOneIntern_Pengelolaan,searchIntern_Pengelolaan,deleteIntern_Pengelolaan}


