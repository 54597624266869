import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './PengangkutColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import { AddBox } from "@material-ui/icons";
import { deletePengangkut, getPengangkut } from "../../repo/pengangkutRepo";
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const PengangkutTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deletePengangkut(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                resolve()
            })
    }


    return (
        <div>
            <MaterialTable
                minRows={20}
                title="Data Perusahaan Pengangkut (Transporter)"
                columns={columns}
                data={async (query) => {
                    const res = await getPengangkut(query.page, query.pageSize, query.search);
                    return ({
                        data: res.records,
                        page: query.page,
                        totalCount: parseInt(res.TotalRecord)
                    })
                }
                }
                options={{
                    sorting: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    toolbar: true,
                    paging: true
                }}

                actions={[
                    {
                        icon: () => <Edit />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                            history.push({
                                pathname: `/dashboard/pengangkut/update/${rowData.id}`,
                                user: rowData
                            })
                        }
                    }
                ]}

                icons={tableIcons}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}

            />
        </div>);
}
export default withRouter(PengangkutTable);
