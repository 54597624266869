import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengangkut = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPengangkut(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPengangkut(pageNo + 1, pageSize, search);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}


const addPengangkut = (data) => {
    return api.post(`/pengangkut`, data)
}
const updatePengangkut = (id, data) => {
    return api.put(`/pengangkut/${id}`, data)
}
const getAllPengangkut = (pageNo, pageSize) => {
    return api.get(`/pengangkut/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePengangkut = (id) => {
    return api.get(`/pengangkut/${id}`)
}
const searchPengangkut = (pageNo, pageSize, searchKey) => {
    return api.get(`/pengangkut/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePengangkut = (id) => {
    return api.delete(`/pengangkut/${id}`)
}
const getPerizinanByPengangkutID = (id) => {
    return api.get(`/pengangkut/${id}/perizinan`);
}
export { getPerizinanByPengangkutID, getPengangkut, addPengangkut, updatePengangkut, getAllPengangkut, getOnePengangkut, searchPengangkut, deletePengangkut }


