import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { CardHeader, CircularProgress } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../../history';
import { addKendaraan, getKendaraan, getOneKendaraan, getPerizinanByKendaraanID, updateKendaraan } from "../../repo/kendaraanRepo";
import { getPengangkut } from "../../repo/pengangkutRepo";
import ImageSelector from "../templates/ImageSelector";
import { uploadFile } from "../../repo/fileRepo";
import { apiUrl } from "../../api/axios";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CardCircularProgress(props) {
    return <div style={{ width: '100%', paddingTop: 20, textAlign: 'center' }}>
        <CircularProgress size={30} />
    </div>
}

const KendaraanAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [kendaraan, setKendaraan] = useState(undefined)
    const [pengangkut, setPengangkut] = useState(undefined)
    const [title, setTitle] = useState("Add/Update Data Kendaraan");
    const [fileFoto, setFileFoto] = useState(undefined);
    const [fileSTNK, setFileSTNK] = useState(undefined);
    const [daftarPerizinan, setDaftarPerizinan] = useState(undefined);

    const checkErrors = () => {
        let errorList = {}
        if (kendaraan.plat_nomor === "" || kendaraan.plat_nomor === undefined) {
            errorList = { ...errorList, plat_nomor: "Required field!" }
        }
        if (kendaraan.id_pengangkut === "" || kendaraan.id_pengangkut === undefined) {
            errorList = { ...errorList, id_pengangkut: "Required field!" }
        }
        if (kendaraan.nomor_rangka === "" || kendaraan.nomor_rangka === undefined) {
            errorList = { ...errorList, nomor_rangka: "Required field!" }
        }
        if (kendaraan.nomor_mesin === "" || kendaraan.nomor_mesin === undefined) {
            errorList = { ...errorList, nomor_mesin: "Required field!" }
        }
        if (kendaraan.is_aktif === "" || kendaraan.is_aktif === undefined) {
            errorList = { ...errorList, is_aktif: "Required field!" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    useEffect(() => {
        if (props.match.params.id) {
            setTitle("Tambah Kendaraan Baru");
            getOneKendaraan(props.match.params.id).then((res) => {
                setKendaraan(res.data.document)
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });

            getPerizinanByKendaraanID(props.match.params.id).then((res) => {
                if (res.data.document) {
                    setDaftarPerizinan(res.data.document);
                } else {
                    setDaftarPerizinan([]);
                }
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });

        } else {
            setTitle("Edit Kendaraan");
            if (pengangkut && pengangkut.length > 0) {
                setKendaraan({ id_pengangkut: pengangkut[0].id });
            } else {
                setKendaraan({});
            }
        }

        if (pengangkut === undefined) {
            getPengangkut(0, 200, "").then((res) => {
                setPengangkut(res.records)
                if (!props.match.params.id && res.records.length > 0) {
                    setKendaraan({ ...kendaraan, id_pengangkut: res.records[0].id });
                }
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });
        }

    }, [props.match.params.id]);

    const handleSelectedImageFoto = (file) => {
        setFileFoto(file, checkErrors);
    }

    const handleSelectedImageSTNK = (file) => {
        setFileSTNK(file, checkErrors);
    }

    const handleSubmitForm = async (data) => {
        try {

            if (data.id) {
                var updateResponse = await updateKendaraan(data.id, data);
                if (updateResponse && updateResponse.data) {
                    if (updateResponse.data.code === 1) {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                }
                //props.history.push("/");
            } else {
                var addResponse = await addKendaraan(data)
                if (addResponse && addResponse.data) {
                    if (addResponse.data.code === 1) {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                    }
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });

                }
                //props.history.push("/");
            }

        } catch (e) {
            console.log(e);
            alert("Terjadi kesalahan sistem :(");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (fileFoto && fileSTNK) {
                    uploadFile(fileFoto).then((res) => {
                        if (res.data && res.data.document && res.data.document.file_id) {
                            let uploadedFotoFileURL = apiUrl + '/file/' + res.data.document.file_id;

                            uploadFile(fileSTNK).then(res => {
                                if (res.data && res.data.document && res.data.document.file_id) {
                                    let uploadedSTNKFileURL = apiUrl + '/file/' + res.data.document.file_id;
                                    handleSubmitForm({ ...kendaraan, url_foto_kendaraan: uploadedFotoFileURL, url_foto_stnk: uploadedSTNKFileURL });
                                } else {
                                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'gagal mengunggah dokumen', severity: "error" });
                                }
                            }).catch(err => {
                                let errMessage = JSON.stringify(err);
                                if (err.response && err.response.data && err.response.data.message) {
                                    errMessage = err.response.data.message;
                                }
                                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                            })
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'gagal mengunggah dokumen', severity: "error" });
                        }
                    }).catch(err => {
                        let errMessage = JSON.stringify(err);
                        if (err.response && err.response.data && err.response.data.message) {
                            errMessage = err.response.data.message;
                        }
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                    })
                } else if (fileFoto) {
                    uploadFile(fileFoto).then((res) => {
                        if (res.data && res.data.document && res.data.document.file_id) {
                            let uploadedFileURL = apiUrl + '/file/' + res.data.document.file_id;
                            handleSubmitForm({ ...kendaraan, url_foto_kendaraan: uploadedFileURL });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'gagal mengunggah dokumen', severity: "error" });
                        }
                    }).catch(err => {
                        let errMessage = JSON.stringify(err);
                        if (err.response && err.response.data && err.response.data.message) {
                            errMessage = err.response.data.message;
                        }
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                    })
                } else if (fileSTNK) {
                    uploadFile(fileSTNK).then((res) => {
                        if (res.data && res.data.document && res.data.document.file_id) {
                            let uploadedFileURL = apiUrl + '/file/' + res.data.document.file_id;
                            handleSubmitForm({ ...kendaraan, url_foto_stnk: uploadedFileURL });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'gagal mengunggah dokumen', severity: "error" });
                        }
                    }).catch(err => {
                        let errMessage = JSON.stringify(err);
                        if (err.response && err.response.data && err.response.data.message) {
                            errMessage = err.response.data.message;
                        }
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                    })
                } else {
                    handleSubmitForm(kendaraan);
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

        }

    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title="Add/Update Kendaraan" menu="kendaraan" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={8} xs={12}>
                    <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">

                                {(kendaraan !== undefined && pengangkut !== undefined) ?
                                    <Grid spacing={3} container>

                                        <Grid xs={12} md={6} key={"8"} item >
                                            <InputLabel style={{ textAlign: 'left' }}>Perusahaan Pengangkut</InputLabel>
                                            <Select style={{ width: '100%', textAlign: 'left' }}
                                                labelId="id_pengangkut"
                                                id="id_pengangkut"
                                                value={kendaraan.id_pengangkut ? kendaraan.id_pengangkut : ''}
                                                onChange={(e) => { setKendaraan({ ...kendaraan, id_pengangkut: e.target.value }); checkErrors() }}>
                                                {pengangkut.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                                            </Select>
                                        </Grid>

                                        <Grid xs={12} md={6} key={"0"} item>
                                            <TextField required autoFocus fullWidth
                                                helperText={errorMessages.plat_nomor}
                                                type={"text"}
                                                onChange={(e) => { setKendaraan({ ...kendaraan, plat_nomor: e.target.value.replace(/\s/g, "") }); checkErrors() }}
                                                defaultValue={kendaraan.plat_nomor}
                                                error={(errorMessages.plat_nomor) ? true : false}
                                                label={"Plat Nomor"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"4"} item>
                                            <TextField required autoFocus fullWidth
                                                helperText={errorMessages.nomor_rangka}
                                                type={"text"}
                                                onChange={(e) => { setKendaraan({ ...kendaraan, nomor_rangka: e.target.value }); checkErrors() }}
                                                defaultValue={kendaraan.nomor_rangka}
                                                error={(errorMessages.nomor_rangka) ? true : false}
                                                label={"Nomor Rangka"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"5"} item>
                                            <TextField required autoFocus fullWidth
                                                helperText={errorMessages.nomor_mesin}
                                                type={"text"}
                                                onChange={(e) => { setKendaraan({ ...kendaraan, nomor_mesin: e.target.value }); checkErrors() }}
                                                defaultValue={kendaraan.nomor_mesin}
                                                error={(errorMessages.nomor_mesin) ? true : false}
                                                label={"Nomor Mesin"} />
                                        </ Grid >

                                        <Grid md={12} key={"2"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Foto Kendaraan
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <ImageSelector handleSelectedImage={handleSelectedImageFoto} previewImage={kendaraan && kendaraan.url_foto_kendaraan ? kendaraan.url_foto_kendaraan : null} />
                                                {(errorMessages.url_foto_kendaraan != "" || errorMessages.url_foto_kendaraan != undefined) && <p style={{ textAlign: 'left', color: '#f44336', fontSize: '0.75rem', fontWeight: 400 }}>{errorMessages.url}</p>}
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"3"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Foto STNK
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <ImageSelector handleSelectedImage={handleSelectedImageSTNK} previewImage={kendaraan && kendaraan.url_foto_stnk ? kendaraan.url_foto_stnk : null} />
                                                {(errorMessages.url_foto_stnk != "" || errorMessages.url_foto_stnk != undefined) && <p style={{ textAlign: 'left', color: '#f44336', fontSize: '0.75rem', fontWeight: 400 }}>{errorMessages.url}</p>}
                                            </Grid>
                                        </Grid>

                                        <Grid xs={12} md={12} key={"9"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Kendaraan Aktif? *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} align="left">
                                                <Checkbox
                                                    checked={kendaraan.is_aktif == 1}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setKendaraan({ ...kendaraan, is_aktif: e.target.checked ? 1 : 0 });
                                                        checkErrors();
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid xs={12} md={12} key={"9"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Kendaraan Sedang Digunakan?
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} align="left">
                                                <Checkbox
                                                    checked={kendaraan.is_used == 1}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setKendaraan({ ...kendaraan, is_used: e.target.checked ? 1 : 0 });
                                                        checkErrors();
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid md={12} item key={"10"} style={{ alignContent: 'flex-end' }}>
                                            <Button onClick={() => history.push('/dashboard/kendaraan')} variant={"contained"} type={"Button"}>Kembali</Button>
                                            <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Submit"}>Simpan</Button>
                                        </Grid>

                                    </Grid>
                                    :
                                    (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)
                                }
                            </form>

                        </CardContent>
                    </Card>
                </Grid>

                {props.match.params.id ?
                    (
                        <Grid item md={8} xs={12} style={{ minWidth: '67%', marginTop: 20 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Card style={{ minWidth: '100%', textAlign: 'left', padding: 20 }}>
                                        <b>Daftar Perizinan</b>
                                        <br />
                                        {daftarPerizinan ?
                                            <div>
                                                <table width="100%">
                                                    {
                                                        daftarPerizinan.map(
                                                            (p) => (
                                                                <tr>
                                                                    <td>{p.nama_perizinan}</td>
                                                                    <td>{p.tanggal_selesai ? p.tanggal_selesai.substring(0, 10) : '✗'}</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </table>
                                                {daftarPerizinan.length > 0 ? <small><br />Lihat lebih detail di menu "Administrasi → Perizinan"</small> : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    :
                    null
                }

            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(KendaraanAddUpdatePage)
