import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { CircularProgress, Select, MenuItem } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import history from '../../history';
import { addPengelola, getOnePengelola, getPerizinanByPengelolaID, updatePengelola } from "../../repo/pengelolaRepo";
import { getAllMaterial } from "../../repo/materialRepo";
import { addPengelolaMaterial, deletePengelolaMaterial, getMaterialByPengelolaID, updatePengelolaMaterial } from "../../repo/pengelola_materialRepo";
import MaterialTable from "material-table";
import { DeleteOutline, Edit, Save } from "@material-ui/icons";
import { getLast10PengambilanByPengelolaID } from "../../repo/pengambilanRepo";
import { getKerjasamaByIDPengelolaIsWithPJB } from "../../repo/kerjasamaRepo";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CardCircularProgress(props) {
    return <div style={{ width: '100%', paddingTop: 20, textAlign: 'center' }}>
        <CircularProgress size={30} />
    </div>
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const PengelolaAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [pengelola, setPengelola] = useState(undefined);
    const [title, setTitle] = useState("Add/Update Data Pengelola");
    const [daftarPerizinan, setDaftarPerizinan] = useState(undefined);
    const [daftarMaterial, setDaftarMaterial] = useState(undefined);
    const [materialOptions, setMaterialOptions] = useState(undefined);
    const [daftarKerjasama, setDaftarKerjasama] = useState(undefined);
    const [daftarPengambilan, setDaftarPengambilan] = useState(undefined);
    const [addMaterialForm, setAddMaterialForm] = useState(undefined);

    const checkErrors = () => {
        let errorList = {}
        if (pengelola.nama_perusahaan === "" || pengelola.nama_perusahaan === undefined) {
            errorList = { ...errorList, nama_perusahaan: "Required field!" }
        }
        if (pengelola.nama_pimpinan === "" || pengelola.nama_pimpinan === undefined) {
            errorList = { ...errorList, nama_pimpinan: "Required field!" }
        }
        if (pengelola.alamat === "" || pengelola.alamat === undefined) {
            errorList = { ...errorList, alamat: "Required field!" }
        }
        if (pengelola.telepon === "" || pengelola.telepon === undefined) {
            errorList = { ...errorList, telepon: "Required field!" }
        }
        if (pengelola.email === "" || validateEmail(pengelola.email) === false) {
            errorList = { ...errorList, email: "Enter a valid email!" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    const loadDaftarPengelolaMaterial = () => {
        let id_pengelola = props.match.params.id;
        getMaterialByPengelolaID(id_pengelola).then(res => {
            let m = res.data.document;
            m.forEach(element => {
                element.is_edit = false;
            });
            setDaftarMaterial(m);
        }).catch(error => {
            console.log(error);
            alert("terjadi kesalahan sistem :(");
        });
    }

    useEffect(() => {
        if (props.match.params.id) {
            setTitle("Edit Pengelola");

            getOnePengelola(props.match.params.id).then((res) => {
                setPengelola(res.data.document)
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

            getAllMaterial(null, 1, 1000).then(res => {
                let materialRaw = [];
                if (res && res.data && res.data.document && res.data.document.records) {
                    materialRaw = res.data.document.records;
                } else {
                    alert("terjadi kesalahan sistem :(");
                }
                setMaterialOptions(materialRaw);
                setAddMaterialForm(
                    {
                        id_pengelola: props.match.params.id,
                        id_material: materialRaw.length > 0 ? materialRaw[0].id : undefined,
                        harga: 1000,
                        jenis: 'pemasukan'
                    });
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

            loadDaftarPengelolaMaterial();

            getPerizinanByPengelolaID(props.match.params.id).then((res) => {
                if (res.data.document) {
                    setDaftarPerizinan(res.data.document);
                } else {
                    setDaftarPerizinan([]);
                }
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });

            getKerjasamaByIDPengelolaIsWithPJB(props.match.params.id, true).then(res => {
                if (res && res.data && res.data.document && res.data.document.records) {
                    let data = res.data.document.records;
                    getKerjasamaByIDPengelolaIsWithPJB(props.match.params.id, false).then(res => {
                        if (res && res.data && res.data.document && res.data.document.records) {
                            res.data.document.records.forEach(k => {
                                data.push(k);
                            })
                        }
                        setDaftarKerjasama(data);
                    }).catch(error => {
                        console.log(error);
                        alert("terjadi kesalahan sistem :(");
                    });
                } else {
                    setDaftarKerjasama([]);
                }
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

            getLast10PengambilanByPengelolaID(props.match.params.id).then(res => {
                if (res && res.data && res.data.document && res.data.document.records) {
                    let data = [];
                    res.data.document.records.forEach(p => {
                        p.material.forEach(m => {
                            data.push(m);
                        })
                    })
                    setDaftarPengambilan(data);
                } else {
                    setDaftarPengambilan([]);
                }
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

        } else {
            setPengelola({})
            setTitle("Tambah Pengelola Baru");
        }
    }, [props.match.params.id])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (pengelola.id) {
                    var updateResponse = await updatePengelola(pengelola.id, pengelola);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                    //props.history.push("/");
                } else {
                    var addResponse = await addPengelola(pengelola)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                    }
                    //props.history.push("/");
                }
            } else {
                console.log(errors);
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
            }
        } catch (e) {
            console.log(e);
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
        }
    }

    const handleAddMaterial = async (e) => {
        e.preventDefault();
        let id_pengelola = props.match.params.id;
        addPengelolaMaterial(id_pengelola, addMaterialForm).then(res => {
            if (res && res.data && res.data.code === 200) {
                setDaftarMaterial(undefined);
                loadDaftarPengelolaMaterial();
            } else {
                alert("terjadi kesalahan sistem :( asd");
            }
        }).catch(error => {
            if (error.response) {
                // Request made and server responded
                if (error.response.data.code === 500 && error.response.data.message && error.response.data.message.code === "ER_DUP_ENTRY") {
                    setAlertstate({ ...alertState, open: true, severity: 'warning', message: 'Pengelola sudah dapat mengelola material tersebut!' });
                }
                console.log(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                alert("terjadi kesalahan sistem, kesalahan jaringan");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                alert("terjadi kesalahan sistem :(");
            }
        });
    };

    const handleUpdateMaterial = (idx) => {
        let id_pengelola = props.match.params.id;
        let data = daftarMaterial[idx];
        let id_material = data.id_material;
        updatePengelolaMaterial(id_pengelola, id_material, data).then(res => {
            let oldState = [...daftarMaterial];
            if (res && res.data && res.data.code === 200) {
                oldState[idx] = data;
            } else {
                alert("terjadi kesalahan sistem :(");
            }
            oldState[idx].is_edit = false;
            setDaftarMaterial(oldState);
        }).catch(error => {
            console.log(error);
            alert("terjadi kesalahan sistem :(");
        });
    };

    const handleDeleteMaterial = (idx) => {
        let id_pengelola = props.match.params.id;
        let id_material = daftarMaterial[idx].id_material;
        deletePengelolaMaterial(id_pengelola, id_material).then(res => {
            if (res && res.data && res.data.code === 200) {
                let oldState = [...daftarMaterial];
                oldState.splice(idx, 1);
                setDaftarMaterial(oldState);
            } else {
                alert("terjadi kesalahan sistem :(");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="pengelola" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={8} xs={12}>
                    <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {(pengelola !== undefined) ?
                                    <Grid spacing={3} container>
                                        <Grid xs={12} md={6} key={"0"} item>
                                            <TextField required autoFocus fullWidth
                                                helperText={errorMessages.nama_perusahaan}
                                                type={"text"}
                                                onChange={(e) => { setPengelola({ ...pengelola, nama_perusahaan: e.target.value }); checkErrors() }}
                                                defaultValue={pengelola.nama_perusahaan}
                                                error={(errorMessages.nama_perusahaan) ? true : false}
                                                label={"Nama Perusahaan"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"1"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.nama_pimpinan}
                                                type={"text"}
                                                onChange={(e) => { setPengelola({ ...pengelola, nama_pimpinan: e.target.value }); checkErrors() }}
                                                defaultValue={pengelola.nama_pimpinan}
                                                error={(errorMessages.nama_pimpinan) ? true : false}
                                                label={"Nama Pimpinan"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"2"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.alamat}
                                                type={"text"}
                                                onChange={(e) => { setPengelola({ ...pengelola, alamat: e.target.value }); checkErrors() }}
                                                defaultValue={pengelola.alamat}
                                                error={(errorMessages.alamat) ? true : false}
                                                label={"Alamat"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"3"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.telepon}
                                                type={"text"}
                                                onChange={(e) => { setPengelola({ ...pengelola, telepon: e.target.value }); checkErrors() }}
                                                defaultValue={pengelola.telepon}
                                                error={(errorMessages.telepon) ? true : false}
                                                label={"No. Telepon"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"4"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.email}
                                                type={"email"}
                                                onChange={(e) => { setPengelola({ ...pengelola, email: e.target.value }); checkErrors() }}
                                                defaultValue={pengelola.email}
                                                error={(errorMessages.email) ? true : false}
                                                label={"Email"} />
                                        </ Grid >
                                        <Grid md={12} key={"6"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Pengelola Internal? *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} align="left">
                                                <Checkbox
                                                    checked={pengelola.is_internal == 1}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setPengelola({ ...pengelola, is_internal: e.target.checked ? 1 : 0 });
                                                        checkErrors();
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} item key={"7"}>
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => history.push('/dashboard/pengelola')} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Submit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    :
                                    (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)
                                }
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

                {props.match.params.id ?
                    (
                        <Grid item md={8} xs={12} style={{ marginTop: 20 }}>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Daftar Material yang dapat Dikelola</b>
                                        <br />
                                        {daftarMaterial ?
                                            <div>
                                                <table style={{ width: '100%', marginTop: 20 }}>
                                                    <tr>
                                                        <th>Kode</th>
                                                        <th>Material</th>
                                                        <th>Limbah B3?</th>
                                                        <th>Harga (Rp/Ton)</th>
                                                        <th>Jenis</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                    {daftarMaterial.map((material, idx) =>
                                                        <tr key={material.id_material}>
                                                            <td>{material.kode_material}</td>
                                                            <td>{material.nama}</td>
                                                            <td>{material.is_b3 ? '✓' : '✗'}</td>
                                                            <td>
                                                                <TextField required
                                                                    value={material.harga}
                                                                    disabled={!material.is_edit}
                                                                    type={"number"}
                                                                    onChange={(e) => {
                                                                        let oldState = [...daftarMaterial];
                                                                        oldState[idx].harga = e.target.value;
                                                                        setDaftarMaterial(oldState);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {!material.is_edit ? <p>{material.jenis.charAt(0).toUpperCase() + material.jenis.slice(1)}</p> :
                                                                    <Select style={{ width: '100%', height: '100%', textAlign: 'left' }}
                                                                        id="jenis-material"
                                                                        value={material.jenis}
                                                                        onChange={(e) => {
                                                                            let oldState = [...daftarMaterial];
                                                                            oldState[idx].jenis = e.target.value;
                                                                            setDaftarMaterial(oldState);
                                                                        }}
                                                                    >
                                                                        <MenuItem key={0} value={'pemasukan'}>Pemasukan</MenuItem>
                                                                        <MenuItem key={1} value={'biaya'}>Biaya</MenuItem>
                                                                    </Select>
                                                                }
                                                            </td>
                                                            <td>
                                                                {!material.is_edit ?
                                                                    <Edit onClick={() => {
                                                                        let oldState = [...daftarMaterial];
                                                                        oldState[idx].is_edit = true;
                                                                        setDaftarMaterial(oldState);
                                                                    }} />
                                                                    :
                                                                    null
                                                                }
                                                                {material.is_edit ?
                                                                    <Save onClick={() => {
                                                                        handleUpdateMaterial(idx);
                                                                    }} />
                                                                    :
                                                                    null
                                                                }
                                                                <DeleteOutline onClick={() => handleDeleteMaterial(idx)} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                                {daftarMaterial.length === 0 ? <p>belum ada material yang bisa dikelola</p> : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                        {materialOptions && addMaterialForm ?
                                            <div>
                                                <br />
                                                <b>Tambah Material Baru:</b>
                                                <form onSubmit={handleAddMaterial}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={4}>
                                                            <Select style={{ width: '100%', height: '100%', textAlign: 'left' }}
                                                                id="material"
                                                                value={addMaterialForm.id_material}
                                                                onChange={(e) => {
                                                                    setAddMaterialForm({ ...addMaterialForm, id_material: e.target.value });
                                                                }}
                                                            >
                                                                {materialOptions.map((material) => <MenuItem key={material.id} value={material.id}>{material.kode_material} - {material.nama}</MenuItem>)}
                                                            </Select>
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <TextField required fullWidth
                                                                value={addMaterialForm.harga}
                                                                type={"number"}
                                                                label={"Harga (Rp/Ton)"}
                                                                onChange={(e) => setAddMaterialForm({ ...addMaterialForm, harga: e.target.value })} />
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <Select style={{ width: '100%', height: '100%', textAlign: 'left' }}
                                                                id="jenis"
                                                                value={addMaterialForm.jenis}
                                                                onChange={(e) => {
                                                                    setAddMaterialForm({ ...addMaterialForm, jenis: e.target.value });
                                                                }}
                                                            >
                                                                <MenuItem key={0} value={'pemasukan'}>Pemasukan</MenuItem>
                                                                <MenuItem key={1} value={'biaya'}>Biaya</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item md={2}>
                                                            <Button style={{ height: '80%', marginTop: '5%', marginBottom: '5%' }} variant={"outlined"} color="primary" type={"Submit"}>Tambah</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </div>
                                            : null
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Daftar Perizinan</b>
                                        <br />
                                        {daftarPerizinan ?
                                            <div>
                                                <table width="100%">
                                                    {
                                                        daftarPerizinan.map(
                                                            (p) => (
                                                                <tr>
                                                                    <td>{p.nama_perizinan}</td>
                                                                    <td>{p.tanggal_selesai ? p.tanggal_selesai.substring(0, 10) : '✗'}</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </table>
                                                {daftarPerizinan.length > 0 ? <small><br />Lihat lebih detail di menu "Administrasi → Perizinan"</small> : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Data Kerjasama</b>
                                        <br />
                                        {daftarKerjasama ?
                                            <div>
                                                <table width="100%">
                                                    {
                                                        daftarKerjasama.map((k) =>
                                                        (
                                                            <tr>
                                                                <td>{k.is_with_pjb ? 'UP Paiton' : k.nama_pengangkut}</td>
                                                                <td><small>{k.tanggal_selesai.substring(0, 10)}</small></td>
                                                            </tr>
                                                        )
                                                        )
                                                    }
                                                </table>
                                                {daftarKerjasama.length > 0 && !daftarKerjasama[0].is_with_pjb ? <small style={{ color: 'red' }}><b>Belum ada kerjasama dengan UP Paiton!</b></small> : null}
                                                {daftarKerjasama.length > 0 ? <small><br />Lihat lebih detail di menu "Administrasi → Kerjasama"</small> : null}
                                                {daftarKerjasama.length == 0 ? 'belum memiliki kerjasama apapun' : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>10 Pengambilan Terakhir</b>
                                        <br />
                                        {daftarPengambilan ?
                                            <div>
                                                <table width="100%">
                                                    {daftarPengambilan.map((m) => (<tr><td>{m.kode_material}</td><td>{m.tonase} ton</td></tr>))}
                                                </table>
                                                {daftarPengambilan.length > 0 ? <small><br />Lihat lebih detail di menu "Pengambilan Limbah"</small> : null}
                                                {daftarPengambilan.length == 0 ? 'belum ada pengambilan' : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    :
                    null
                }

            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(PengelolaAddUpdatePage)
