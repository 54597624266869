import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { CircularProgress, Typography, Select, MenuItem } from "@material-ui/core";
import history from '../../history';
import { addUser, getOneUser, updateUser } from "../../repo/userRepo";
import { getPengelola } from "../../repo/pengelolaRepo";
import { getPengangkut } from "../../repo/pengangkutRepo";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const UserAddUpdatePage = (props) => {
  const [alertState, setAlertstate] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    severity: "success",
    message: "",
  });
  const { vertical, horizontal, open, severity, message } = alertState;
  const [errorMessages, setErrorMessages] = useState({});
  const [user, setUser] = useState(undefined);
  const [title, setTitle] = useState("Add/Update Data User");
  const [pengelola, setPengelola] = useState([]);
  const [pengangkut, setPengangkut] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [isAddMode, setIsAddMode] = useState(true);

  let pengangkutDict = {};
  let pengelolaDict = {};

  const checkErrors = () => {
    let errorList = {}
    if (user.nama === "" || user.nama === undefined) {
      errorList = { ...errorList, nama: "Required field!" }
    }
    if (user.email === "" || validateEmail(user.email) === false) {
      errorList = { ...errorList, email: "Enter a valid email!" }
    }
    if (user.username === "" || user.username === undefined) {
      errorList = { ...errorList, username: "Required field!" }
    }
    if (user.password === "" || user.password === undefined) {
      errorList = { ...errorList, password: "Required field!" }
    }
    if (user.password && user.password.length < 8) {
      errorList = { ...errorList, password: "Minimum password 8 karakter" }
    }
    if (user.repassword === "" || user.repassword === undefined) {
      errorList = { ...errorList, repassword: "Required field!" }
    }
    if (user.password && user.repassword && user.password != user.repassword) {
      errorList = { ...errorList, repassword: "Kata sandi tidak sama!" }
    }
    if (user.role === "" || user.role === undefined) {
      errorList = { ...errorList, role: "Required field!" }
    }

    setErrorMessages(errorList)
    return errorList
  }

  useEffect(() => {
    getPengelola(0, 1000, "").then((res) => {
      res.records.forEach(p => {
        p.nama = p.nama_perusahaan;
      })
      for (let p of res.records) {
        pengelolaDict[p.id] = p;
      }
      setPengelola(res.records);
    });
    getPengangkut(0, 1000, "").then((res) => {
      res.records.forEach(p => {
        p.nama = p.nama_perusahaan;
      })
      for (let p of res.records) {
        pengangkutDict[p.id] = p;
      }
      setPengangkut(res.records);
    });

    if (props.match.params.id) {
      setIsAddMode(false);
      setTitle("Edit Data User");
    } else {
      setTitle("Tambah User Baru");
    }
  }, [props.match.params.id])

  useEffect(() => {
    if (pengelola.length > 0 && pengangkut.length > 0) {
      if (props.match.params.id) {
        getOneUser(props.match.params.id).then((res) => {
          let tempUser = res.data.document;
          let id_pemilik = 0;
          if (tempUser.role === 'pengelola') {
            let idx = 0;
            while (idx < pengelola.length && pengelola[idx].username != tempUser.username) {
              idx += 1;
            }
            id_pemilik = pengelola[idx].id;
          }
          if (tempUser.role === 'pengangkut') {
            let idx = 0;
            while (idx < pengangkut.length && pengangkut[idx].username != tempUser.username) {
              idx += 1;
            }
            id_pemilik = pengangkut[idx].id;
          }
          tempUser.id_pemilik = id_pemilik;
          setUser(tempUser);
        })
      } else {
        setUser({
          role: 'admin',
        });
      }
    }
  }, [pengelola, pengangkut]);

  useEffect(() => {
    if (user) checkErrors();

    if (user && user.role) {
      if (user.role == 'pengelola') {
        setOwnerOptions(pengelola);
      }
      if (user.role == 'pengangkut') {
        setOwnerOptions(pengangkut);
      }
    }

  }, [user]);

  React.useEffect(() => {
    console.log(">>>>", props);
  }, []);

  const handleUserRoleChange = (newRole) => {
    setUser((data) => {
      let id_pemilik = undefined;
      let email = '';
      if (newRole === 'pengelola' && pengelola.length > 0) {
        id_pemilik = pengelola[0].id;
        email = pengelola[0].email;
      }
      if (newRole === 'pengangkut' && pengangkut.length > 0) {
        id_pemilik = pengangkut[0].id;
        email = pengangkut[0].email;
      }
      return { ...data, role: newRole, id_pemilik: id_pemilik, email: email };
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const errors = checkErrors()
      //if no errors then send data
      if (Object.keys(errors).length < 1) {
        if (user.id) {
          var updateResponse = await updateUser(user.id, user);
          if (updateResponse && updateResponse.data) {
            if (updateResponse.data.code === 200) {
              props.history.push({
                pathname: "/dashboard/user",
                state: { alert: { open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" } },
              });
            } else {
              setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
            }
          } else {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
          }
          //props.history.push("/");
        } else {
          addUser(user).then((addResponse) => {
            if (addResponse && addResponse.data) {
              if (addResponse.data.code === 200) {
                props.history.push({
                  pathname: "/dashboard/user",
                  state: { alert: { open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" } },
                });
              } else if (addResponse.data.code === 400) {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: addResponse.data.message, severity: "error" });
              } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
              }
            } else {
              setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
            }
          }).catch(err => {
            let errMessage = JSON.stringify(err);
            if (err.response && err.response.data && err.response.data.message) {
              errMessage = err.response.data.message;
            }
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
          });
          //props.history.push("/");
        }
      } else {
        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
      }
    } catch (e) {
      setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
    }
  }

  const hideAlert = () => {
    setAlertstate({ ...alertState, open: false });
  };

  return (
    <PageTemplate title={title} menu="user" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
        <Grid item md={6} xs={12}>
          <Card style={{ width: '100%', marginTop: 20 }}>
            <CardHeader title={title} />
            <CardContent>
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {(user !== undefined) ?
                  <Grid spacing={3} container>

                    <Grid md={12} key={"10"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Jenis User *
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Select style={{ width: '100%', textAlign: 'left' }}
                          id="role"
                          value={user.role ? user.role : ''}
                          disabled={!isAddMode}
                          onChange={(e) => { handleUserRoleChange(e.target.value) }}>
                          <MenuItem value={'admin'}>User Admin</MenuItem>
                          <MenuItem value={'sekretariat'}>User Sekretariat</MenuItem>
                          <MenuItem value={'pengelola'}>User Pengelola</MenuItem>
                          <MenuItem value={'pengangkut'}>User Pengangkut</MenuItem>
                          <MenuItem value={'pos1'}>User Pos POMI</MenuItem>
                          <MenuItem value={'pos2'}>User Pos Sikam</MenuItem>
                          <MenuItem value={'operasi'}>User Operasi</MenuItem>
                          <MenuItem value={'operasi-internal'}>User Operasi Internal</MenuItem>
                          <MenuItem value={'lingkungan'}>User Lingkungan</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>

                    {(user.role == 'pengelola' || user.role == 'pengangkut') ?
                      <Grid md={12} key={"20"} item container direction="row">
                        <Grid item md={4}>
                          <Typography variant="body1" align="left">
                            Perusahaan *
                          </Typography>
                        </Grid>
                        <Grid item md={8}>
                          <Select style={{ width: '100%', textAlign: 'left' }}
                            id="role"
                            disabled={!isAddMode}
                            value={user.id_pemilik ? user.id_pemilik : ''}
                            onChange={(e) => {
                              setUser((data) => {
                                let idx = 0;
                                while (idx < ownerOptions.length && ownerOptions[idx].id != e.target.value) {
                                  idx += 1;
                                }
                                return { ...data, id_pemilik: e.target.value, email: ownerOptions[idx].email };
                              })
                            }}>
                            {ownerOptions.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                          </Select>
                        </Grid>
                      </Grid>
                      : null}

                    <Grid md={12} key={"40x"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Jabatan
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          type={"text"}
                          onChange={(e) => { setUser({ ...user, jabatan: e.target.value }) }}
                          value={user.jabatan ? user.jabatan : ''}
                          error={(errorMessages.jabatan) ? true : false}
                          placeholder={"Contoh: Pengawas Lapangan"} />
                      </Grid>
                    </Grid>

                    <Grid md={12} key={"40"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Email *
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          helperText={errorMessages.email}
                          type={"email"}
                          onChange={(e) => { setUser({ ...user, email: e.target.value }) }}
                          value={user.email ? user.email : ''}
                          disabled={!isAddMode || user.role == 'pengelola' || user.role == 'pengangkut'}
                          error={(errorMessages.email) ? true : false}
                          placeholder={"user@uppaiton.com"} />
                      </Grid>
                    </Grid>

                    <Grid md={12} key={"30"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Nama Akun *
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          helperText={errorMessages.nama}
                          type={"text"}
                          onChange={(e) => { setUser({ ...user, nama: e.target.value }) }}
                          value={user.nama ? user.nama : ''}
                          error={(errorMessages.nama) ? true : false}
                          placeholder={"Nama pemilik akun"} />
                      </Grid>
                    </Grid>

                    <Grid md={12} key={"70"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Username *
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          helperText={errorMessages.username}
                          type={"text"}
                          onChange={(e) => { setUser({ ...user, username: e.target.value }) }}
                          value={user.username ? user.username : ''}
                          error={(errorMessages.username) ? true : false} />
                      </Grid>
                    </Grid>

                    <Grid md={12} key={"50"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Kata Sandi *
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          helperText={errorMessages.password}
                          type={"password"}
                          onChange={(e) => { setUser({ ...user, password: e.target.value }) }}
                          value={user.password ? user.password : ''}
                          error={(errorMessages.password) ? true : false} />
                      </Grid>
                    </Grid>

                    <Grid md={12} key={"60"} item container direction="row">
                      <Grid item md={4}>
                        <Typography variant="body1" align="left">
                          Ulangi Kata Sandi *
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <TextField autoFocus fullWidth
                          helperText={errorMessages.repassword}
                          type={"password"}
                          onChange={(e) => { setUser({ ...user, repassword: e.target.value }) }}
                          value={user.repassword ? user.repassword : ''}
                          error={(errorMessages.repassword) ? true : false} />
                      </Grid>
                    </Grid>

                    <Grid md={12} item key={"5"} >
                      <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                        <Button onClick={() => history.push('/dashboard/user')} variant={"contained"} type={"Button"}>Kembali</Button>
                        <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Sumbit"}>Simpan</Button>
                      </Grid>
                    </Grid>

                  </Grid>
                  : (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)}
              </form>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={hideAlert}
        key={vertical + horizontal}>
        <Alert onClose={hideAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </PageTemplate>
  )
}

export default withRouter(UserAddUpdatePage)
