import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Switch } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../../history';
import { addPengambilan, getPengambilan, getOnePengambilan, updatePengambilan } from "../../repo/pengambilanRepo";
import { getKendaraan } from "../../repo/kendaraanRepo";
import { getPengangkut } from "../../repo/pengangkutRepo";



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const PengambilanAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [pengambilan, setPengambilan] = useState(undefined)
    const [kendaraan, setKendaraan] = useState(undefined)
    const [pengangkut, setPengangkut] = useState(undefined)


    const checkErrors = () => {
        let errorList = {}
        if (pengambilan.id_kendaraan === "" || pengambilan.id_kendaraan === undefined) {
            errorList = { ...errorList, id_kendaraan: "Required field!" }
        }
        if (pengambilan.id_pengangkut === "" || pengambilan.id_pengangkut === undefined) {
            errorList = { ...errorList, id_pengangkut: "Required field!" }
        }
        if (pengambilan.status_akhir === "" || pengambilan.status_akhir === undefined) {
            errorList = { ...errorList, status_akhir: "Required field!" }
        }
        if (pengambilan.is_internal === "" || pengambilan.is_internal === undefined) {
            errorList = { ...errorList, is_internal: "Required field!" }
        }
        if (pengambilan.is_b3 === "" || pengambilan.is_b3 === undefined) {
            errorList = { ...errorList, is_b3: "Required field!" }
        }


        setErrorMessages(errorList)
        return errorList
    }

    useEffect(() => {
        getKendaraan(0, 200, "").then((res) => { setKendaraan(res.records) })
        getPengangkut(0, 200, "").then((res) => { setPengangkut(res.records) })


        if (props.match.params.id) {
            getOnePengambilan(props.match.params.id).then((res) => {
                setPengambilan(res.data.document)
            })
        } else {
            setPengambilan({})
        }
    }, [props.match.params.id])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (pengambilan.id) {
                    var updateResponse = await updatePengambilan(pengambilan.id, pengambilan);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                    //props.history.push("/");
                } else {
                    var addResponse = await addPengambilan(pengambilan)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });

                    }
                    //props.history.push("/");
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

        }

    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };
    return (
        <PageTemplate title="Add/Update Pengambilan">
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">

                        {(pengambilan !== undefined && kendaraan !== undefined && pengangkut !== undefined) ?
                            <Grid spacing={3} container>
                                <Grid xs={12} md={6} key={"2"} item>
                                    <TextField required autoFocus fullWidth
                                        helperText={errorMessages.status_akhir}
                                        type={"text"}
                                        onChange={(e) => { setPengambilan({ ...pengambilan, status_akhir: e.target.value }); checkErrors() }}
                                        defaultValue={pengambilan.status_akhir}
                                        error={(errorMessages.status_akhir) ? true : false}
                                        label={"status_akhir"} />
                                </ Grid >
                                <Grid xs={12} md={6} key={"3"} item>
                                    <TextField required autoFocus fullWidth
                                        helperText={errorMessages.is_internal}
                                        type={"number"}
                                        onChange={(e) => { setPengambilan({ ...pengambilan, is_internal: e.target.value }); checkErrors() }}
                                        defaultValue={pengambilan.is_internal}
                                        error={(errorMessages.is_internal) ? true : false}
                                        label={"is_internal"} />
                                </ Grid >
                                <Grid xs={12} md={6} key={"4"} item>
                                    <TextField required autoFocus fullWidth
                                        helperText={errorMessages.is_b3}
                                        type={"number"}
                                        onChange={(e) => { setPengambilan({ ...pengambilan, is_b3: e.target.value }); checkErrors() }}
                                        defaultValue={pengambilan.is_b3}
                                        error={(errorMessages.is_b3) ? true : false}
                                        label={"is_b3"} />
                                </ Grid >
                                <Grid xs={12} md={6} key={"7"} item >
                                    <InputLabel style={{ textAlign: 'left' }}>id_kendaraan</InputLabel>
                                    <Select style={{ width: '100%', textAlign: 'left' }}
                                        labelId="id_kendaraan"
                                        id="id_kendaraan"
                                        value={pengambilan.id_kendaraan}
                                        onChange={(e) => { setPengambilan({ ...pengambilan, id_kendaraan: e.target.value }); checkErrors() }}>
                                        {kendaraan.map((key) => <MenuItem key={key.id} value={key.id}>{key.plat_nomor}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid xs={12} md={6} key={"8"} item >
                                    <InputLabel style={{ textAlign: 'left' }}>id_pengangkut</InputLabel>
                                    <Select style={{ width: '100%', textAlign: 'left' }}
                                        labelId="id_pengangkut"
                                        id="id_pengangkut"
                                        value={pengambilan.id_pengangkut}
                                        onChange={(e) => { setPengambilan({ ...pengambilan, id_pengangkut: e.target.value }); checkErrors() }}>
                                        {pengangkut.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid xs={12} md={6} item key={"9"}>
                                    <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                        <Button onClick={() => history.push('/dashboard/pengambilan')} variant={"contained"} type={"Button"} color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={6} item key={"10"}>
                                    <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                        <Button variant={"contained"} color="primary" type={"Sumbit"}>Save</Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : null}
                    </form>


                </CardContent>
            </Card>
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(PengambilanAddUpdatePage)
