import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Switch } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../../../history';
import { addPerizinan, getPerizinan, getOnePerizinan, updatePerizinan } from "../../../repo/perizinanRepo";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const PerizinanAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [perizinan, setPerizinan] = useState(undefined)
    const [title, setTitle] = useState("Add/Update Jenis Perizinan")

    const checkErrors = () => {
        let errorList = {}
        if (perizinan.nama_perizinan === "" || perizinan.nama_perizinan === undefined) {
            errorList = { ...errorList, nama_perizinan: "Required field!" }
        }
        if (perizinan.is_b3 === "" || perizinan.is_b3 === undefined) {
            errorList = { ...errorList, is_b3: "Required field!" }
        }
        if (perizinan.is_required === "" || perizinan.is_required === undefined) {
            errorList = { ...errorList, is_required: "Required field!" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    const handleChange = (event) => {
        setPerizinan({ ...perizinan, peruntukan: event.target.value });
    };

    useEffect(() => {
        if (props.match.params.id) {
            setTitle("Edit Jenis Perizinan");
            getOnePerizinan(props.match.params.id).then((res) => {
                setPerizinan(res.data.document)
            })
        } else {
            setTitle("Tambah Jenis Perizinan Baru");
            setPerizinan({ peruntukan: 'pengelola', is_b3: 0, is_required: 0 });
        }
    }, [props.match.params.id])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (perizinan.id) {
                    var updateResponse = await updatePerizinan(perizinan.id, perizinan);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                } else {
                    var addResponse = await addPerizinan(perizinan)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                    }
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
        }
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={5} xs={12}>
                    <Card style={{ marginTop: 20, width: '100%' }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {(perizinan !== undefined) ?
                                    <Grid spacing={3} container>
                                        <Grid md={12} key={"0"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Nama Perizinan *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField required autoFocus fullWidth
                                                    helperText={errorMessages.nama_perizinan}
                                                    type={"text"}
                                                    onChange={(e) => { setPerizinan({ ...perizinan, nama_perizinan: e.target.value }); checkErrors() }}
                                                    defaultValue={perizinan.nama_perizinan}
                                                    error={(errorMessages.nama_perizinan) ? true : false}
                                                    placeholder={"Cth: Izin Pengolahan Limbah"} />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} key={"1"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Peruntukan *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={7}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={perizinan.peruntukan}
                                                    onChange={handleChange}
                                                    style={{ textAlign: 'left', backgroundColor: 'white' }}
                                                    fullWidth>
                                                    <MenuItem value={"pengelola"}>Perusahaan Pengelola</MenuItem>
                                                    <MenuItem value={"pengangkut"}>Perusahaan Pengangkut</MenuItem>
                                                    <MenuItem value={"kendaraan"}>Kendaraan</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} key={"2"} item container direction="row">
                                            <Grid item md={6}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Diperlukan untuk penanganan Limbah B3?
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6} align="left">
                                                <Radio
                                                    checked={perizinan.is_nonb3 == 1}
                                                    color="primary"
                                                    onChange={(e) => { setPerizinan({ ...perizinan, is_nonb3: e.target.checked ? 1 : 0, is_required:  e.target.checked ? 0 : 1}); checkErrors() }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Diperlukan untuk penanganan Limbah Non-B3?
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item md={6} align="left">
                                                <Checkbox
                                                    checked={perizinan.is_b3 == 1}
                                                    color="primary"
                                                    onChange={(e) => { setPerizinan({ ...perizinan, is_b3: e.target.checked ? 1 : 0 }); checkErrors() }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Wajib untuk semua jenis pengambilan?
                                                </Typography>
                                            </Grid> */}
                                            <Grid item md={6} align="left">
                                                <Radio
                                                    checked={perizinan.is_required == 1}
                                                    color="primary"
                                                    onChange={(e) => { setPerizinan({ ...perizinan, is_required: e.target.checked ? 1 : 0, is_nonb3:  e.target.checked ? 0 : 1}); checkErrors() }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} item key={"6"} >
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => { history.push('/dashboard/administrasi?menu=1'); }} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Sumbit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)}
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(PerizinanAddUpdatePage)
