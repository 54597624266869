import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const GetIntern_Leak_DetectorColumns = (totalCount) => [
  {title: "Id", field: "id",hidden:true},

  {title: "Waktu", field: "waktu",type:"date"},
{title: "Status1", field: "status_1"},
{title: "Status2", field: "status_2"},
{title: "Status3", field: "status_3"},
{title: "Status4", field: "status_4"},
{title: "Status5", field: "status_5"},
{title: "Status6", field: "status_6"},
{title: "Status7", field: "status_7"},
{title: "UrlFoto1", field: "url_foto_1"},
{title: "UrlFoto2", field: "url_foto_2"},
{title: "UrlFoto3", field: "url_foto_3"},
{title: "UrlFoto4", field: "url_foto_4"},
{title: "UrlFoto5", field: "url_foto_5"},
{title: "UrlFoto6", field: "url_foto_6"},
{title: "UrlFoto7", field: "url_foto_7"},
{title: "InsertedAt", field: "inserted_at",type:"datetime"},
{title: "UpdatedAt", field: "updated_at",type:"datetime"},

]
export default GetIntern_Leak_DetectorColumns;
