import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import history from './../../history';
import { Copyright } from '../templates/Template';
import { getCachedToken, tokenAPICALL } from '../../auth/auth';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { Loading } from '../templates/Loading';
import { getRoleFromJWTToken } from '../../repo/notificationRepo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(undefined);

  function processLogin() {
    setIsLoading(true);
    tokenAPICALL(username, password).then((response) => {
      if (response.token) {
        props.setRole(response.role);

        if (response.role == 'sekretariat') {
          history.push({
            pathname: '/dashboard/s/pengambilan',
            state: { fullName: response.fullName }
          });
          return;
        }

        if (response.role == 'keuangan') {
          history.push({
            pathname: '/dashboard/f/main',
            state: { fullName: response.fullName }
          });
          return;
        }

        if (response.role == 'pos1') {
          history.push({
            pathname: '/dashboard/p1/main',
            state: { fullName: response.fullName }
          });
          return;
        }

        if (response.role == 'pos2') {
          history.push({
            pathname: '/dashboard/p2/main',
            state: { fullName: response.fullName }
          });
          return;
        }

        history.push({
          pathname: '/dashboard/main',
          state: { fullName: response.fullName }
        });
      } else {
        setError("terjadi kesalahan sistem :(");
      }
    }, (err) => {
      if (err.response && err.response.status == 400) {
        setError('username atau password salah');
      } else {
        setError("terjadi kesalahan sistem :(");
      }
    }).finally(() => {
      setPassword('');
      setIsLoading(false);
    });
  }

  // redirect to dashboard if already logged in
  let cachedToken = getCachedToken();
  if (cachedToken) {
    let role = getRoleFromJWTToken();
    if (role === 'admin') {
      return <Redirect to='/dashboard/main' />;
    } else if (role === 'sekretariat') {
      return <Redirect to='/dashboard/s/pengambilan' />;
    } else if (role === 'keuangan') {
      return <Redirect to='/dashboard/f/main' />;
    } else if (role === 'pos1') {
      return <Redirect to='/dashboard/p1/main' />;
    } else if (role === 'pos2') {
      return <Redirect to='/dashboard/p2/main' />;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      {isLoading ? <Loading /> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <img src={process.env.PUBLIC_URL + '/main_logo.png'} width={'35%'} />
        <Typography component="h1" variant="h5" style={{ marginTop: 40 }}>
          Log In - Waste Management
        </Typography>
        {error && <Container><Alert severity="error">{error}</Alert></Container>}
        <form className={classes.form} noValidate onSubmit={(e) => { e.preventDefault(); if (!(!username || !password || isLoading)) { processLogin() }; }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            disabled={isLoading}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={isLoading}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={processLogin}
            className={classes.submit}
            type="submit"
            disabled={!username || !password || isLoading}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}