import React, { useEffect, useState } from 'react';
import './App.css';
import { getCachedToken } from "./auth/auth";
import { getRoleFromJWTToken } from './repo/notificationRepo';
import Routes from './Routes';

function App() {
  console.disableYellowBox = true;
  const isMobile = window.innerWidth <= 500;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    getCachedToken();
    if (!isMobile) {
      setIsDrawerOpen(true);
    }

    let cachedRole = getRoleFromJWTToken();
    if (!role && cachedRole) {
      setRole(cachedRole);
      console.log(">>>" + cachedRole);
    }
  }, []);

  return (
    <div className="App">
      <Routes
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        role={role}
        setRole={setRole}
      />
    </div>
  );
}

export default App;
