import PageTemplate from "../templates/Template";
import Intern_CondensorTable from "./Intern_CondensorTable";
import React from "react";

const Intern_CondensorPage = (props) => {
    return(
    <PageTemplate title="Intern_Condensor List">
        <Intern_CondensorTable/>
    </PageTemplate>)
}
export default Intern_CondensorPage;
