import api from "../api/axios";

const uploadFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return api.post(`/files/upload/`, formData, {
        headers: {
            'Content-Type': `multipart/form-data boundary=${formData._boundary}`,
        }
    });
}

export {uploadFile};


