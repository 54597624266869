import PageTemplate from "../templates/Template";
import Pengambilan_LogTable from "./Pengambilan_LogTable";
import React from "react";

const Pengambilan_LogPage = (props) => {
    return(
    <PageTemplate title="Pengambilan_Log List">
        <Pengambilan_LogTable/>
    </PageTemplate>)
}
export default Pengambilan_LogPage;
