import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Switch } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../../history';
import { addPengelola_Material, getPengelola_Material, getOnePengelola_Material, updatePengelolaMaterial } from "../../repo/pengelola_materialRepo";
import { getMaterial } from "../../repo/materialRepo";



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const Pengelola_MaterialAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [pengelola_material, setPengelola_Material] = useState(undefined)
    const [material, setMaterial] = useState(undefined)


    const checkErrors = () => {
        let errorList = {}
        if (pengelola_material.id_material === "" || pengelola_material.id_material === undefined) {
            errorList = { ...errorList, id_material: "Required field!" }
        }


        setErrorMessages(errorList)
        return errorList
    }

    useEffect(() => {
        getMaterial(0, 200, "").then((res) => { setMaterial(res.records) })


        if (props.match.params.id) {
            getOnePengelola_Material(props.match.params.id).then((res) => {
                setPengelola_Material(res.data.document)
            })
        } else {
            setPengelola_Material({})
        }
    }, [props.match.params.id])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (pengelola_material.id_pengelola) {
                    var updateResponse = await updatePengelolaMaterial(pengelola_material);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                    //props.history.push("/");
                } else {
                    var addResponse = await addPengelola_Material(pengelola_material)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });

                    }
                    //props.history.push("/");
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

        }

    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };
    return (
        <PageTemplate title="Add/Update Pengelola_Material">
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">

                        {(pengelola_material !== undefined && material !== undefined) ?
                            <Grid spacing={3} container>
                                <Grid xs={12} md={6} key={"1"} item >
                                    <InputLabel style={{ textAlign: 'left' }}>id_material</InputLabel>
                                    <Select style={{ width: '100%', textAlign: 'left' }}
                                        labelId="id_material"
                                        id="id_material"
                                        value={pengelola_material.id_material}
                                        onChange={(e) => { setPengelola_Material({ ...pengelola_material, id_material: e.target.value }); checkErrors() }}>
                                        {material.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid xs={12} md={6} item key={"2"}>
                                    <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                        <Button onClick={() => history.push('/pengelola_material')} variant={"contained"} type={"Button"} color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={6} item key={"3"}>
                                    <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                        <Button variant={"contained"} color="primary" type={"Sumbit"}>Save</Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : null}
                    </form>


                </CardContent>
            </Card>
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(Pengelola_MaterialAddUpdatePage)
