import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const GetPengambilan_MaterialColumns = (totalCount) => [
  {title: "IdPengambilan", field: "id_pengambilan",hidden:true},

  {title: "IdMaterial", field: "id_material"},
{title: "Tonase", field: "tonase"},
{title: "IdMaterialValue", field: "id_material_Value"},

]
export default GetPengambilan_MaterialColumns;
