import { useState } from "react";
import { withRouter } from "react-router";
import { Box, Typography, Grid, Tabs, Tab, withStyles, makeStyles } from '@material-ui/core';
import MaterialDataSubpage from "./MaterialDataSubpage";
import SumberMaterialDataSubpage from "./SumberMaterialDataSubpage";

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#3d5bcc',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#3d5bca',
            opacity: 1,
        },
        '&$selected': {
            color: '#3d5bcc',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#3d5bca',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: '#635ee7',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#fff',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    menuTabs: {
        backgroundColor: theme.palette.background.paper,
        width: '105%',
        marginLeft: '-2.6%',
    },
}));

const MaterialMenuTabs = (props) => {
    const classes = useStyles();
    const titleData = ['Data Limbah', 'Sumber Limbah'];
    const [selectedMenuID, setSelectedMenuID] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedMenuID(newValue);
    };

    return (<div className={classes.root}>
        <div>
            <AntTabs value={selectedMenuID} onChange={handleChange} aria-label="ant example" className={classes.menuTabs}>
                <AntTab label={titleData[0]} />
                <AntTab label={titleData[1]} />
            </AntTabs>
            <Box style={{ height: 8, backgroundColor: '#3d5bcc' }} className={classes.menuTabs}/>
            {selectedMenuID == 0 && <MaterialDataSubpage history={props.history}/>}
            {selectedMenuID == 1 && <SumberMaterialDataSubpage />}
        </div>
    </div>);
};

export default withRouter(MaterialMenuTabs);