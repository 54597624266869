import PageTemplate from "../templates/Template";
import Pengambilan_MaterialTable from "./Pengambilan_MaterialTable";
import React from "react";

const Pengambilan_MaterialPage = (props) => {
    return(
    <PageTemplate title="Pengambilan_Material List">
        <Pengambilan_MaterialTable/>
    </PageTemplate>)
}
export default Pengambilan_MaterialPage;
