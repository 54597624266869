import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Switch } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PageTemplate from "../../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import history from '../../../history';
import { addPerizinan_Pemegang, getPerizinan_Pemegang, getOnePerizinan_Pemegang, updatePerizinan_Pemegang } from "../../../repo/perizinan_pemegangRepo";
import { getPerizinan } from "../../../repo/perizinanRepo";
import { getKendaraan } from "../../../repo/kendaraanRepo";
import { getPengelola } from "../../../repo/pengelolaRepo";
import { getPengangkut } from "../../../repo/pengangkutRepo";
import FileSelector from "../../templates/FileSelector";
import { uploadFile } from "../../../repo/fileRepo";
import { apiUrl } from "../../../api/axios";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const Perizinan_PemegangAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [perizinan_pemegang, setPerizinan_Pemegang] = useState(undefined)
    const [jenisPerizinan, setJenisPerizinan] = useState(undefined)
    const [perizinan, setPerizinan] = useState(undefined)
    const [pemegang, setPemegang] = useState([]);
    const [pengelola, setPengelola] = useState(undefined);
    const [pengangkut, setPengangkut] = useState(undefined);
    const [kendaraan, setKendaraan] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [title, setTitle] = useState("Add/Update Data Perizinan");
    const [peruntukan, setPeruntukan] = useState('pengelola');
    const [peruntukanOptions, setPeruntukanOptions] = useState([
        'pengelola', 'pengangkut', 'kendaraan'
    ]);
    const [perizinanPengelola, setPerizinanPengelola] = useState(undefined);
    const [perizinanPengangkut, setPerizinanPengangkut] = useState(undefined);
    const [perizinanKendaraan, setPerizinanKendaraan] = useState(undefined);
    const [kendaraanOwner, setKendaraanOwner] = useState(undefined);
    const [kendaraanByPengangkutID, setKendaraanByPengangkutID] = useState({});

    const checkErrors = () => {
        let errorList = {}
        if (perizinan_pemegang.tanggal_selesai === "" || perizinan_pemegang.tanggal_selesai === undefined) {
            errorList = { ...errorList, tanggal_selesai: "Required field!" }
        }
        if (perizinan_pemegang.jenis_izin === "" || perizinan_pemegang.jenis_izin === undefined) {
            errorList = { ...errorList, jenis_izin: "Required field!" }
        }
        if (perizinan_pemegang.no_dokumen === "" || perizinan_pemegang.no_dokumen === undefined) {
            errorList = { ...errorList, no_dokumen: "Required field!" }
        }
        if (perizinan_pemegang.pemilik === "" || perizinan_pemegang.pemilik === undefined) {
            errorList = { ...errorList, pemilik: "Required field!" }
        }
        if (selectedFile == undefined && props.match.params.id === undefined) {
            errorList = { ...errorList, url: "Required field!" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    useEffect(() => {
        let perizinanPengelolaTemp = [];
        let perizinanPengangkutTemp = [];
        let perizinanKendaraanTemp = [];
        getPerizinan(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                if (p.peruntukan === 'pengelola') perizinanPengelolaTemp.push(p);
                if (p.peruntukan === 'pengangkut') perizinanPengangkutTemp.push(p);
                if (p.peruntukan === 'kendaraan') perizinanKendaraanTemp.push(p);
            })
            setPerizinanPengelola(perizinanPengelolaTemp);
            setPerizinanPengangkut(perizinanPengangkutTemp);
            setPerizinanKendaraan(perizinanKendaraanTemp);
            setJenisPerizinan(res.records);
            // console.log("perizinan loaded");
        });
        getKendaraan(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                p.nama = p.plat_nomor;
            })
            setKendaraan(res.records);
            let kendaraanByPengangkutID = {};
            if (res.records.length > 0) {
                res.records.forEach((k) => {
                    if (kendaraanByPengangkutID[k.id_pengangkut] == undefined) kendaraanByPengangkutID[k.id_pengangkut] = [];
                    kendaraanByPengangkutID[k.id_pengangkut].push(k);
                });
            }
            setKendaraanByPengangkutID(kendaraanByPengangkutID);
            // console.log("kendaraan loaded");
        });
        getPengelola(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                p.nama = p.nama_perusahaan;
            })
            setPengelola(res.records);
            // console.log("pengelola loaded");
        });
        getPengangkut(0, 1000, "").then((res) => {
            res.records.forEach(p => {
                p.nama = p.nama_perusahaan;
            })
            setPengangkut(res.records);
            if (res.records.length > 0) setKendaraanOwner(res.records[0].id);
            // console.log("pengangkut loaded");
        });
        if (props.match.params.id) {
            setTitle("Edit Data Perizinan")
        } else {
            setTitle("Tambah Data Perizinan")
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (jenisPerizinan && perizinanPengelola && perizinanPengangkut && perizinanKendaraan && kendaraan && pengelola && pengangkut) {
            if (props.match.params.id != undefined) {
                getOnePerizinan_Pemegang(props.match.params.id).then((res) => {
                    if (res.data.document.tanggal_selesai && res.data.document.tanggal_selesai.length > 0) {
                        let tanggalSelesai = new Date(res.data.document.tanggal_selesai);
                        res.data.document.tanggal_selesai = tanggalSelesai.toISOString().substring(0, 10);
                    }
                    let perizinanPemegang = res.data.document;
                    let jenisIzinID = perizinanPemegang.jenis_izin;
                    let jenisIzinPeruntukan = 'pengelola';
                    for (let i = 0; i < jenisPerizinan.length; i++) {
                        if (jenisPerizinan[i].id === jenisIzinID) {
                            jenisIzinPeruntukan = jenisPerizinan[i].peruntukan;
                            break;
                        }
                    }

                    setPeruntukan(jenisIzinPeruntukan);
                    if (jenisIzinPeruntukan === 'pengelola') {
                        setPerizinan(perizinanPengelola);
                        setPemegang(pengelola);
                    }
                    if (jenisIzinPeruntukan === 'pengangkut') {
                        setPerizinan(perizinanPengangkut);
                        setPemegang(pengangkut);
                    }
                    if (jenisIzinPeruntukan === 'kendaraan') {
                        setPerizinan(perizinanKendaraan);
                        setPemegang(kendaraan);
                    }
                    // TODO: set options correctly
                    setPerizinan_Pemegang(perizinanPemegang);
                });
            } else {
                // Setting default options
                setPeruntukan('pengelola')
                setPerizinan(perizinanPengelola);
                setPemegang(pengelola);

                // Setting the real data
                setPerizinan_Pemegang(
                    {
                        ...perizinan_pemegang,
                        jenis_izin: perizinanPengelola.length > 0 ? perizinanPengelola[0].id : '',
                        pemilik: pengelola.length > 0 ? pengelola[0].id : ''
                    });
            }
        }
    }, [jenisPerizinan, perizinanPengelola, perizinanPengangkut, perizinanKendaraan, kendaraan, pengelola, pengangkut]);

    useEffect(() => {
        if (perizinan_pemegang) {
            if (peruntukan === 'pengelola' && perizinanPengelola) {
                setPerizinan(perizinanPengelola);
                setPemegang(pengelola);
                setPerizinan_Pemegang({ ...perizinan_pemegang, jenis_izin: perizinanPengelola.length > 0 ? perizinanPengelola[0].id : '', pemilik: pengelola.length > 0 ? pengelola[0].id : '' });
            }
            if (peruntukan === 'pengangkut' && perizinanPengangkut) {
                setPerizinan(perizinanPengangkut);
                setPemegang(pengangkut);
                setPerizinan_Pemegang({ ...perizinan_pemegang, jenis_izin: perizinanPengangkut.length > 0 ? perizinanPengangkut[0].id : '', pemilik: pengangkut.length > 0 ? pengangkut[0].id : '' });
            }
            if (peruntukan === 'kendaraan' && perizinanKendaraan) {
                setPerizinan(perizinanKendaraan);
                if (pengangkut.length > 0) setKendaraanOwner(pengangkut[0].id);
                setPemegang(kendaraanByPengangkutID[pengangkut[0].id] ? kendaraanByPengangkutID[pengangkut[0].id] : []);
                setPerizinan_Pemegang(
                    {
                        ...perizinan_pemegang,
                        jenis_izin: perizinanKendaraan.length > 0 ? perizinanKendaraan[0].id : '',
                        pemilik: kendaraanByPengangkutID[pengangkut[0].id] && kendaraanByPengangkutID[pengangkut[0].id].length > 0 ? kendaraanByPengangkutID[pengangkut[0].id][0].id : ''
                    }
                );
            }
        }
    }, [peruntukan]);

    useEffect(() => {
        if (perizinan_pemegang && kendaraan && pengangkut && peruntukan === 'kendaraan') {
            setPemegang(kendaraanByPengangkutID[kendaraanOwner] ? kendaraanByPengangkutID[kendaraanOwner] : []);
            setPerizinan_Pemegang(
                {
                    ...perizinan_pemegang,
                    pemilik: kendaraanByPengangkutID[kendaraanOwner] && kendaraanByPengangkutID[kendaraanOwner].length > 0 ? kendaraanByPengangkutID[kendaraanOwner][0].id : ''
                }
            );
        }
    }, [kendaraanOwner]);

    const handleSelectedFile = (file) => {
        setSelectedFile(file, checkErrors);
    }

    const createOrAdddPerizinanPemegang = async (data) => {
        try {
            if (data.id) {
                var updateResponse = await updatePerizinan_Pemegang(data.id, data);
                if (updateResponse && updateResponse.data) {
                    if (updateResponse.data.code === 1) {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                }
                //props.history.push("/");
            } else {
                var addResponse = await addPerizinan_Pemegang(data)
                if (addResponse && addResponse.data) {
                    if (addResponse.data.code === 1) {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                    }
                } else {
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                }
                //props.history.push("/");
            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const errors = checkErrors()
        //if no errors then send data
        if (Object.keys(errors).length < 1) {
            // Upload file
            if (selectedFile) {
                uploadFile(selectedFile).then((res) => {
                    if (res.data && res.data.document && res.data.document.file_id) {
                        let uploadedFileURL = apiUrl + '/file/' + res.data.document.file_id;
                        createOrAdddPerizinanPemegang({ ...perizinan_pemegang, url: uploadedFileURL });
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: 'gagal mengunggah dokumen', severity: "error" });
                    }
                }).catch(err => {
                    let errMessage = JSON.stringify(err);
                    if (err.response && err.response.data && err.response.data.message) {
                        errMessage = err.response.data.message;
                    }
                    setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errMessage, severity: "error" });
                })
            } else {
                createOrAdddPerizinanPemegang(perizinan_pemegang);
            }
        } else {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });
        }
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={8} xs={12}>
                    <Card style={{ marginTop: 20, width: '100%' }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {(perizinan_pemegang !== undefined && perizinan) ?
                                    <Grid spacing={3} container>

                                        <Grid md={12} key={"80"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Peruntukan
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Select style={{ width: '100%', textAlign: 'left' }}
                                                    id="peruntukan"
                                                    value={peruntukan ? peruntukan : ''}
                                                    onChange={(e) => {
                                                        setPeruntukan(e.target.value);
                                                    }}>
                                                    {peruntukanOptions.map((key) => <MenuItem key={key} value={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"0"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Jenis Izin
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Select style={{ width: '100%', textAlign: 'left' }}
                                                    id="jenis_izin"
                                                    value={perizinan_pemegang.jenis_izin ? perizinan_pemegang.jenis_izin : ''}
                                                    onChange={(e) => {
                                                        setPerizinan_Pemegang({ ...perizinan_pemegang, jenis_izin: e.target.value });
                                                        checkErrors();
                                                    }}>
                                                    {perizinan.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama_perizinan}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"1"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    No. Dokumen *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField autoFocus fullWidth
                                                    helperText={errorMessages.no_dokumen}
                                                    type={"text"}
                                                    onChange={(e) => { setPerizinan_Pemegang({ ...perizinan_pemegang, no_dokumen: e.target.value }); checkErrors() }}
                                                    value={perizinan_pemegang.no_dokumen ? perizinan_pemegang.no_dokumen : ''}
                                                    error={(errorMessages.no_dokumen) ? true : false}
                                                    placeholder={"Cth: ABC-1233-12-23"} />
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"2"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Tanggal Selesai *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField required autoFocus fullWidth
                                                    helperText={errorMessages.tanggal_selesai}
                                                    type={"date"}
                                                    InputLabelProps={{ shrink: true, }}
                                                    onChange={(e) => { setPerizinan_Pemegang({ ...perizinan_pemegang, tanggal_selesai: e.target.value }); checkErrors() }}
                                                    value={perizinan_pemegang.tanggal_selesai ? perizinan_pemegang.tanggal_selesai : ''}
                                                    error={(errorMessages.tanggal_selesai) ? true : false} />
                                            </Grid>
                                        </Grid>

                                        {peruntukan === 'kendaraan' && <Grid md={12} key={"30"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Perusahaan Pengangkut *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Select style={{ width: '100%', textAlign: 'left' }}
                                                    id="pemilik"
                                                    value={kendaraanOwner != undefined ? kendaraanOwner : ''}
                                                    onChange={(e) => setKendaraanOwner(e.target.value)}>
                                                    {pengangkut.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        </Grid>}

                                        <Grid md={12} key={"3"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Pemegang Izin *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Select style={{ width: '100%', textAlign: 'left' }}
                                                    id="pemilik"
                                                    value={perizinan_pemegang.pemilik ? perizinan_pemegang.pemilik : ''}
                                                    onChange={(e) => { setPerizinan_Pemegang({ ...perizinan_pemegang, pemilik: e.target.value }); checkErrors() }}>
                                                    {pemegang.map((key) => <MenuItem key={key.id} value={key.id}>{key.nama}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} key={"4"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Dokumen Izin *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <FileSelector handleSelectedFile={handleSelectedFile} thumbnailURL={perizinan_pemegang && perizinan_pemegang.url ? perizinan_pemegang.url : null} />
                                                {(errorMessages.url != "" || errorMessages.url != undefined) && <p style={{ textAlign: 'left', color: '#f44336', fontSize: '0.75rem', fontWeight: 400 }}>{errorMessages.url}</p>}
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} item key={"5"} >
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => history.push('/dashboard/administrasi?menu=0')} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Sumbit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)}
                            </form>


                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(Perizinan_PemegangAddUpdatePage)
