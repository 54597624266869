import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengambilan_Material = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPengambilan_Material(pageNo+1,pageSize);
    }

    else{
        try {
            res = await searchPengambilan_Material(search,pageNo+1,pageSize);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addPengambilan_Material = (data) => {
return api.post(`/pengambilan_material`,data)
}
const updatePengambilan_Material = (id_pengambilan,data) => {
return api.put(`/pengambilan_material/${id_pengambilan}`,data)
}
const getAllPengambilan_Material = (pageNo,pageSize) => {
return api.get(`/pengambilan_material/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePengambilan_Material = (id_pengambilan) => {
return api.get(`/pengambilan_material/${id_pengambilan}`)
}
const searchPengambilan_Material = (pageNo,pageSize,searchKey) => {
return api.get(`/pengambilan_material/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePengambilan_Material = (id_pengambilan) => {
return api.delete(`/pengambilan_material/${id_pengambilan}`)
}
export {getPengambilan_Material,addPengambilan_Material,updatePengambilan_Material,getAllPengambilan_Material,getOnePengambilan_Material,searchPengambilan_Material,deletePengambilan_Material}


