import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { CircularProgress, CardHeader } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import history from '../../history';
import { DeleteOutline, Edit, Save } from "@material-ui/icons";
import { addPengangkut, getPengangkut, getOnePengangkut, updatePengangkut, getPerizinanByPengangkutID } from "../../repo/pengangkutRepo";
import { addPengangkutMaterial, deletePengangkutMaterial, getMaterialByPengangkutID } from "../../repo/pengangkutMaterialRepo";
import { getAllMaterial } from "../../repo/materialRepo";
import { getLast10PengambilanByPengangkutID } from "../../repo/pengambilanRepo";
import { getKerjasamaByIDPengangkutIsWithPJB } from "../../repo/kerjasamaRepo";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CardCircularProgress(props) {
    return <div style={{ width: '100%', paddingTop: 20, textAlign: 'center' }}>
        <CircularProgress size={30} />
    </div>
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const PengangkutAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [pengangkut, setPengangkut] = useState(undefined)
    const [title, setTitle] = useState("Add/Update Data Pengangkut");
    const [daftarPerizinan, setDaftarPerizinan] = useState(undefined);
    const [daftarMaterial, setDaftarMaterial] = useState(undefined);
    const [materialOptions, setMaterialOptions] = useState(undefined);
    const [daftarKerjasama, setDaftarKerjasama] = useState(undefined);
    const [daftarPengambilan, setDaftarPengambilan] = useState(undefined);
    const [addMaterialForm, setAddMaterialForm] = useState(undefined);

    const checkErrors = () => {
        let errorList = {}
        if (pengangkut.nama_perusahaan === "" || pengangkut.nama_perusahaan === undefined) {
            errorList = { ...errorList, nama_perusahaan: "Required field!" }
        }
        if (pengangkut.nama_pimpinan === "" || pengangkut.nama_pimpinan === undefined) {
            errorList = { ...errorList, nama_pimpinan: "Required field!" }
        }
        if (pengangkut.alamat === "" || pengangkut.alamat === undefined) {
            errorList = { ...errorList, alamat: "Required field!" }
        }
        if (pengangkut.telepon === "" || pengangkut.telepon === undefined) {
            errorList = { ...errorList, telepon: "Required field!" }
        }
        if (pengangkut.email === "" || validateEmail(pengangkut.email) === false) {
            errorList = { ...errorList, email: "Enter a valid email!" }
        }
        if (pengangkut.is_internal === "" || pengangkut.is_internal === undefined) {
            errorList = { ...errorList, is_internal: "Required field!" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    const loadDaftarPengangkutMaterial = () => {
        let id_pengangkut = props.match.params.id;
        getMaterialByPengangkutID(id_pengangkut).then(res => {
            let m = res.data.document;
            m.forEach(element => {
                element.is_edit = false;
            });
            setDaftarMaterial(m);
        }).catch(error => {
            console.log(error);
            alert("terjadi kesalahan sistem :(");
        });
    }

    useEffect(() => {
        if (props.match.params.id) {
            setTitle("Edit Pengangkut");

            getOnePengangkut(props.match.params.id).then((res) => {
                setPengangkut(res.data.document)
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });

            getAllMaterial(null, 1, 1000).then(res => {
                let materialRaw = [];
                if (res && res.data && res.data.document && res.data.document.records) {
                    materialRaw = res.data.document.records;
                } else {
                    alert("terjadi kesalahan sistem :(");
                }
                setMaterialOptions(materialRaw);
                setAddMaterialForm(
                    {
                        id_pengelola: props.match.params.id,
                        id_material: materialRaw.length > 0 ? materialRaw[0].id : undefined,
                    });
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

            loadDaftarPengangkutMaterial();

            getPerizinanByPengangkutID(props.match.params.id).then((res) => {
                if (res.data.document) {
                    setDaftarPerizinan(res.data.document);
                } else {
                    setDaftarPerizinan([]);
                }
            }).catch(error => {
                console.log(error);
                alert("Terjadi kesalahan sistem :(");
            });

            getKerjasamaByIDPengangkutIsWithPJB(props.match.params.id, true).then(res => {
                if (res && res.data && res.data.document && res.data.document.records) {
                    let data = res.data.document.records;
                    getKerjasamaByIDPengangkutIsWithPJB(props.match.params.id, false).then(res => {
                        if (res && res.data && res.data.document && res.data.document.records) {
                            res.data.document.records.forEach(k => {
                                data.push(k);
                            })
                        }
                        setDaftarKerjasama(data);
                    }).catch(error => {
                        console.log(error);
                        alert("terjadi kesalahan sistem :(");
                    });
                } else {
                    setDaftarKerjasama([]);
                }
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

            getLast10PengambilanByPengangkutID(props.match.params.id).then(res => {
                if (res && res.data && res.data.document && res.data.document.records) {
                    let data = [];
                    res.data.document.records.forEach(p => {
                        p.material.forEach(m => {
                            data.push(m);
                        })
                    })
                    setDaftarPengambilan(data);
                } else {
                    setDaftarPengambilan([]);
                }
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });

        } else {
            setTitle("Tambah Pengangkut Baru");
            setPengangkut({is_internal: false});
        }
    }, [props.match.params.id])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (pengangkut.id) {
                    var updateResponse = await updatePengangkut(pengangkut.id, pengangkut);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                    //props.history.push("/");
                } else {
                    var addResponse = await addPengangkut(pengangkut)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });

                    }
                    //props.history.push("/");
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Invalid Data. Please try again.", severity: "error" });

        }

    }

    const handleAddMaterial = async (e) => {
        e.preventDefault();
        let id_pengangkut = props.match.params.id;
        addPengangkutMaterial(id_pengangkut, addMaterialForm).then(res => {
            if (res && res.data && res.data.code === 200) {
                setDaftarMaterial(undefined);
                loadDaftarPengangkutMaterial();
            } else {
                alert("terjadi kesalahan sistem :( asd");
            }
        }).catch(error => {
            if (error.response) {
                // Request made and server responded
                if (error.response.data.code === 500 && error.response.data.message && error.response.data.message.code === "ER_DUP_ENTRY") {
                    setAlertstate({ ...alertState, open: true, severity: 'warning', message: 'Pengangkut sudah dapat mengangkut material tersebut!' });
                }
                console.log(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                alert("terjadi kesalahan sistem, kesalahan jaringan");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                alert("terjadi kesalahan sistem :(");
            }
        });
    };

    const handleDeleteMaterial = (idx) => {
        let id_pengangkut = props.match.params.id;
        let id_material = daftarMaterial[idx].id_material;
        deletePengangkutMaterial(id_pengangkut, id_material).then(res => {
            if (res && res.data && res.data.code === 200) {
                let oldState = [...daftarMaterial];
                oldState.splice(idx, 1);
                setDaftarMaterial(oldState);
            } else {
                alert("terjadi kesalahan sistem :(");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="pengangkut" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={8} xs={12}>
                    <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">

                                {(pengangkut !== undefined) ?
                                    <Grid spacing={3} container>
                                        <Grid xs={12} md={6} key={"0"} item>
                                            <TextField required autoFocus fullWidth
                                                helperText={errorMessages.nama_perusahaan}
                                                type={"text"}
                                                onChange={(e) => { setPengangkut({ ...pengangkut, nama_perusahaan: e.target.value }); checkErrors() }}
                                                defaultValue={pengangkut.nama_perusahaan}
                                                error={(errorMessages.nama_perusahaan) ? true : false}
                                                label={"Nama Perusahaan"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"1"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.nama_pimpinan}
                                                type={"text"}
                                                onChange={(e) => { setPengangkut({ ...pengangkut, nama_pimpinan: e.target.value }); checkErrors() }}
                                                defaultValue={pengangkut.nama_pimpinan}
                                                error={(errorMessages.nama_pimpinan) ? true : false}
                                                label={"Nama Pimpinan"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"2"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.alamat}
                                                type={"text"}
                                                onChange={(e) => { setPengangkut({ ...pengangkut, alamat: e.target.value }); checkErrors() }}
                                                defaultValue={pengangkut.alamat}
                                                error={(errorMessages.alamat) ? true : false}
                                                label={"Alamat"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"3"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.telepon}
                                                type={"text"}
                                                onChange={(e) => { setPengangkut({ ...pengangkut, telepon: e.target.value }); checkErrors() }}
                                                defaultValue={pengangkut.telepon}
                                                error={(errorMessages.telepon) ? true : false}
                                                label={"No. Telepon"} />
                                        </ Grid >
                                        <Grid xs={12} md={6} key={"4"} item>
                                            <TextField required fullWidth
                                                helperText={errorMessages.email}
                                                type={"email"}
                                                onChange={(e) => { setPengangkut({ ...pengangkut, email: e.target.value }); checkErrors() }}
                                                defaultValue={pengangkut.email}
                                                error={(errorMessages.email) ? true : false}
                                                label={"Email"} />
                                        </ Grid >
                                        <Grid md={12} key={"6"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Pengangkut Internal? *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} align="left">
                                                <Checkbox
                                                    checked={pengangkut.is_internal == 1}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setPengangkut({ ...pengangkut, is_internal: e.target.checked ? 1 : 0 });
                                                        checkErrors();
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} item key={"7"}>
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => history.push('/dashboard/pengangkut')} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Submit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    :
                                    (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)
                                }
                            </form>


                        </CardContent>
                    </Card>
                </Grid>

                {props.match.params.id ?
                    (
                        <Grid item md={8} xs={12} style={{ marginTop: 20 }}>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Daftar Material yang dapat Diangkut</b>
                                        <br />
                                        {daftarMaterial ?
                                            <div>
                                                <table style={{ width: '100%', marginTop: 20 }}>
                                                    <tr>
                                                        <th>Kode</th>
                                                        <th>Material</th>
                                                        <th>Limbah B3?</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                    {daftarMaterial.map((material, idx) =>
                                                        <tr key={material.id_material}>
                                                            <td>{material.kode_material}</td>
                                                            <td>{material.nama}</td>
                                                            <td>{material.is_b3 ? '✓' : '✗'}</td>
                                                            <td>
                                                                <DeleteOutline onClick={() => handleDeleteMaterial(idx)} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                                {daftarMaterial.length === 0 ? <p>belum ada material yang bisa diangkut</p> : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                        {materialOptions && addMaterialForm ?
                                            <div>
                                                <br />
                                                <b>Tambah Material Baru:</b>
                                                <form onSubmit={handleAddMaterial}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={10}>
                                                            <Select style={{ width: '100%', height: '100%', textAlign: 'left' }}
                                                                id="role"
                                                                value={addMaterialForm.id_material}
                                                                onChange={(e) => {
                                                                    setAddMaterialForm({ ...addMaterialForm, id_material: e.target.value });
                                                                }}
                                                            >
                                                                {materialOptions.map((material) => <MenuItem key={material.id} value={material.id}>{material.kode_material} - {material.nama}</MenuItem>)}
                                                            </Select>
                                                        </Grid>
                                                        <Grid item md={2}>
                                                            <Button style={{ width: '100%', height: '80%', marginTop: '5%', marginBottom: '5%' }} variant={"outlined"} color="primary" type={"Submit"}>Tambah</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </div>
                                            : null
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Daftar Perizinan</b>
                                        <br />
                                        {daftarPerizinan ?
                                            <div>
                                                <table width="100%">
                                                    {
                                                        daftarPerizinan.map(
                                                            (p) => (
                                                                <tr>
                                                                    <td>{p.nama_perizinan}</td>
                                                                    <td>{p.tanggal_selesai ? p.tanggal_selesai.substring(0, 10) : '✗'}</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </table>
                                                {daftarPerizinan.length > 0 ? <small><br />Lihat lebih detail di menu "Administrasi → Perizinan"</small> : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>Data Kerjasama</b>
                                        <br />
                                        {daftarKerjasama ?
                                            <div>
                                                <table width="100%">
                                                    {
                                                        daftarKerjasama.map((k) =>
                                                        (
                                                            <tr>
                                                                <td>{k.is_with_pjb ? 'UP Paiton' : k.nama_pengelola}</td>
                                                                <td><small>{k.tanggal_selesai.substring(0, 10)}</small></td>
                                                            </tr>
                                                        )
                                                        )
                                                    }
                                                </table>
                                                {daftarKerjasama.length > 0 && !daftarKerjasama[0].is_with_pjb ? <small style={{ color: 'red' }}><b>Belum ada kerjasama dengan UP Paiton!</b></small> : null}
                                                {daftarKerjasama.length > 0 ? <small><br />Lihat lebih detail di menu "Administrasi → Kerjasama"</small> : null}
                                                {daftarKerjasama.length == 0 ? 'belum memiliki kerjasama apapun' : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Card style={{ textAlign: 'left', padding: 20 }}>
                                        <b>10 Pengambilan Terakhir</b>
                                        <br />
                                        {daftarPengambilan ?
                                            <div>
                                                <table width="100%">
                                                    {daftarPengambilan.map((m) => (<tr><td>{m.kode_material}</td><td>{m.tonase} ton</td></tr>))}
                                                </table>
                                                {daftarPengambilan.length > 0 ? <small><br />Lihat lebih detail di menu "Pengambilan Limbah"</small> : null}
                                                {daftarPengambilan.length == 0 ? 'belum ada pengambilan' : null}
                                            </div>
                                            :
                                            <CardCircularProgress />
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>)
                    :
                    null
                }
            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </PageTemplate>
    )
}

export default withRouter(PengangkutAddUpdatePage)
