import PageTemplate from "../../templates/Template";
import Perizinan_PemegangTable from "./Perizinan_PemegangTable";
import React from "react";

const Perizinan_PemegangPage = (props) => {
    return (
        <PageTemplate title="Perizinan_Pemegang List" menu="administrasi" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Perizinan_PemegangTable />
        </PageTemplate>)
}
export default Perizinan_PemegangPage;
