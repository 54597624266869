import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getKerjasama = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllKerjasama(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchKerjasama(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addKerjasama = (data) => {
    return api.post(`/kerjasama`, data);
}

const updateKerjasama = (id, data) => {
    return api.put(`/kerjasama/${id}`, data);
}

const getAllKerjasama = (pageNo, pageSize) => {
    return api.get(`/kerjasama/?pageNo=${pageNo}&pageSize=${pageSize}`);
}

const getOneKerjasama = (id) => {
    return api.get(`/kerjasama/${id}`);
}

const searchKerjasama = (pageNo, pageSize, searchKey) => {
    return api.get(`/kerjasama/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`);
}

const deleteKerjasama = (id) => {
    return api.delete(`/kerjasama/${id}`);
}

const getKerjasamaByIDPengelolaIsWithPJB = (idPengelola, isWithPJB) => {
    return api.get(`/kerjasama/?id_pengelola=${idPengelola}&is_with_pjb=${isWithPJB ? 1 : 0}`);
}

const getKerjasamaByIDPengangkutIsWithPJB = (idPengangkut, isWithPJB) => {
    return api.get(`/kerjasama/?id_pengangkut=${idPengangkut}&is_with_pjb=${isWithPJB ? 1 : 0}`);
}

const getAllKerjasamaByAttribute = (isWithPJB, idPengelola, idPengangkut, pageNo, pageSize) => {
    return api.get(`/kerjasama/?pageNo=${pageNo}&pageSize=${pageSize}${isWithPJB != undefined ? `&is_with_pjb=${isWithPJB}` : ''}${idPengelola != undefined ? `&id_pengelola=${idPengelola}` : ''}${idPengangkut != undefined ? `&id_pengangkut=${idPengangkut}` : ''}`);
}

export { getAllKerjasamaByAttribute, getKerjasamaByIDPengangkutIsWithPJB, getKerjasamaByIDPengelolaIsWithPJB, getKerjasama, addKerjasama, updateKerjasama, getAllKerjasama, getOneKerjasama, searchKerjasama, deleteKerjasama }


