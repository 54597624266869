import React, { useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import history from '../../history';
import { countUnreadNotifications } from '../../repo/notificationRepo';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -6,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

export default function NotificationBadges(props) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        countUnreadNotifications().then(res => {
            if (res && res.data && res.data.document) {
                setCount(res.data.document.count);
            }
        }).catch(e => {
            console.log("error: ", e);
        })
    }, [])

    return (
        <IconButton aria-label="cart" style={{ width: 35 }} onClick={() => history.push({ pathname: '/dashboard/notifikasi', isDrawerOpen: props.isMobile ? false : true })}>
            <StyledBadge badgeContent={count} color="secondary">
                <NotificationsIcon style={{ color: 'white' }} />
            </StyledBadge>
        </IconButton>
    );
}