import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './MaterialColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { deleteMaterial, getMaterial } from "../../repo/materialRepo";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialTableView = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const tableRef = React.createRef();

    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deleteMaterial(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errorMessage, severity: "error" });
                resolve()
            })
    }

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [props.isb3]);

    return (
        <div>
            <MaterialTable
                tableRef={tableRef}
                minRows={10}
                title="Data Material Limbah"
                columns={columns}
                data={async (query) => {
                    const res = await getMaterial(props.isb3 == 'all' ? null : props.isb3, query.page, query.pageSize, query.search);
                    return ({
                        data: res.records,
                        page: query.page,
                        totalCount: parseInt(res.TotalRecord)
                    })
                }
                }
                options={{
                    sorting: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    toolbar: true,
                    paging: true
                }}

                actions={[
                    {
                        icon: () => <Edit />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                            history.push({
                                pathname: `/dashboard/material/update/${rowData.id}`,
                                user: rowData
                            })
                        }
                    }
                ]}

                icons={tableIcons}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}

            />
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>);
}
export default withRouter(MaterialTableView);
