import { withRouter } from "react-router";
import { Grid, TextField, Modal, makeStyles, Button, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { addSumberMaterial, updateSumberMaterial } from "../../repo/sumberMaterialRepo";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const SumberMaterialAddUpdateModal = (props) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [title, setTitle] = useState('Add/Update Sumber Material');
    const [buttonText, setButtonText] = useState('Tambah');
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState('');

    const handleClose = () => {
        props.setOpen(false);
        setValue('');
        props.setTableRefresh(!props.tableRefresh);
    };

    useEffect(() => {
        console.log(props.tableRef);
        setIsLoading(true);
        if (props.mode) {
            if (props.mode === 'add') {
                setTitle('Tambah Sumber Material Baru');
                setValue('');
                setButtonText('Tambah');
                setIsLoading(false);
            }
            if (props.mode === 'edit' && props.data && props.data.value) {
                setTitle('Edit Sumber Material');
                setValue(props.data.value);
                setButtonText('Simpan');
                setIsLoading(false);
            }
        }
    }, [props.mode && props.data]);

    const handleAddData = async (e) => {
        let payload = {
            nama: value,
        }
        if (value.length == 0) {
            props.setAlertState({ ...props.alertState, open: true, message: "Tidak dapat menginput data kosong", severity: "warning" });
            handleClose();
            return;
        }
        try {
            setIsLoading(true);
            let response = await addSumberMaterial(payload);
            if (response && response.data && response.data.code === 200) {
                props.setAlertState({ ...props.alertState, open: true, message: "Berhasil menambah data.", severity: "success" });
            } else {
                props.setAlertState({ ...props.alertState, open: true, message: "Terjadi kesalahan, harap ulangi lagi.", severity: "error" });
            }
            setIsLoading(false);
            handleClose();
        } catch (e) {
            console.log(e);
            props.setAlertState({ ...props.alertState, message: e.message, open: true, severity: "error" })
            setIsLoading(false);
            handleClose();
        }
    };

    const handleEditData = async (e) => {
        let payload = {
            id: props.data.id,
            nama: value,
        }
        if (value.length == 0) {
            props.setAlertState({ ...props.alertState, open: true, message: "Tidak dapat menginput data kosong", severity: "warning" });
            handleClose();
            return;
        }
        if (value === props.data.value) {
            props.setAlertState({ ...props.alertState, open: true, message: "Berhasil mengubah data.", severity: "success" });
            handleClose();
            return;
        }
        try {
            setIsLoading(true);
            let response = await updateSumberMaterial(props.data.id, payload);
            if (response && response.data && response.data.code === 200) {
                props.setAlertState({ ...props.alertState, open: true, message: "Berhasil mengubah data.", severity: "success" });
            } else {
                props.setAlertState({ ...props.alertState, open: true, message: "Terjadi kesalahan, harap ulangi lagi.", severity: "error" });
            }
            setIsLoading(false);
            handleClose();
        } catch (e) {
            console.log(e);
            props.setAlertState({ ...props.alertState, message: e.message, open: true, severity: "error" })
            setIsLoading(false);
            handleClose();
        }
    }

    return (<Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
    >
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{title}</h2>
            <div id="simple-modal-description">
                {!isLoading ?
                    <form noValidate autoComplete="off">
                        <Grid container direction="row" spacing={1}>
                            <Grid item style={{ width: '73%' }}>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Sumber Limbah"
                                    variant="outlined"
                                    value={value}
                                    onChange={(e) => { setValue(e.target.value) }}
                                />
                            </Grid>
                            <Grid item style={{ width: '27%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    style={{ height: '100%', width: '100%' }}
                                    onClick={() => {
                                        if (props.mode === 'add') handleAddData();
                                        if (props.mode === 'edit') handleEditData();
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    :
                    <CircularProgress />
                }
            </div>
        </div>
    </Modal>)
};

export default withRouter(SumberMaterialAddUpdateModal);