import api from "../api/axios";

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const getUsernameFromJWTToken = () => {
  let username = '';
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  const payload = parseJwt(token);
  username = payload.username;
  return username;
}

const getRoleFromJWTToken = () => {
  let role = '';
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  const payload = parseJwt(token);
  role = payload.role;
  return role;
}

const getNotifications = (pageNo, pageSize) => {
  let username = getUsernameFromJWTToken();
  let usernameQuery = username ? `&username=${username}` : ``;
  return api.get(`/notifikasi?pageNo=${pageNo}&pageSize=${pageSize}${usernameQuery}`);
}

const countUnreadNotifications = () => {
  let username = getUsernameFromJWTToken();
  return api.get(`/notifikasi/${username}/count`);
}

const setNotificationAsRead = (ids) => {
  const data = { ids: ids };
  return api.put(`notifikasi/mark-read`, data);
}

export { getNotifications, countUnreadNotifications, setNotificationAsRead, getRoleFromJWTToken }