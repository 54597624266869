import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { MainListItems } from './MenuItems';
import { getCachedToken } from '../../auth/auth';
import { getCachedFullName } from '../../auth/auth';
import { Redirect } from 'react-router';
import ConfirmDialog from './ConfirmDialog';
import { removeToken } from '../../auth/auth';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import history from './../../history';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withRouter } from "react-router";
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import NotificationBadges from './NotificationBadges';
import { getRoleFromJWTToken } from '../../repo/notificationRepo';

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
        PT Pembangkitan Jawa Bali
      </Link>{' '}
      2021
      {'.'}
    </Typography>
  );
}

const drawerWidth = 255;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#3d5bcc',
    color: 'white',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  offset: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  contentClose: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 64,
  },
  contentOpenMobile: {
    flexGrow: 1,
    height: '100vh',
    overflowX: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

function PageTemplate(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [confirmLogoutOpen, setConfirmLogoutOpen] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [userRole, setUserRole] = React.useState(undefined);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  let isMobile = (width <= 768);

  let namaAkun = getCachedFullName();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.setIsDrawerOpen) props.setIsDrawerOpen(open);
  }, [open]);

  useEffect(() => {
    if (props.isDrawerOpen != undefined) {
      setOpen(props.isDrawerOpen);
    }
    if (props.location && props.location.isDrawerOpen != undefined) {
      setOpen(props.location.isDrawerOpen);
    }
    if (isMobile) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    let cachedRole = getRoleFromJWTToken();
    if (!userRole && cachedRole) {
      setUserRole(cachedRole);
    }
  }, []);

  var jwt = null;
  jwt = getCachedToken();
  if (!jwt) {
    return <Redirect to='/dashboard' />
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      {!open ?
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{ textAlign: 'left' }} component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {props.title}
            </Typography>
            {userRole == "admin" && <NotificationBadges isMobile={isMobile} />}
          </Toolbar>
        </AppBar>
        : null}

      <Drawer
        variant="persistent"
        anchor="left"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {open ?
            <IconButton onClick={handleDrawerClose} color="inherit">
              <ChevronLeftIcon />
            </IconButton>
            :
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          }
        </div>

        {open ?
          <Container maxWidth="lg" style={{ marginLeft: 0, paddingBottom: 20 }}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Avatar>{namaAkun.length > 0 ? namaAkun[0] : '?'}</Avatar>
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: 'left' }} component="h3" color="inherit" noWrap>
                  {namaAkun.length > 0 ? namaAkun : 'Nama Akun'}
                </Typography>
              </Grid>
              <Grid item>
                {userRole == "admin" && <NotificationBadges isMobile={isMobile} />}
              </Grid>
            </Grid>
          </Container>
          : null
        }

        <List>
          <MainListItems menu={props.menu} isMobile={isMobile} handleDrawerClose={handleDrawerClose} />
        </List>

        <Divider />
        <List>
          <ListItem button>
            <ListItemText primary="Keluar" onClick={() => {
              setConfirmLogoutOpen(true);
            }} />
            <ConfirmDialog
              title=""
              open={confirmLogoutOpen}
              setOpen={setConfirmLogoutOpen}
              onConfirm={() => {
                removeToken();
                history.push('/dashboard/')
              }}
            >
              Apakah anda yakin ingin keluar dari aplikasi ini?
            </ConfirmDialog>
          </ListItem>
        </List>
        <Container style={{ backgroundColor: 'white', paddingTop: '3%', margin: '10%', marginTop: 'auto', width: '80%', borderRadius: 5 }}>
          <Box>
            <img src={process.env.PUBLIC_URL + '/main_logo.png'} width={'80%'} />
          </Box>
        </Container>

      </Drawer>

      <Grid container justify='center' className={clsx(!isMobile && open && classes.content, isMobile && open && classes.contentOpenMobile, !open && classes.contentClose)}>

        <Grid item style={{ width: '100%', paddingLeft: '2.5%', paddingRight: '2.5%' }}>
          {props.children}
        </Grid>

        <Container maxWidth="lg" className={classes.container}>
          <Box></Box>
        </Container>

        <Grid item className={classes.footer} style={{ minWidth: '100%' }}>
          <Container maxWidth="lg">
            <Typography variant="body1">Teman - Waste Management UP Paiton</Typography>
            <Copyright />
          </Container>
        </Grid>
      </Grid>

    </div>
  );
}

export default withRouter(PageTemplate);