import { Chip, Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";
import PengambilanStateChip from './PengambilanStateChip';
import { dateToStringID } from '../../repo/utils';

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */
const GetPengambilanColumns = (totalCount) => [
  { title: "Id", field: "id", hidden: true },
  {
    title: "No. Pengambilan", field: "no_pengambilan", render: rowData => {
      return <p>{rowData.no_pengambilan ? rowData.no_pengambilan.replace(/\s/g, "") : '-'}</p>
    }
  },
  {
    title: "Tanggal", field: "tanggal_pengambilan", type: "date", render: rowData => {
      let date = new Date(rowData.tanggal_pengambilan);
      return <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
    }
  },
  { title: "Pengelola", field: "nama_pengelola" },
  { title: "Pengangkut", field: "nama_pengangkut" },
  { title: "Kendaraan", field: "plat_nomor" },
  {
    title: "Status Akhir", field: "status_akhir", render: rowData => {
      return <PengambilanStateChip status={rowData.status_akhir} />
    }
  },
  {
    title: "Total Tonase", render: rowData => {
      let totalTonase = 0;
      if (rowData.material && rowData.material.length > 0) {
        rowData.material.forEach((m, idx) => {
          totalTonase += m.tonase;
        });
      }
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
      });
      var value = formatter.formatToParts(totalTonase).map(
        p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
      ).join('');
      return <p>{value}</p>
    }
  },
  {
    title: "Material", render: rowData => {
      let namaMaterial = '';
      if (rowData.material && rowData.material.length > 0) {
        rowData.material.forEach((m, idx) => {
          if (idx != 0) namaMaterial += ", ";
          namaMaterial += m.nama_material;
        });
      }
      return <p>{namaMaterial ? namaMaterial : '-'}</p>
    }
  },
  {
    title: "Jenis Pengolahan", render: rowData => {
      let jenisPengolahan = '';
      if (rowData.material && rowData.material.length > 0) {
        rowData.material.forEach((m, idx) => {
          if (m.intern_jenis_pengolahan) {
            if (jenisPengolahan.length > 0) jenisPengolahan += ", ";
            jenisPengolahan += m.intern_jenis_pengolahan;
          }
        });
      }
      return <p>{jenisPengolahan ? jenisPengolahan : '-'}</p>
    }
  },
  { title: "B3", field: "is_b3", type: "boolean" },
  { title: "UpdatedAt", field: "updated_at", type: "datetime", hidden: true },

]
export default GetPengambilanColumns;
