import PageTemplate from "../templates/Template";
import Pengelola_MaterialTable from "./Pengelola_MaterialTable";
import React from "react";

const Pengelola_MaterialPage = (props) => {
    return(
    <PageTemplate title="Pengelola_Material List">
        <Pengelola_MaterialTable/>
    </PageTemplate>)
}
export default Pengelola_MaterialPage;
