import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import tableIcons from '../../templates/TableIcons';
import getColumns from './PerizinanColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import AddIcon from '@material-ui/icons/Add';
import { Button, Hidden } from '@material-ui/core';
import { deletePerizinan, getPerizinan } from "../../../repo/perizinanRepo";
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const PerizinanTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deletePerizinan(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                props.setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: errorMessage, severity: "error" });
                resolve()
            });
    }

    return (
        <MaterialTable
            style={{ minWidth: '100%' }}
            minRows={10}
            title={props.title}
            columns={columns}
            data={async (query) => {
                const res = await getPerizinan(query.page, query.pageSize, query.search);
                return ({
                    data: res.records,
                    page: query.page,
                    totalCount: parseInt(res.TotalRecord)
                })
            }
            }
            options={{
                sorting: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                toolbar: true,
                paging: true,
                search: false,
            }}

            actions={[
                {
                    icon: () => <Edit />,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => {
                        history.push({
                            pathname: `/dashboard/perizinan/update/${rowData.id}`,
                            user: rowData
                        })
                    }
                },
                {
                    icon: () => (
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            style={{ height: 40 }}
                        >
                            <AddIcon />
                            <Hidden smDown>
                                <p style={{ marginLeft: 10 }}>Tambah Data Baru</p>
                            </Hidden>
                        </Button>),
                    tooltip: 'Tambah Data',
                    isFreeAction: true,
                    onClick: (event, rowData) => history.push({
                        pathname: `/dashboard/perizinan/add`,
                        user: rowData
                    })
                }
            ]}

            icons={tableIcons}
            editable={{
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        handleRowDelete(oldData, resolve)
                    }),
            }}

        />);
}
export default withRouter(PerizinanTable);
