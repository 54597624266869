import { useEffect } from "react";
import { Redirect } from "react-router";
import { getRoleFromJWTToken } from "../../repo/notificationRepo";

export default function NotFound() {
  useEffect(()=> {
    console.log("not found");
  }, []);

  let role = getRoleFromJWTToken();

  if (role == 'admin') {
    return <Redirect to='/dashboard/main' />
  } else if (role == 'sekretariat') {
    return <Redirect to='/dashboard/s/pengambilan' />
  } else if (role == 'keuangan') {
    return <Redirect to='/dashboard/f/main' />
  } else if (role == 'pos1') {
    return <Redirect to='/dashboard/p1/main' />
  } else if (role == 'pos2') {
    return <Redirect to='/dashboard/p2/main' />
  }

  return (
    <Redirect to='/dashboard/' />
  )
}