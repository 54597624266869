import { Divider, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { Grid, TextField, Modal, Button, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getOnePengambilan } from "../../repo/pengambilanRepo";
import { getOnePengambilan_Log } from "../../repo/pengambilan_logRepo";
import PengambilanStateChip from "./PengambilanStateChip";
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { dateTimeToStringID, dateToStringID, dateTimeToString } from "../../repo/utils";
import { apiUrl } from "../../api/axios";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PengambilanViewModal = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pengambilan, setPengambilan] = useState(undefined);
  const [pengambilanLog, setPengambilanLog] = useState(undefined);
  const [sumberEksternal, setSumberEksternal] = useState(undefined);
  const token = localStorage.getItem('token');

  useEffect(() => {
    setIsLoading(true);
    if (props.pengambilan && props.pengambilan.id) {
      getOnePengambilan(props.pengambilan.id).then(result => {
        if (result && result.data && result.data.document && result.data.document.records && result.data.document.records.length > 0) {
          let pengambilanRaw = result.data.document.records[0];
          if (pengambilanRaw.tanggal_pengambilan) {
            let temp_date = new Date(pengambilanRaw.tanggal_pengambilan);
            pengambilanRaw.tanggal_pengambilan_str = dateToStringID(temp_date);
          }
          let material = pengambilanRaw.material[0];
          // for (var material in pengambilanRaw.material) {
          var formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
          var temp = formatter.formatToParts(material.nilai).map(
            p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
          ).join('');
          console.log(temp);
          
          var formatterTonase = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
          });
          var tempTonase = formatter.formatToParts(material.tonase).map(
            p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
          ).join('');

          material.nilai = temp;
          material.tonase = tempTonase;
          // }
          setPengambilan(pengambilanRaw);
        }
      }).catch(error => {
        console.log(error);
        setIsLoading(false);
        alert("terjadi kesalahan sistem :(");
      });

      getOnePengambilan_Log(props.pengambilan.id).then(result => {
        if (result && result.data && result.data.document) {
          let dataLog = result.data.document.reverse();
          setPengambilanLog(dataLog);
          dataLog.map((log) => {
            let keterangan = "";
            let keteranganJSON = undefined;
            try {
              keteranganJSON = JSON.parse(log.keterangan);
            } catch (e) {
              // do nothing
            }
            if (keteranganJSON) {
              if (keteranganJSON.sumber_material != "" && keteranganJSON.sumber_material != null) {
                setSumberEksternal(keteranganJSON.sumber_material);
              }
            }
          })
        }
      }).catch(error => {
        console.log(error);
        alert("terjadi kesalahan sistem :(");
      });

    }

  }, [props.pengambilan]);

  useEffect(() => {
    setIsLoading(false);
  }, [pengambilan]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Detail Pengambilan
        </DialogTitle>
        <DialogContent dividers>
          {!isLoading && pengambilan ?
            <div>
              <table style={{ textAlign: 'left' }}>
                <tr><th style={{ width: '40%' }}>Status</th><td><PengambilanStateChip status={pengambilan.status_akhir} /></td></tr>
                <tr><th>Berita acara</th><td> {pengambilan.plat_nomor ? <a href={`${apiUrl}/document/surat-jalan/${pengambilan.id}?token=${token}`}>unduh</a> : null}</td></tr>
                <tr><th>Hari, tanggal</th><td>{pengambilan.tanggal_pengambilan_str}</td></tr>
                <tr><th>Pengelola</th><td>{pengambilan.nama_pengelola}</td></tr>
                <tr><th>Pengangkut</th><td>{pengambilan.nama_pengangkut}</td></tr>
                <tr><th>Plat No. Truk</th><td>{pengambilan.plat_nomor ? pengambilan.plat_nomor : '-'}</td></tr>
                <tr><th>No. Kabel Ties</th><td>{pengambilan.material.map(m => <span>{m.kabel_ties} </span>)}</td></tr>
              </table>
              <br />
              <Divider light />
              <b>Limbah yang diambil</b>
              <table style={{ textAlign: 'left', width: '100%' }}>
                <tr><th>Kode</th><th>Material</th><th>Sumber</th><th>Tonase</th><th>Nilai (Rp)</th><th>Keterangan</th></tr>
                {pengambilan.material.map(m =>
                  (<tr><td>{m.kode_material}</td> <td>{m.nama_material}</td> <td>{sumberEksternal ?? m.sumber_material}</td> <td>{m.tonase}</td> <td>{m.nilai}</td>  <td>{m.jenis}</td></tr>)
                )}
              </table>
              
              <br />
              <Divider light />
              <b>Log Perubahan Status</b>
              {pengambilanLog ?
                <table style={{ textAlign: 'left', width: '100%' }}>
                  <tr><th style={{ width: '35%' }}>Waktu (WIB)</th><th>Status</th><th>keterangan</th></tr>
                  {pengambilanLog.map((log) => {
                    let keterangan = "";
                    let keteranganJSON = undefined;
                    try {
                      keteranganJSON = JSON.parse(log.keterangan);
                    } catch (e) {
                      // do nothing
                    }
                    if (keteranganJSON) {
                      if (keteranganJSON.keterangan)
                        keterangan += `${keteranganJSON.keterangan}. `;
                      if (keteranganJSON.user_name) {
                        keterangan += `${keteranganJSON.user_name} `;
                        if (keteranganJSON.role_name) keterangan += `(${keteranganJSON.role_name})`;
                        keterangan += `. `;
                      }
                    }

                    let time = new Date(log.inserted_at);
                    let time_str = dateTimeToString(time);
                    // if (keteranganJSON && keteranganJSON.date_time) {
                    //     time_str = keteranganJSON.date_time;
                    // }

                    // change 'verifikasi_lingkungan' to 'input_data', for internal
                    if (pengambilan.is_internal && log.status == 'verifikasi_lingkungan') {
                      log.status = 'input_data';
                    }
                    // kalau is_done di-skip aja
                    if (pengambilan.is_internal && keteranganJSON && keteranganJSON.is_done) {
                      return <span></span>;
                    }

                    return (<tr> <td>{time_str}</td> <td> <PengambilanStateChip status={log.status} /></td>  <td>{keterangan == "" ? "-" : keterangan}</td> </tr>)
                  })}
                </table>
                :
                <CircularProgress />
              }
              <br />
              <Divider light />
              <b>Dokumentasi Pengambilan</b>
              {pengambilan.material.map(m => (
                <span>
                  <Grid container style={{ marginTop: 10 }} spacing={2} alignItems="center">
                    <Grid item md={4}>
                      <b><small>Foto timbangan: </small></b>
                      {m.foto_timbangan != null ?
                        <img style={{ width: '100%' }} src={`${m.foto_timbangan}?token=${token}`} />
                        : '-'
                      }
                    </Grid>
                    <Grid item md={4}>
                      <b><small>Foto kabel ties: </small></b>
                      {m.foto_kabel_ties != null ?
                        <img style={{ width: '100%' }} src={`${m.foto_kabel_ties}?token=${token}`} />
                        : '-'
                      }
                    </Grid>
                    <Grid item md={4}>
                      <b><small>Foto setelah pengiriman: </small></b>
                      {m.foto_kabel_ties_pengelola != null ?
                        <img style={{ width: '100%' }} src={`${m.foto_kabel_ties_pengelola}?token=${token}`} />
                        : '-'
                      }
                    </Grid>
                  </Grid>

                </span>
              ))}

              {pengambilan.is_internal ?
                <div>
                  <br />
                  <Divider light />
                  <b>Pengolahan Limbah Internal</b>
                  {pengambilan.material.map(m => (
                    <div>
                      <table style={{ textAlign: 'left', width: '100%' }}>
                        <tr><th>Jenis Pengolahan</th> <td>{m.intern_jenis_pengolahan ? m.intern_jenis_pengolahan : '-'}</td></tr>
                        <tr><th>Lokasi Palet</th> <td>{m.intern_lokasi_palet ? m.intern_lokasi_palet : '-'}</td></tr>
                        <tr><th>Target Produk</th> <td>{m.intern_produk ? m.intern_produk : '-'}</td></tr>
                        <tr><th>Status Pengolahan</th> <td>{m.intern_status_pengolahan ? m.intern_status_pengolahan : '-'}</td></tr>
                      </table>
                      <span>
                        <p>Foto pengolahan internal:</p>
                        {m.intern_foto_pengolahan_b3 ?
                          <img src={`${m.intern_foto_pengolahan_b3}?token=${token}`} style={{ width: '25%' }}></img>
                          : '-'
                        }
                      </span>
                    </div>
                  ))}
                </div>
                :
                null
              }
            </div>
            :
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withRouter(PengambilanViewModal);