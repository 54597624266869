import MaterialTable from "material-table";
import { createRef, useEffect } from "react";
import { getPengambilanTagihan } from "../../../repo/pengambilanTagihanRepo";
import tableIcons from '../../templates/TableIcons';

const PengambilanTable = (props) => {
  const tableRef = createRef();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [props]);

  return (
    <span>
      <MaterialTable
        tableRef={tableRef}
        minRows={10}
        title="Pengambilan Belum Tertagih"
        options={{
          sorting: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          toolbar: true,
          paging: true,
          search: false,
        }}
        icons={tableIcons}
        columns={
          [
            { title: "Id", field: "id", hidden: true },
            { title: "No. Pengambilan", field: "no_pengambilan" },
            {
              title: "Tanggal Pengambilan", field: "tanggal_pengambilan", render: rowData => {
                let date = new Date(rowData.tanggal_pengambilan);
                return <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
              }
            },
            { title: "Perusahaan", field: "pengelola" },
            { title: "Pengangkut", field: "pengangkut" },
            { title: "Kendaraan", field: "plat_nomor" },
            { title: "Tonase", field: "tonase" },
            { title: "Material", field: "material" },
            { title: "B3?", field: "is_b3", type: "boolean" },
          ]
        }
        data={async (query) => {
          const res = await getPengambilanTagihan(props.companyID, props.transporterID, props.dateStart, props.dateEnd, query.page, query.pageSize);

          if (res.TotalRecord > 0) {
            props.setIsDataAvailable(true);
          }

          return ({
            data: res.records,
            page: query.page,
            totalCount: parseInt(res.TotalRecord)
          })
        }}
      >

      </MaterialTable>
    </span>
  )
}

export default PengambilanTable;