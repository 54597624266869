import PageTemplate from "../templates/Template";
import Intern_Fly_AshTable from "./Intern_Fly_AshTable";
import React from "react";

const Intern_Fly_AshPage = (props) => {
    return(
    <PageTemplate title="Intern_Fly_Ash List">
        <Intern_Fly_AshTable/>
    </PageTemplate>)
}
export default Intern_Fly_AshPage;
