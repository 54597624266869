import MaterialTable from "./MaterialTable";
import React, { useState, useEffect } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialDataSubpage = (props) => {
    const classes = useStyles();
    const history = props.history;
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [isb3, setIsb3] = useState('all');

    useEffect(() => {
        if (props.history && props.history.location && props.history.location.state && props.history.location.state.alert) {
            setAlertstate(props.history.location.state.alert);
            props.history.location.state.alert = undefined;
        }
    }, []);

    const handleMaterialTypeChange = (event) => {
        setIsb3(event.target.value);
    };

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <div>
            <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15, maxHeight: 50 }} spacing={2}>
                <Grid item md={3} xs={9} justify='flex-start'>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Jenis Limbah</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleMaterialTypeChange}
                            value={isb3}
                            label="Jenis Limbah"
                            style={{ textAlign: 'left', backgroundColor: 'white', maxHeight: 45 }}
                        >
                            <MenuItem value={'all'} selected>Semua</MenuItem>
                            <MenuItem value={'1'}>Limbah B3</MenuItem>
                            <MenuItem value={'0'}>Limbah Non B3</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item md={9} xs={3} justify='flex-end' alignItems='center' justifyContent='flex-end'>
                    <Grid item md={4} xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ height: 45, width: '100%' }}
                            onClick={() => { history.push("/dashboard/material/add") }}
                        >
                            <AddIcon />
                            <Hidden mdDown>
                                <p style={{ marginLeft: 10 }}>Tambah Data Baru</p>
                            </Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <MaterialTable isb3={isb3} />
            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>);
}
export default MaterialDataSubpage;
