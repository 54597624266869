import MaterialTable from 'material-table';
import React, { createRef, useEffect } from 'react';
import tableIcons from '../templates/TableIcons';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import { deleteSumberMaterial, getSumberMaterial } from '../../repo/sumberMaterialRepo';

const SumberMaterialTableView = (props) => {
    const tableRef = createRef();

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deleteSumberMaterial(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                props.setAlertState({ open: true, vertical: 'bottom', horizontal: 'center', message: errorMessage, severity: "error" });
                resolve()
            });
    }

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [props.refresh])

    let columns = [
        { title: "ID", field: "id", hidden: true },
        { title: "Sumber Limbah", field: "nama" },
        { title: "InsertedAt", field: "inserted_at", type: "datetime", hidden: true },
        { title: "UpdatedAt", field: "updated_at", type: "datetime", hidden: true },
    ];

    return (
        <div>
            <MaterialTable
                tableRef={tableRef}
                minRows={10}
                title="Data Sumber Limbah"
                columns={columns}
                data={async (query) => {
                    const res = await getSumberMaterial(query.page, query.pageSize);
                    return ({
                        data: res.records,
                        page: query.page,
                        totalCount: parseInt(res.TotalRecord)
                    })
                }
                }
                options={{
                    sorting: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    toolbar: true,
                    paging: true,
                    search: false,
                }}

                actions={[
                    {
                        icon: () => <Edit />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                            props.openEditDataForm(rowData.id, rowData.nama);
                        }
                    }
                ]}

                icons={tableIcons}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}
            />
        </div>);
}
export default withRouter(SumberMaterialTableView);
