import { Switch } from '@material-ui/core';
import React from 'react';
import Avatar from 'react-avatar';
import Input from "@material-ui/core/Input";

/*
In order to validate errors on the input field you can
override the editComponent of the Material Table to add a new material-ui Input fields
and use props for validation.
Information on material-ui Input element https://material-ui.com/api/input/
Information on material-table Props https://material-table.com/#/docs/all-props
You can also find an example of an overridden element bellow. Overriding the render method is not a must.
 */

const toUpper = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(' ');
}

function getURLExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

const GetPerizinan_PemegangColumns = (totalCount) => [
  { title: "Id", field: "id", hidden: true },
  { title: "Entitas", field: "peruntukan", render: rowData => toUpper(rowData.peruntukan) }, // Kendaraan, Pengelola, Pengangkut
  { title: "Jenis Izin", field: "jenis_izin_Value" },
  {
    title: "Pemilik", render: rowData => {
      if (rowData.peruntukan == 'kendaraan') return rowData.plat_nomor;
      if (rowData.peruntukan == 'pengelola') return rowData.pengelola;
      return rowData.pengangkut;
    }
  },
  { title: "No Dokumen", field: "no_dokumen" },
  {
    title: "Tanggal Selesai", field: "tanggal_selesai", type: "date", render: rowData => {
      let date = new Date(rowData.tanggal_selesai);
      return <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
    }
  },
  {
    title: "Dokumen", field: "url", render: rowData => {
      const token = localStorage.getItem('token');
      let url = '';
      let fileURL = '';
      let maxWidth = 30;
      if (rowData.url) {
        const extension = getURLExtension(rowData.url);
        fileURL = `${rowData.url}?token=${token}`;
        if (['pdf', 'doc', 'docx', 'xls', 'xlsx'].includes(extension)) {
          if (extension === 'pdf') url = process.env.PUBLIC_URL + '/icon-pdf.png';
          if (extension === 'doc' || extension === 'docx') url = process.env.PUBLIC_URL + '/icon-doc.png';
          if (extension === 'xls' || extension === 'xlsx') url = process.env.PUBLIC_URL + '/icon-xls.png';
          fileURL += '&dl';
        }
        if (['png', 'jpg', 'jpeg'].includes(extension)) {
          url = rowData.url + `?token=${token}`;
          maxWidth = 60;
        }
      }
      return (
        <img
          src={url ? url : "https://static.thenounproject.com/png/583402-200.png"}
          style={{ maxWidth: maxWidth}}
          onClick={() => {
            if (url) window.open(fileURL, "_blank")
          }}
        />)
    }
  },
  { title: "InsertedAt", field: "inserted_at", type: "datetime", hidden: true },
  { title: "UpdatedAt", field: "updated_at", type: "datetime", hidden: true },
]
export default GetPerizinan_PemegangColumns;
