import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import PageTemplate from "../templates/Template";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import history from '../../history';
import { addMaterial, getOneMaterial, updateMaterial } from "../../repo/materialRepo";
import { CircularProgress } from "@material-ui/core";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialAddUpdatePage = (props) => {
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [errorMessages, setErrorMessages] = useState({});
    const [title, setTitle] = useState("Add/Update Data Material");
    const [material, setMaterial] = useState(undefined);

    const checkErrors = () => {
        let errorList = {}
        if (material.nama === "" || material.nama === undefined) {
            errorList = { ...errorList, nama: "nama material wajib diisi" }
        }
        if (material.is_b3 === "" || material.is_b3 === undefined) {
            errorList = { ...errorList, is_b3: "jenis material (b3) wajib diisi" }
        }
        if (material.kode_material === "" || material.kode_material === undefined) {
            errorList = { ...errorList, kode_material: "kode material wajib diisi" }
        }

        setErrorMessages(errorList)
        return errorList
    }

    useEffect(() => {
        if (props.match.params.id) {
            setTitle("Edit Data Material");
            getOneMaterial(props.match.params.id).then((res) => {
                setMaterial(res.data.document);
            }).catch(error => {
                console.log(error);
                alert("terjadi kesalahan sistem :(");
            });
        } else {
            setMaterial({ is_b3: 0 });
            setTitle("Tambah Material Baru");
        }
    }, [props.match.params.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const errors = checkErrors()
            //if no errors then send data
            if (Object.keys(errors).length < 1) {
                if (material.id != null && material.id != undefined) {
                    var updateResponse = await updateMaterial(material.id, material);
                    if (updateResponse && updateResponse.data) {
                        if (updateResponse.data.code === 1) {
                            props.history.push({
                                pathname: "/dashboard/material",
                                state: { alert: { open: true, vertical: 'bottom', horizontal: 'center', message: "Record Updated Successfully.", severity: "success" } },
                            });
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Updated failed. Please try again.", severity: "error" });
                    }
                    // props.history.push("/dashboard/material");
                } else {
                    var addResponse = await addMaterial(material)
                    if (addResponse && addResponse.data) {
                        if (addResponse.data.code === 1) {
                            props.history.push({
                                pathname: "/dashboard/material",
                                state: { alert: { open: true, vertical: 'bottom', horizontal: 'center', message: "Record Added Successfully.", severity: "success" } },
                            });
                            // setMaterial({});
                        } else {
                            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });
                        }
                    } else {
                        setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Add Record Failed. Please try again.", severity: "error" });

                    }
                    // props.history.push("/dashboard/material");
                }
            } else {
                setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Data belum lengkap, harap dilengkapi.", severity: "error" });

            }
        } catch (e) {
            setAlertstate({ open: true, vertical: 'bottom', horizontal: 'center', message: "Terjadi kesalahan, harap coba lagi.", severity: "error" });
        }
    }

    const hideAlert = () => {
        setAlertstate({ ...alertState, open: false });
    };

    return (
        <PageTemplate title={title} menu="material" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
            <Grid container justifyContent="center" justify="center" direction="column" alignItems="center">
                <Grid item md={6} xs={12}>
                    <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardHeader title={title} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {(material !== undefined) ?
                                    <Grid spacing={3} container style={{ paddingLeft: 30, paddingRight: 30 }}>
                                        <Grid md={12} key={"0"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Nama Material *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField required autoFocus fullWidth
                                                    helperText={errorMessages.nama}
                                                    type={"text"}
                                                    onChange={(e) => { setMaterial({ ...material, nama: e.target.value }); checkErrors() }}
                                                    value={material.nama}
                                                    error={(errorMessages.nama) ? true : false}
                                                    placeholder="Cth: Resin Bekas" />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} key={"2"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Kode Material *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField required fullWidth
                                                    helperText={errorMessages.kode_material}
                                                    type={"text"}
                                                    onChange={(e) => { setMaterial({ ...material, kode_material: e.target.value }); checkErrors() }}
                                                    defaultValue={material.kode_material}
                                                    error={(errorMessages.kode_material) ? true : false}
                                                    placeholder="Cth: M-123" />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} key={"3"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left">
                                                    Masa Simpan (Hari) *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4}>
                                                <TextField required fullWidth
                                                    helperText={errorMessages.masa_simpan}
                                                    type="number"
                                                    onChange={(e) => { setMaterial({ ...material, masa_simpan: e.target.value }); checkErrors() }}
                                                    defaultValue={material.masa_simpan}
                                                    error={(errorMessages.masa_simpan) ? true : false}
                                                    placeholder="30" />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} key={"1"} item container direction="row">
                                            <Grid item md={4}>
                                                <Typography variant="body1" align="left" style={{ marginTop: 8 }}>
                                                    Limbah B3? *
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} align="left">
                                                <Checkbox
                                                    checked={material.is_b3 == 1}
                                                    color="primary"
                                                    onChange={(e) => { setMaterial({ ...material, is_b3: e.target.checked ? 1 : 0 }); console.log(e.target.checked); checkErrors() }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} item key={"7"}>
                                            <Grid container justify={"flex-end"} alignContent={"flex-end"}>
                                                <Button onClick={() => history.push('/dashboard/material')} variant={"contained"} type={"Button"}>Kembali</Button>
                                                <Button style={{ marginLeft: 10 }} variant={"contained"} color="primary" type={"Submit"}>Simpan</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    (<CircularProgress color="primary" style={{ marginTop: 50, marginBottom: 50 }} />)}
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Snackbar autoHideDuration={6000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={hideAlert}
                key={vertical + horizontal}>
                <Alert onClose={hideAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

        </PageTemplate>
    )
}

export default withRouter(MaterialAddUpdatePage)
