import api from "../api/axios";
import { dateToISOString } from "./utils";

//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getIntern_Fly_Ash = async (filterStartDate, filterEndDate, pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllIntern_Fly_Ash(filterStartDate, filterEndDate, pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchIntern_Fly_Ash(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addIntern_Fly_Ash = (data) => {
    return api.post(`/intern_fly_ash`, data)
}
const updateIntern_Fly_Ash = (id, data) => {
    return api.put(`/intern_fly_ash/${id}`, data)
}
const getAllIntern_Fly_Ash = (filterStartDate, filterEndDate, pageNo, pageSize) => {
    return api.get(`/intern_fly_ash/?pageNo=${pageNo}&pageSize=${pageSize}${filterStartDate ? '&start_date='+dateToISOString(filterStartDate) : ''}${filterEndDate ? '&end_date='+dateToISOString(filterEndDate) : ''}`);
}

const getOneIntern_Fly_Ash = (id) => {
    return api.get(`/intern_fly_ash/${id}`)
}
const searchIntern_Fly_Ash = (pageNo, pageSize, searchKey) => {
    return api.get(`/intern_fly_ash/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deleteIntern_Fly_Ash = (id) => {
    return api.delete(`/intern_fly_ash/${id}`)
}
export { getIntern_Fly_Ash, addIntern_Fly_Ash, updateIntern_Fly_Ash, getAllIntern_Fly_Ash, getOneIntern_Fly_Ash, searchIntern_Fly_Ash, deleteIntern_Fly_Ash }


