import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPengelola = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPengelola(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPengelola(pageNo + 1, pageSize, search);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}


const addPengelola = (data) => {
    return api.post(`/pengelola`, data)
}
const updatePengelola = (id, data) => {
    return api.put(`/pengelola/${id}`, data)
}
const getAllPengelola = (pageNo, pageSize) => {
    return api.get(`/pengelola/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOnePengelola = (id) => {
    return api.get(`/pengelola/${id}`)
}
const searchPengelola = (pageNo, pageSize, searchKey) => {
    return api.get(`/pengelola/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deletePengelola = (id) => {
    return api.delete(`/pengelola/${id}`)
}
const getPerizinanByPengelolaID = (id) => {
    return api.get(`/pengelola/${id}/perizinan`);
}
export { getPerizinanByPengelolaID, getPengelola, addPengelola, updatePengelola, getAllPengelola, getOnePengelola, searchPengelola, deletePengelola }


