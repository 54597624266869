import MaterialTable from 'material-table';
import React, { createRef, useEffect, useState } from 'react';
import tableIcons from '../../templates/TableIcons';
import getColumns from './Perizinan_PemegangColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import AddIcon from '@material-ui/icons/Add';
import { Button, Hidden, Grid, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { deletePerizinan_Pemegang, getAllPerizinanByAttribute, getPerizinan_Pemegang } from "../../../repo/perizinan_pemegangRepo";
import { getAllPengelola } from '../../../repo/pengelolaRepo';
import { getAllPengangkut } from '../../../repo/pengangkutRepo';
import { getAllKendaraan } from '../../../repo/kendaraanRepo';
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const Perizinan_PemegangTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const tableRef = createRef();

    const token = localStorage.getItem('token');

    // filter selected value
    const [filterEntity, setFilterEntity] = useState('all');
    const [filterCompany, setFilterCompany] = useState('all');
    const [filterVehicleNo, setFilterVehicleNo] = useState('all');

    // filter options
    const [listPengelola, setListPengelola] = useState([]);
    const [listPengangkut, setListPengangkut] = useState([]);
    const [listKendaraan, setListKendaraan] = useState([]);
    const [listKendaraanByPengangkut, setListKendaraanByPengangkut] = useState({});
    const [listKendaraanStrByPengangkutID, setListKendaraanStrByPengangkutID] = useState({});
    const [companyOptions, setCompanyOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);

    useEffect(() => {
        // get all pengelola
        getAllPengelola(1, 1000).then((res) => {
            if (res && res.data && res.data.document && res.data.document.records && res.data.document.records.length > 0) {
                setListPengelola(res.data.document.records);
            }
        }).catch(err => {
            alert('Terjadi Kesalahan', err);
        });
        // get all pengangkut
        getAllPengangkut(1, 1000).then((res) => {
            if (res && res.data && res.data.document && res.data.document.records && res.data.document.records.length > 0) {
                setListPengangkut(res.data.document.records);
            }
        }).catch(err => {
            alert('Terjadi Kesalahan', err);
        });
        // get all kendaraan
        getAllKendaraan(1, 1000).then((res) => {
            if (res && res.data && res.data.document && res.data.document.records && res.data.document.records.length > 0) {
                setListKendaraan(res.data.document.records);
                let kendaraanByPengangkutID = {};
                let kendaraanStrByPengangkutID = { 'all': '' };
                res.data.document.records.forEach(k => {
                    if (!kendaraanByPengangkutID[k.id_pengangkut]) kendaraanByPengangkutID[k.id_pengangkut] = [];
                    kendaraanByPengangkutID[k.id_pengangkut].push(k);

                    if (!kendaraanStrByPengangkutID[k.id_pengangkut]) kendaraanStrByPengangkutID[k.id_pengangkut] = '';
                    kendaraanStrByPengangkutID[k.id_pengangkut] += "," + k.id;
                    kendaraanStrByPengangkutID['all'] += "," + k.id;
                });
                setListKendaraanByPengangkut(kendaraanByPengangkutID);
                setListKendaraanStrByPengangkutID(kendaraanStrByPengangkutID);
            }
        }).catch(err => {
            alert('Terjadi Kesalahan', err);
        });
    }, []);

    // handle if entity is changed
    useEffect(() => {
        if (filterEntity === 'all') {
            setCompanyOptions([]);
            setVehicleOptions([]);
        }
        if (filterEntity === 'pengelola') {
            setCompanyOptions(listPengelola);
            setVehicleOptions([]);
            setFilterCompany('all');
        }
        if (filterEntity === 'pengangkut') {
            setCompanyOptions(listPengangkut);
            setVehicleOptions([]);
            setFilterCompany('all');
        }
        if (filterEntity === 'kendaraan') {
            setCompanyOptions(listPengangkut);
            setVehicleOptions(listKendaraan);
            setFilterCompany('all');
            setFilterVehicleNo('all');
        }
    }, [filterEntity]);

    // for entity == kendaraan, handle if pengangkut is changed
    useEffect(() => {
        if (filterEntity === 'kendaraan') {
            if (filterCompany === 'all') {
                setVehicleOptions(listKendaraan);
            } else {
                setVehicleOptions(listKendaraanByPengangkut[filterCompany] ? listKendaraanByPengangkut[filterCompany] : []);
            }
            setFilterVehicleNo('all');
        }
    }, [filterCompany]);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [filterEntity, filterCompany, filterVehicleNo]);

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deletePerizinan_Pemegang(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                resolve()
            });
    }

    return (
        <div style={{ width: '100%' }}>
            <Grid container direction='row' spacing={1} style={{ margin: 4 }}>
                <Grid item xs={12} md={3} justify='flex-start'>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Entitas</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Entitas"
                            value={filterEntity}
                            onChange={(e) => setFilterEntity(e.target.value)}
                            style={{ textAlign: 'left', backgroundColor: 'white' }}
                        >
                            <MenuItem value={'all'}>Semua</MenuItem>
                            <MenuItem value={'pengelola'}>Pengelola</MenuItem>
                            <MenuItem value={'pengangkut'}>Pengangkut</MenuItem>
                            <MenuItem value={'kendaraan'}>Kendaraan</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterEntity != 'all' &&
                    <Grid item xs={12} md={3} justify='flex-start'>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">Perusahaan</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Perusahaan"
                                value={filterCompany}
                                onChange={(e) => setFilterCompany(e.target.value)}
                                style={{ textAlign: 'left', backgroundColor: 'white' }}
                            >
                                <MenuItem value={'all'}>Semua</MenuItem>
                                {companyOptions.map((key, i) => <MenuItem key={i} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {filterEntity === 'kendaraan' &&
                    <Grid item xs={12} md={3} justify='flex-start'>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">Plat Nomor</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Plat Nomor"
                                value={filterVehicleNo}
                                onChange={(e) => setFilterVehicleNo(e.target.value)}
                                style={{ textAlign: 'left', backgroundColor: 'white' }}
                            >
                                <MenuItem value={'all'}>Semua</MenuItem>
                                {vehicleOptions.map((key, i) => <MenuItem key={i} value={key.id}>{key.plat_nomor}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>}
            </Grid >
            <Grid container>
                <Grid item md={12}>
                    <MaterialTable
                        tableRef={tableRef}
                        style={{ minWidth: '100%' }}
                        minRows={10}
                        title={props.title}
                        columns={columns}
                        data={async (query) => {
                            const res = await getAllPerizinanByAttribute(
                                filterEntity === 'all' ? null : filterEntity,
                                filterEntity === 'kendaraan' ?
                                    (filterVehicleNo === 'all' && filterCompany == 'all' ?
                                        null
                                        : (filterCompany == 'all' && filterVehicleNo != 'all' ?
                                            filterVehicleNo
                                            : filterCompany != 'all' && filterVehicleNo == 'all' ? 
                                            listKendaraanStrByPengangkutID[filterCompany]
                                            : filterVehicleNo
                                        ))
                                    : filterCompany === 'all' ?
                                        null
                                        : filterCompany,
                                query.page + 1,
                                query.pageSize
                            );
                            return ({
                                data: res && res.data && res.data.document && res.data.document.records ? res.data.document.records : [],
                                page: query.page,
                                totalCount: parseInt(res.data.document.TotalRecord)
                            })
                        }
                        }
                        options={{
                            sorting: false,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            toolbar: true,
                            paging: true,
                            search: false,
                        }}

                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    history.push({
                                        pathname: `/dashboard/perizinan_pemegang/update/${rowData.id}`,
                                        user: rowData
                                    })
                                }
                            },
                            {
                                icon: () => (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        style={{ height: 40 }}
                                    >
                                        <AddIcon />
                                        <Hidden smDown>
                                            <p style={{ marginLeft: 10 }}>Tambah Data Baru</p>
                                        </Hidden>
                                    </Button>),
                                tooltip: 'Tambah Data',
                                isFreeAction: true,
                                onClick: (event, rowData) => history.push({
                                    pathname: `/dashboard/perizinan_pemegang/add`,
                                    user: rowData
                                })
                            }
                        ]}

                        icons={tableIcons}
                        editable={{
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    handleRowDelete(oldData, resolve)
                                }),
                        }}

                    />
                </Grid>
            </Grid>
        </div>
    );
}
export default withRouter(Perizinan_PemegangTable);
