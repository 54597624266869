import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { withRouter } from 'react-router';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const dropzoneStyle = {
  width: "100%",
  height: "20%",
  border: "1px solid black"
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function FileSelector(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: getUploadedDocumentThumbnailURL(file)
      })));
      props.handleSelectedFile(acceptedFiles[0]);
    }
  });

  const getUploadedDocumentThumbnailURL = function (file) {
    if (file.type.substring(0, 6) === "image/") {
      return URL.createObjectURL(file);
    }
    if (file.type === "application/pdf") {
      return process.env.PUBLIC_URL + '/icon-pdf.png'
    }
    if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/msword") {
      return process.env.PUBLIC_URL + '/icon-doc.png'
    }
    if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel") {
      return process.env.PUBLIC_URL + '/icon-xls.png'
    }
    return process.env.PUBLIC_URL + '/icon-other.png'
  }

  const style = useMemo(() => ({
    ...baseStyle
  }), [
    getInputProps
  ]);

  function getURLExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  useEffect(() => {
    if (props.thumbnailURL) {
      let url = '';
      const extension = getURLExtension(props.thumbnailURL);
      if (extension === 'pdf') url = process.env.PUBLIC_URL + '/icon-pdf.png'
      if (extension === 'doc' || extension === 'docx') url = process.env.PUBLIC_URL + '/icon-doc.png'
      if (extension === 'xls' || extension === 'xlsx') url = process.env.PUBLIC_URL + '/icon-xls.png'
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        const token = localStorage.getItem('token');
        url = props.thumbnailURL + `?token=${token}`;
      }
      setFiles([{ preview: url }]);
    }
  }, []);

  const thumbs = files.map((file, i) => (
    <div key={i}>
      <div style={thumb}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
          />
        </div>
      </div>
      {file.name && <small>{file.name}</small>}
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag drop dokumen di sini, atau klik untuk memilih.</p>
        <small>Menerima format dokumen .png, .jpeg, .pdf, .docx, dan .xlsx</small>
        <small></small>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  );
}

export default withRouter(FileSelector);