import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from '../templates/TableIcons';
import AddIcon from '@material-ui/icons/Add';
import { Button, Box } from '@material-ui/core';
import { deleteIntern_Leak_Detector, getIntern_Leak_Detector } from '../../repo/intern_leak_detectorRepo';
import { dateTimeToStringTime, dateToStringID } from '../../repo/utils';
import { getCachedToken } from '../../auth/auth';

export default function MonitoringLeakDetectorTable(props) {
  const tableRef = React.createRef();
  const leakDetectorColumns = [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Tanggal", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCDate()}/${temp_date.getUTCMonth() + 1}/${temp_date.getUTCFullYear()}`}</p>
      }
    },
    {
      title: "Jam", field: "waktu", type: 'date', render: rowData => {
        let temp_date = new Date(rowData.waktu);
        return <p>{`${temp_date.getUTCHours()}:${temp_date.getUTCMinutes() + 1}`}</p>
      }
    },
    { title: "Status 1", field: "status_1" },
    {
      title: "Foto 1", field: "url_foto_1", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_1 ? rowData.url_foto_1 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 2", field: "status_2" },
    {
      title: "Foto 2", field: "url_foto_2", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_2 ? rowData.url_foto_2 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 3", field: "status_3" },
    {
      title: "Foto 3", field: "url_foto_3", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_3 ? rowData.url_foto_3 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 4", field: "status_4" },
    {
      title: "Foto 4", field: "url_foto_4", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_4 ? rowData.url_foto_4 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 5", field: "status_5" },
    {
      title: "Foto 5", field: "url_foto_5", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_5 ? rowData.url_foto_5 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 6", field: "status_6" },
    {
      title: "Foto 6", field: "url_foto_6", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_6 ? rowData.url_foto_6 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    { title: "Status 7", field: "status_7" },
    {
      title: "Foto 7", field: "url_foto_7", render: rowData => {
        let token = getCachedToken();
        let url = rowData.url_foto_7 ? rowData.url_foto_7 + "?token=" + token : "https://static.thenounproject.com/png/583402-200.png";
        return <img src={url} style={{ maxWidth: 60 }} onClick={() => window.open(url, "_blank")} />
      }
    },
    {
      title: "Penginput", field: "updated_by"
    },
  ];
  let columns = leakDetectorColumns;

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [props.tipe, props.filterStartDate, props.filterEndDate]);

  const handleRowDelete = (oldData, resolve) => {
    deleteIntern_Leak_Detector(oldData.id)
      .then(res => {
        resolve()
      })
      .catch(error => {
        let errorMessage = "Terjadi kesalahan sistem, harap coba lagi.";
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        alert(errorMessage);
        resolve()
      })
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      title={props.title}
      icons={tableIcons}
      columns={columns}
      options={{
        sorting: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        toolbar: true,
        paging: true,
        search: false,
      }}
      // actions={[
      //   {
      //     icon: () => (
      //       <Button variant="outlined" disableElevation startIcon={<AddIcon />}>
      //         Tambah Data Baru
      //       </Button>),
      //     tooltip: 'Tambah Data',
      //     isFreeAction: true,
      //     onClick: (event) => alert("You want to add a new row")
      //   }
      // ]}
      icons={tableIcons}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve)
          }),
      }}
      data={async (query) => {
        const res = await getIntern_Leak_Detector(props.filterStartDate, props.filterEndDate, query.page, query.pageSize, '');
        return ({
          data: res.records ? res.records : [],
          page: query.page,
          totalCount: parseInt(res.TotalRecord)
        })
      }
      }
    />
  );
}
