import MaterialTable from 'material-table';
import React, { createRef, useEffect, useState } from 'react';
import tableIcons from '../../templates/TableIcons';
import getColumns from './KerjasamaColumns';
import Edit from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import AddIcon from '@material-ui/icons/Add';
import { Button, Hidden, Grid, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { deleteKerjasama, getAllKerjasamaByAttribute, getKerjasama } from "../../../repo/kerjasamaRepo";
import { getAllPengelola } from '../../../repo/pengelolaRepo';
import { getAllPengangkut } from '../../../repo/pengangkutRepo';
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const KerjasamaTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const tableRef = createRef();

    // filter selected value
    const [filterEntity, setFilterEntity] = useState('all');
    const [filterCompany, setFilterCompany] = useState('all');

    // filter options
    const [listPengelola, setListPengelola] = useState([]);
    const [listPengangkut, setListPengangkut] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        // get all pengelola
        getAllPengelola(1, 1000).then((res) => {
            if (res && res.data && res.data.document && res.data.document.records && res.data.document.records.length > 0) {
                setListPengelola(res.data.document.records);
            }
        }).catch(err => {
            alert('Terjadi Kesalahan', err);
        });
        // get all pengangkut
        getAllPengangkut(1, 1000).then((res) => {
            if (res && res.data && res.data.document && res.data.document.records && res.data.document.records.length > 0) {
                setListPengangkut(res.data.document.records);
            }
        }).catch(err => {
            alert('Terjadi Kesalahan', err);
        });
    }, []);

    // handle if entity is changed
    useEffect(() => {
        if (filterEntity === 'all') {
            setCompanyOptions([]);
        }
        if (filterEntity === 'pengelola') {
            setCompanyOptions(listPengelola);
            setFilterCompany('all');
        }
        if (filterEntity === 'pengangkut') {
            setCompanyOptions(listPengangkut);
            setFilterCompany('all');
        }
        if (filterEntity === 'paiton') {
            setCompanyOptions([]);
        }
    }, [filterEntity]);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [filterEntity, filterCompany]);

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deleteKerjasama(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                resolve()
            })
    }

    return (
        <div style={{ width: '100%' }}>
            <Grid container direction='row' spacing={1} style={{ margin: 4 }}>
                <Grid item xs={12} md={3} justify='flex-start'>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Jenis Perusahaan</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Jenis Perusahaan"
                            value={filterEntity}
                            onChange={(e) => setFilterEntity(e.target.value)}
                            style={{ textAlign: 'left', backgroundColor: 'white' }}
                        >
                            <MenuItem value={'all'}>Semua</MenuItem>
                            <MenuItem value={'paiton'}>PT. PJB UP Paiton</MenuItem>
                            <MenuItem value={'pengelola'}>Pengelola</MenuItem>
                            <MenuItem value={'pengangkut'}>Pengangkut</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {(filterEntity === 'pengelola' || filterEntity === 'pengangkut') &&
                    <Grid item xs={12} md={3} justify='flex-start'>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">Perusahaan</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Perusahaan Pengelola"
                                value={filterCompany}
                                onChange={(e) => setFilterCompany(e.target.value)}
                                style={{ textAlign: 'left', backgroundColor: 'white' }}
                            >
                                <MenuItem value={'all'}>Semua</MenuItem>
                                {companyOptions.map((key, i) => <MenuItem key={i} value={key.id}>{key.nama_perusahaan}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid >
            <Grid container>
                <Grid item md={12}>
                    <MaterialTable
                        tableRef={tableRef}
                        style={{ minWidth: '100%' }}
                        minRows={10}
                        title={props.title}
                        columns={columns}
                        data={async (query) => {
                            const res = await getAllKerjasamaByAttribute(
                                filterEntity === 'paiton' ? 1 : undefined,
                                filterEntity === 'pengelola' && filterCompany != 'all' ? filterCompany : null,
                                filterEntity === 'pengangkut' && filterCompany != 'all' ? filterCompany : null,
                                query.page+1, 
                                query.pageSize
                                );
                            return ({
                                data: res && res.data && res.data.document && res.data.document.records ? res.data.document.records : [],
                                page: query.page,
                                totalCount: parseInt(res.data.document.TotalRecord)
                            })
                        }
                        }
                        options={{
                            sorting: false,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            toolbar: true,
                            paging: true,
                            search: false,
                        }}

                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    history.push({
                                        pathname: `/dashboard/kerjasama/update/${rowData.id}`,
                                        user: rowData
                                    })
                                }
                            },
                            {
                                icon: () => (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        style={{ height: 40 }}
                                    >
                                        <AddIcon />
                                        <Hidden smDown>
                                            <p style={{ marginLeft: 10 }}>Tambah Data Baru</p>
                                        </Hidden>
                                    </Button>),
                                tooltip: 'Tambah Data',
                                isFreeAction: true,
                                onClick: (event, rowData) => history.push({
                                    pathname: `/dashboard/kerjasama/add`,
                                    user: rowData
                                })
                            }
                        ]}

                        icons={tableIcons}
                        editable={{
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    handleRowDelete(oldData, resolve)
                                }),
                        }}

                    />
                </Grid>
            </Grid>
        </div>
    );
}
export default withRouter(KerjasamaTable);
