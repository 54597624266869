import PageTemplate from "../templates/Template";
import Intern_Leak_DetectorTable from "./Intern_Leak_DetectorTable";
import React from "react";

const Intern_Leak_DetectorPage = (props) => {
    return(
    <PageTemplate title="Intern_Leak_Detector List">
        <Intern_Leak_DetectorTable/>
    </PageTemplate>)
}
export default Intern_Leak_DetectorPage;
