import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getPerizinan_Pemegang = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllPerizinan_Pemegang(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchPerizinan_Pemegang(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addPerizinan_Pemegang = (data) => {
    return api.post(`/perizinan_pemegang`, data)
}

const updatePerizinan_Pemegang = (id, data) => {
    return api.put(`/perizinan_pemegang/${id}`, data)
}

const getAllPerizinan_Pemegang = (pageNo, pageSize) => {
    return api.get(`/perizinan_pemegang/?pageNo=${pageNo}&pageSize=${pageSize}`);
}

const getOnePerizinan_Pemegang = (id) => {
    return api.get(`/perizinan_pemegang/${id}`)
}

const searchPerizinan_Pemegang = (pageNo, pageSize, searchKey) => {
    return api.get(`/perizinan_pemegang/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

const deletePerizinan_Pemegang = (id) => {
    return api.delete(`/perizinan_pemegang/${id}`)
}

const getAllPerizinanByAttribute = (peruntukan, idPemegang, pageNo, pageSize) => {
    return api.get(`/perizinan_pemegang/?pageNo=${pageNo}&pageSize=${pageSize}${peruntukan ? `&peruntukan=${peruntukan}` : ''}${idPemegang ? `&id_pemilik=${idPemegang}` : ''}`);
}

export { getAllPerizinanByAttribute, getPerizinan_Pemegang, addPerizinan_Pemegang, updatePerizinan_Pemegang, getAllPerizinan_Pemegang, getOnePerizinan_Pemegang, searchPerizinan_Pemegang, deletePerizinan_Pemegang }


