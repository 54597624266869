import PageTemplate from "../templates/Template";
import React, { useEffect, useState } from "react";
import { Grid, Card, Box, Typography, Modal, Button, Slide } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import DashboardPieChart from './DashboardPieChart';
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardTable from "./DashboardTable";
import { getAllPengelola, getPengelola } from "../../repo/pengelolaRepo";
import { getAllPengambilanByPengelolaIDAndDate } from "../../repo/pengambilanRepo";

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import idLocale from 'date-fns/locale/id';
import { dateToISOString } from "../../repo/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DashboardPage = (props) => {
  const classes = useStyles();
  const [pengelolaID, setPengelolaID] = useState('all');
  const [duration, setDuration] = useState('all');
  const [listPengelola, setListPengelola] = useState([]);
  const [listMaterial, setListMaterial] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [chartLabel, setChartLabel] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let isMobile = (width <= 768);

  const handlePengelolaChange = (event) => {
    setPengelolaID(event.target.value);
  };
  const handleDurasiChange = (event) => {
    setDuration(event.target.value);
  };

  useEffect(() => {
    getAllPengelola(1, 1000).then((data) => {
      let res = data && data.data && data.data.document && data.data.document.records ? data.data.document.records : [];
      setListPengelola(res);
    });
  }, []);

  useEffect(() => {
    setIsChartLoading(true);
    let startDateStr = null;
    let endDateStr = null;
    if (duration === 'range' && dateRangeState[0]) {
      startDateStr = dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : null;
      endDateStr = dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : null;
    }
    getAllPengambilanByPengelolaIDAndDate(pengelolaID, 'selesai', startDateStr, endDateStr, 0, 10000).then((data) => {
      if (data && data.data && data.data.document && data.data.document.records) {
        let listPengambilan = data.data.document.records;
        let materialDict = {};
        listPengambilan.forEach(pengambilan => {
          pengambilan.material.forEach(material => {
            if (!(material.id_material in materialDict)) {
              materialDict[material.id_material] = {
                id: material.kode_material,
                code: material.kode_material,
                label: `${material.kode_material} ${material.nama_material}`,
                value: 0.0,
              }
            }
            materialDict[material.id_material].value += parseFloat(material.tonase);
          });
        });
        let listMaterialRaw = [];
        for (const [key, value] of Object.entries(materialDict)) {
          if (value.value.toString().includes('.')) {
            value.value = value.value.toFixed(4)
          }
          listMaterialRaw.push(value);
        }
        setChartLabel("");
        setListMaterial(listMaterialRaw);
      } else {
        setChartLabel("data kosong");
      }
      setIsChartLoading(false);
    }).catch(err => {
      console.log("Error, failed to load dat`a from backend", err);
      setIsChartLoading(false);
      setChartLabel("Terjadi kesalahan sistem, harap coba beberapa saat lagi.");
    });
  }, [pengelolaID, duration, dateRangeState[0]]);

  return (
    <PageTemplate title="Dashboard" menu="dashboard" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} spacing={2}>
        <Grid item xs={12} md={3} justify='flex-start'>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label">Perusahaan Pengelola</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handlePengelolaChange}
              value={pengelolaID}
              label="Perusahaan Pengelola"
              style={{ textAlign: 'left', backgroundColor: 'white' }}
            >
              <MenuItem value={'all'}>Semua</MenuItem>
              {
                listPengelola.map((pengelola, i) => (
                  <MenuItem key={i} value={pengelola.id}>{pengelola.nama_perusahaan}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} justify='flex-start'>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
            <InputLabel id="perusahaan-label">Waktu</InputLabel>
            <Select
              labelId="perusahaan-label"
              id="perusahaan-select"
              onChange={handleDurasiChange}
              value={duration}
              label="Durasi Waktu"
              style={{ textAlign: 'left', backgroundColor: 'white' }}
            >
              <MenuItem value={'all'}>Semua</MenuItem>
              <MenuItem value={'range'}>Rentang Tanggal</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {duration === 'range' && <Grid item xs={12} md={2} justify='flex-start'>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <p style={{ marginBottom: 0, marginTop: 3 }}>
              Tanggal: {dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : '∞'} - {dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : '∞'}
            </p>
            <Button
              onClick={() => setIsDateRangeModalOpen(true)}
              variant="outlined"
              size="small"
            >
              Ganti
            </Button>
          </FormControl>
        </Grid>}
        <Grid item xs={12}>
          <Card style={{ textAlign: 'left', padding: 10 }}>
            <Typography variant="h5">Jenis Material</Typography>
            <Box style={{ height: 400, textAlign: 'center' }}>
              {
                isChartLoading && (<CircularProgress color="primary" style={{ marginTop: 150 }} />)
              }
              {
                !isChartLoading && chartLabel == "" && (<DashboardPieChart data={listMaterial} isMobile={isMobile} />)
              }
              {
                !isChartLoading && chartLabel != "" && (<p style={{ marginTop: 150 }}>{chartLabel}</p>)
              }
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <DashboardTable
            pengelolaID={pengelolaID}
            startDateStr={duration === 'range' && dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : undefined}
            endDateStr={duration === 'range' && dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : undefined}
          />
        </Grid>
      </Grid>
      <Modal
        open={isDateRangeModalOpen}
        onClose={() => {
          setIsDateRangeModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Slide direction="down" in={isDateRangeModalOpen} mountOnEnter unmountOnExit>
          <div style={{ width: 50, height: 50, marginLeft: '40%', marginTop: '10%' }}>
            <DateRange
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              locale={idLocale}
              onChange={item => setDateRangeState([item.selection])}
              ranges={dateRangeState}
              dateDisplayFormat={'d MMM yyyy'}
            />
          </div>
        </Slide>
      </Modal>
    </PageTemplate>)
}
export default DashboardPage;

