import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getKendaraan = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllKendaraan(pageNo + 1, pageSize);
    }

    else {
        try {
            res = await searchKendaraan(search, pageNo + 1, pageSize);
        } catch (err) {
            return {
                records: [],
                totalCount: 0
            }
        }
    }
    return res.data.document;
}

const addKendaraan = (data) => {
    return api.post(`/kendaraan`, data)
}

const updateKendaraan = (id, data) => {
    return api.put(`/kendaraan/${id}`, data)
}

const getAllKendaraan = (pageNo, pageSize) => {
    return api.get(`/kendaraan/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

const getOneKendaraan = (id) => {
    return api.get(`/kendaraan/${id}`)
}

const searchKendaraan = (pageNo, pageSize, searchKey) => {
    return api.get(`/kendaraan/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

const deleteKendaraan = (id) => {
    return api.delete(`/kendaraan/${id}`)
}

const getKendaraanByPengangkutID = async (pengangkutID, pageNo, pageSize) => {
    let res;
    if (pengangkutID != undefined) {
        res = await api.get(`/pengangkut/${pengangkutID}/kendaraan/?pageNo=${pageNo + 1}&pageSize=${pageSize}`)
    } else {
        res = await getAllKendaraan(pageNo + 1, pageSize);
    }
    return res.data.document;
}

const getKendaraanByQuery = async (pengangkutID, platNomorQuery, pageNo, pageSize) => {
    let res;
    if (pengangkutID != undefined || platNomorQuery) {
        platNomorQuery = platNomorQuery.replace(/\s/g,'');
        res = await api.get(`/kendaraan-search/?pageNo=${pageNo + 1}&pageSize=${pageSize}${pengangkutID != undefined ? '&id_pengangkut='+pengangkutID : ''}${platNomorQuery ? '&plat_nomor='+platNomorQuery : ''}`)
    } else {
        res = await getAllKendaraan(pageNo + 1, pageSize);
    }
    return res.data.document;
}

const getPerizinanByKendaraanID = (id) => {
    return api.get(`/kendaraan/${id}/perizinan`);
}

export { 
  getKendaraanByQuery, 
  getPerizinanByKendaraanID, 
  getKendaraan, 
  addKendaraan, 
  updateKendaraan, 
  getAllKendaraan, 
  getOneKendaraan, 
  searchKendaraan, 
  deleteKendaraan, 
  getKendaraanByPengangkutID }


