import PageTemplate from "../templates/Template";
import PengambilanTable from "./PengambilanTable";
import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Button, TextField, InputAdornment, Hidden, Modal, Slide } from "@material-ui/core";
import PengambilanViewModal from "./PengambilanViewModal";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import { apiUrl } from "../../api/axios";
import { getAllMaterial } from "../../repo/materialRepo";
import { getPengangkut } from "../../repo/pengangkutRepo";

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import idLocale from 'date-fns/locale/id';
import { dateToISOString } from "../../repo/utils";
import { getPengelola } from "../../repo/pengelolaRepo";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PengambilanPage = (props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [viewPengambilanData, setViewPengambilanData] = useState(undefined);
  const [viewPengambilanID, setViewPengambilanID] = useState(undefined);
  const [status, setStatus] = useState('all');
  const [noPengambilanQuery, setNoPengambilanQuery] = useState("");
  const [materialOptions, setMaterialOptions] = useState([]);
  const [filterMaterial, setFilterMaterial] = useState(undefined);
  const [duration, setDuration] = useState('all');
  const [pengelolaOptions, setPengelolaOptions] = useState([]);
  const [filterPengelola, setFilterPengelola] = useState('all');
  const [pengangkutOptions, setPengangkutOptions] = useState([]);
  const [filterPengangkut, setFilterPengangkut] = useState('all');
  const [reportQueries, setReportQueries] = useState('');
  const [totalTonage, setTotalTonage] = useState(0.0);

  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const token = localStorage.getItem('token');

  const statusOptions = [
    { key: 'menunggu_pengangkut', label: 'Menunggu Pengangkut' },
    { key: 'menuju_pos_1', label: 'Menuju Pos 1' },
    { key: 'menuju_pos_2', label: 'Menuju Pos 2' },
    { key: 'verifikasi_pos_pomi', label: 'Verifikasi Pos POMI' },
    { key: 'verifikasi_pos_sikam', label: 'Verifikasi Pos Sikam' },
    { key: 'verifikasi_data', label: 'Verifikasi Data' },
    { key: 'disetujui_pengangkut', label: 'Disetujui Pengangkut' },
    { key: 'verifikasi_muatan', label: 'Verifikasi Muatan' },
    { key: 'verifikasi_lingkungan', label: 'Verifikasi Lingkungan' },
    { key: 'verifikasi_keluar', label: 'Verifikasi Keluar' },
    { key: 'verifikasi_pengelola', label: 'Verifikasi Pengelola' },
    { key: 'batal_otomatis', label: 'Batal Otomatis' },
    { key: 'dibatalkan', label: 'Dibatalkan' },
    { key: 'selesai', label: 'Selesai' },
    { key: 'ditolak', label: 'Ditolak' },
  ]

  useEffect(() => {
    // get all material for filter
    getAllMaterial(null, 1, 1000).then((res) => {
      setMaterialOptions(res.data.document.records)
    }).catch(error => {
      console.log(error);
      alert("Terjadi kesalahan sistem :(");
    });

    // get all pengelola for filter
    getPengelola(0, 1000, '').then(res => {
      setPengelolaOptions(res.records);
    }).catch(err => {
      console.log('failed to load pengelola data from backend', err);
      alert("Terjadi kesalahan sistem :(");
    });

    // get all pengangkut for filter
    getPengangkut(0, 1000, '').then(res => {
      setPengangkutOptions(res.records);
    }).catch(err => {
      console.log('failed to load transporter data from backend', err);
      alert("Terjadi kesalahan sistem :(");
    });
  }, []);

  useEffect(() => {
    let tempReportQueries = ``;
    if (status != "all") tempReportQueries += "status_akhir=" + status + "&";
    if (filterMaterial != "all" && filterMaterial != undefined) tempReportQueries += "id_material=" + filterMaterial + "&";
    if (filterPengangkut != "all" && filterPengangkut != undefined) tempReportQueries += "id_pengangkut=" + filterPengangkut + "&";
    if (filterPengelola != "all" && filterPengelola != undefined) tempReportQueries += "id_pengelola=" + filterPengelola + "&";
    if (duration == "range") {
      if (dateRangeState[0].startDate) tempReportQueries += "start_date=" + dateToISOString(dateRangeState[0].startDate) + "&";
      if (dateRangeState[0].endDate) tempReportQueries += "end_date=" + dateToISOString(dateRangeState[0].endDate) + "&";
    }
    if (noPengambilanQuery) tempReportQueries += "no_pengambilan_query=" + noPengambilanQuery.replace(/\s/g, '') + "&";
    setReportQueries(tempReportQueries);
    console.log(tempReportQueries);
  }, [status, filterMaterial, filterPengangkut, filterPengelola, dateRangeState, duration, noPengambilanQuery])

  const showPengambilanDetail = (id, data) => {
    setViewPengambilanID(id);
    setViewPengambilanData(data);
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleStatusOptionsChange = (e) => {
    setStatus(e.target.value);
  }

  const handleFilterMaterialChange = (e) => {
    setFilterMaterial(e.target.value);
  }

  const formatTonage = (tonage) => {
    if (!tonage) {
      return '-';
    }
    let rawStr = tonage.toFixed(2).replaceAll(".",",");
    return rawStr;
  }

  return (
    <PageTemplate title="Pengambilan" menu="pengambilan" isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen}>
      <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} alignItems="center">
        <Grid item md={12} xs={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleStatusOptionsChange}
                  label="Status"
                  value={status ? status : ''}
                  style={{ textAlign: 'left', backgroundColor: 'white' }}
                >
                  <MenuItem value={'all'}>Semua</MenuItem>
                  {
                    statusOptions.map((op, i) => (
                      <MenuItem key={i} value={op.key}>{op.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} justify='flex-start'>
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Mengangkut Material</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleFilterMaterialChange}
                  label="Material"
                  value={filterMaterial != undefined ? filterMaterial : 'all'}
                  style={{ textAlign: 'left', backgroundColor: 'white' }}
                >
                  <MenuItem value={'all'}>Semua</MenuItem>
                  {
                    materialOptions.map((m, i) => (
                      <MenuItem key={i} value={m.id}>{m.nama}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} justify='flex-start'>
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Perusahaan Pengelola</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) => { setFilterPengelola(e.target.value) }}
                  label="Perusahaan Pengelola"
                  value={filterPengelola != undefined ? filterPengelola : 'all'}
                  style={{ textAlign: 'left', backgroundColor: 'white' }}
                >
                  <MenuItem value={'all'}>Semua</MenuItem>
                  {
                    pengelolaOptions.map((m, i) => (
                      <MenuItem key={i} value={m.id}>{m.nama_perusahaan}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} justify='flex-start'>
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Perusahaan Pengangkut</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) => { setFilterPengangkut(e.target.value) }}
                  label="Perusahaan Pengangkut"
                  value={filterPengangkut != undefined ? filterPengangkut : 'all'}
                  style={{ textAlign: 'left', backgroundColor: 'white' }}
                >
                  <MenuItem value={'all'}>Semua</MenuItem>
                  {
                    pengangkutOptions.map((m, i) => (
                      <MenuItem key={i} value={m.id}>{m.nama_perusahaan}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} justify='center'>
              <p><b>Total Pengambilan:</b> {formatTonage(totalTonage)} ton</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} style={{ marginTop: 10 }}>
          <Grid container direction='row' spacing={2}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                <TextField
                  id="outlined-basic"
                  label="Cari No Pengambilan"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /> </InputAdornment>,
                  }}
                  value={noPengambilanQuery ? noPengambilanQuery.replace(/\s/g, "") : ''}
                  onChange={(e) => {
                    setNoPengambilanQuery(e.target.value.replace(/\s/g, ""));
                  }}
                  style={{ textAlign: 'left', backgroundColor: 'white' }} />
              </FormControl>
            </Grid>
            <Hidden smDown>
              <Grid item md={2} xs={12} justify='flex-start'>
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Tanggal</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) => setDuration(e.target.value)}
                    label="Status"
                    value={duration ? duration : 'all'}
                    style={{ textAlign: 'left', backgroundColor: 'white' }}
                  >
                    <MenuItem value={'all'}>Semua</MenuItem>
                    <MenuItem value={'range'}>Rentang Tanggal</MenuItem>Î
                  </Select>
                </FormControl>
              </Grid>
              {duration === 'range' &&
                <Grid item xs={12} md={3} justify='flex-start'>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <p style={{ marginBottom: 0, marginTop: 3 }}>
                      Tanggal: {dateRangeState[0].startDate ? dateToISOString(dateRangeState[0].startDate) : '∞'} - {dateRangeState[0].endDate ? dateToISOString(dateRangeState[0].endDate) : '∞'}
                    </p>
                    <Button
                      onClick={() => setIsDateRangeModalOpen(true)}
                      variant="outlined"
                      size="small"
                    >
                      Ganti
                    </Button>
                  </FormControl>
                </Grid>
              }
            </Hidden>
          </Grid>
        </Grid>
      </Grid>

      <PengambilanTable
        showPengambilanDetail={showPengambilanDetail}
        status={status}
        noPengambilanQuery={noPengambilanQuery ? noPengambilanQuery : undefined}
        filterMaterial={filterMaterial != 'all' && filterMaterial != undefined ? filterMaterial : undefined}
        filterPengelola={filterPengelola != 'all' && filterPengelola != undefined ? filterPengelola : undefined}
        filterPengangkut={filterPengangkut != 'all' && filterPengangkut != undefined ? filterPengangkut : undefined}
        filterStartDate={
          duration == 'range' && dateRangeState[0].startDate ?
            dateRangeState[0].startDate
            :
            null
        }
        filterEndDate={
          duration == 'range' && dateRangeState[0].endDate ?
            dateRangeState[0].endDate
            :
            null
        }
        setTotalTonage={setTotalTonage}
      />

      <Button variant={"contained"} color="primary" href={`${apiUrl}/report/pengambilan?token=${token}&${reportQueries}`} style={{ marginTop: 20 }}>Unduh sebagai File Excel</Button>

      <PengambilanViewModal
        open={modalOpen}
        handleClose={handleModalClose}
        pengambilanID={viewPengambilanID}
        pengambilan={viewPengambilanData}
      />

      <Modal
        open={isDateRangeModalOpen}
        onClose={() => {
          setIsDateRangeModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Slide direction="down" in={isDateRangeModalOpen} mountOnEnter unmountOnExit>
          <div style={{ width: 50, height: 50, marginLeft: '40%', marginTop: '10%' }}>
            <DateRange
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              locale={idLocale}
              onChange={item => setDateRangeState([item.selection])}
              ranges={dateRangeState}
              dateDisplayFormat={'d MMM yyyy'}
            />
          </div>
        </Slide>
      </Modal>
    </PageTemplate>
  )
}
export default PengambilanPage;
