import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './DashboardTableColumns';
import { withRouter } from "react-router";
import { getMaterialListByPengelolaIDAndDate } from '../../repo/pengambilanRepo';
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const dateToStringID = (date) => {
    const hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    return `${hari[date.getDay()]}, ${date.getDate()} ${bulan[date.getMonth()]} ${date.getFullYear()}`;
}

const DashboardTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const tableRef = React.createRef();

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [props.pengelolaID, props.startDateStr, props.endDateStr]);

    return (
        <div>
            <MaterialTable
                tableRef={tableRef}
                minRows={20}
                title="Data Pengambilan Material"
                columns={columns}
                data={async (query) => {
                    const resRaw = await getMaterialListByPengelolaIDAndDate(props.pengelolaID == 'all' ? null : props.pengelolaID, 'selesai', props.startDateStr, props.endDateStr, query.page, query.pageSize);
                    const res = resRaw.data.document;
                    res.records.forEach(row => {
                        let tempDate = new Date(row.tanggal_pengambilan);
                        row.tanggal_pengambilan = dateToStringID(tempDate);
                    });
                    return ({
                        data: res.records,
                        page: query.page,
                        totalCount: parseInt(res.TotalRecord)
                    })
                }
                }
                options={{
                    sorting: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    toolbar: true,
                    paging: true,
                    search: false,
                }}
                icons={tableIcons}

            />
        </div>);
}
export default withRouter(DashboardTable);
