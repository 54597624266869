import api from "../api/axios";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getIntern_Air_Lindi = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllIntern_Air_Lindi(pageNo+1,pageSize);
    }

    else{
        try {
            res = await searchIntern_Air_Lindi(search,pageNo+1,pageSize);
        } catch(err) {
            return {
                records:[],
                totalCount:0
            }
        }
    }
    return res.data.document;
}


const addIntern_Air_Lindi = (data) => {
return api.post(`/intern_air_lindi`,data)
}
const updateIntern_Air_Lindi = (id,data) => {
return api.put(`/intern_air_lindi/${id}`,data)
}
const getAllIntern_Air_Lindi = (pageNo,pageSize) => {
return api.get(`/intern_air_lindi/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const getOneIntern_Air_Lindi = (id) => {
return api.get(`/intern_air_lindi/${id}`)
}
const searchIntern_Air_Lindi = (pageNo,pageSize,searchKey) => {
return api.get(`/intern_air_lindi/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
const deleteIntern_Air_Lindi = (id) => {
return api.delete(`/intern_air_lindi/${id}`)
}
export {getIntern_Air_Lindi,addIntern_Air_Lindi,updateIntern_Air_Lindi,getAllIntern_Air_Lindi,getOneIntern_Air_Lindi,searchIntern_Air_Lindi,deleteIntern_Air_Lindi}


