import MaterialTable from 'material-table';
import React, { createRef, useEffect, useState } from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './PengambilanColumns';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withRouter } from "react-router";
import { deletePengambilan, getPengambilan } from "../../repo/pengambilanRepo";
/*
Documentation on developing the Material-Table can be found at https://material-table.com/
You can use the async function passed to MaterialTable data prop to implement filters and sorting on server-side
Filters and sorting can't be implemented on client side due to server-side pagination.
*/

const PengambilanTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const tableRef = createRef();

    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
        deletePengambilan(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                resolve()
            })
    }

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [props.status, props.filterStartDate, props.filterEndDate, props.noPengambilanQuery, props.filterMaterial, props.filterPengelola, props.filterPengangkut]);

    return (
        <div>
            <MaterialTable
                tableRef={tableRef}
                minRows={10}
                title="Data Pengambilan Limbah"
                columns={columns}
                data={async (query) => {
                    props.setTotalTonage(0.0);
                    const res = await getPengambilan(props.filterStartDate, props.filterEndDate, props.status, props.noPengambilanQuery, props.filterMaterial, props.filterPengelola, props.filterPengangkut, query.page, query.pageSize, query.search);

                    // display tonage
                    props.setTotalTonage(res.totalTonage);

                    return ({
                        data: res.records ? res.records : [],
                        page: query.page,
                        totalCount: parseInt(res.TotalRecord)
                    })
                }
                }
                options={{
                    sorting: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    toolbar: true,
                    paging: true,
                    search: false,
                }}

                actions={[
                    {
                        icon: () => <VisibilityIcon />,
                        tooltip: 'Lihat detail',
                        onClick: (event, rowData) => {
                            props.showPengambilanDetail(rowData.id, rowData);
                        }
                    }
                ]}

                icons={tableIcons}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}

            />
        </div>);
}
export default withRouter(PengambilanTable);
