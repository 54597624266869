import PageTemplate from "../templates/Template";
import Intern_Air_LindiTable from "./Intern_Air_LindiTable";
import React from "react";

const Intern_Air_LindiPage = (props) => {
    return(
    <PageTemplate title="Intern_Air_Lindi List">
        <Intern_Air_LindiTable/>
    </PageTemplate>)
}
export default Intern_Air_LindiPage;
