
const hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

const dateToStringID = (date) => {
  if (!date) return "";
  return `${hari[date.getDay()]}, ${date.getDate()} ${bulan[date.getMonth()]} ${date.getFullYear()}`;
}

const dateTimeToStringID = (date) => {
  if (!date) return "";
  let hour = date.getUTCHours(); hour = hour < 10 ? `0${hour}` : hour;
  let minute = date.getUTCMinutes(); minute = minute < 10 ? `0${minute}` : minute;
  return `${date.getUTCDate()} ${bulan[date.getUTCMonth()]} ${date.getUTCFullYear()} ${hour}:${minute}`;
}

const dateTimeToString = (date) => {
  if (!date) return "";
  let month = date.getUTCMonth() + 1; month = month < 10 ? `0${month}` : month;
  let day = date.getUTCDate(); day = day < 10 ? `0${day}` : day;
  let hour = date.getUTCHours(); hour = hour < 10 ? `0${hour}` : hour;
  let minute = date.getUTCMinutes(); minute = minute < 10 ? `0${minute}` : minute;
  let second = date.getUTCSeconds(); second = second < 10 ? `0${second}` : second;
  return `${date.getUTCFullYear()}-${month}-${day} ${hour}:${minute}:${second}`;
}

const dateToISOString = (date) => {
  if (!date) return '';
  let monthStr = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  let dayStr = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  return `${date.getFullYear()}-${monthStr}-${dayStr}`;
}

const dateTimeToStringTime = (date) => {
  if (!date) return "";
  let hour = date.getHours(); hour = hour < 10 ? `0${hour}` : hour;
  let minute = date.getMinutes(); minute = minute < 10 ? `0${minute}` : minute;
  return `${hour}:${minute}`;
}

export { dateToStringID, dateTimeToStringID, dateTimeToStringTime, dateToISOString, dateTimeToString }